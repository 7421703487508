import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Input as CustomInput } from 'components';

export const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0)
}));

export const Input = styled(CustomInput)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(1, 0)
}));
