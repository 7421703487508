import { useState, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LineChart, DatePicker, Tabs } from 'components';
import { calculateDate } from 'utils';
import type { IDate } from 'types';
import {
    GeneralGraphsTab,
    defaultValues,
    GeneralGraphsInput,
    type IGeneralGraphsForm
} from './general-graphs.config';
import { useGetGeneralStatsData } from '../hooks';
import * as S from '../common';

export const GeneralGraphs = () => {
    const { t } = useTranslation('statistics');
    const [selected, setSelectedValue] = useState<GeneralGraphsTab>(GeneralGraphsTab.STATS_USERS);

    const { GENERAL_STATS_DATE } = GeneralGraphsInput;

    const methods = useForm<IGeneralGraphsForm>({
        defaultValues
    });

    const generalStatsDate = useWatch({
        control: methods.control,
        name: GENERAL_STATS_DATE
    });

    const { generalStatsData, refetchMethods, isLoading, tabsErrorMessages } =
        useGetGeneralStatsData(generalStatsDate, selected);

    const handleChangeDate = (
        date: IDate,
        type: 'add' | 'subtract',
        range: 'month' | 'year' | 'day' = 'month'
    ) => {
        const newDate = calculateDate(date, type, 1, range);
        methods.setValue(GENERAL_STATS_DATE, newDate);
    };

    useEffect(() => {
        refetchMethods[selected]();
    }, [generalStatsDate, selected]);

    return (
        <FormProvider {...methods}>
            <Box component="form">
                <Tabs
                    sx={{
                        marginBottom: 3
                    }}
                    values={Object.values(GeneralGraphsTab)}
                    selected={selected}
                    setValue={setSelectedValue}
                />

                <S.Wrapper>
                    <DatePicker
                        name={GENERAL_STATS_DATE}
                        label={t('chart.inputLabel.month')}
                        format={['year', 'month']}
                        openTo="month"
                        disableFuture
                        size="small"
                        disabled={isLoading}
                    />
                    <Box>
                        <S.IconButton
                            onClick={() => handleChangeDate(generalStatsDate, 'subtract')}
                            disabled={isLoading}
                        >
                            <Tooltip title={t('chart.buttonTooltip.previousMonth')}>
                                <ArrowBackIosIcon />
                            </Tooltip>
                        </S.IconButton>
                        <S.IconButton
                            onClick={() => handleChangeDate(generalStatsDate, 'add')}
                            disabled={dayjs(generalStatsDate).isSame(dayjs(), 'month') || isLoading}
                        >
                            <Tooltip title={t('chart.buttonTooltip.nextMonth')}>
                                <ArrowForwardIosIcon />
                            </Tooltip>
                        </S.IconButton>
                    </Box>
                </S.Wrapper>
                <LineChart
                    data={generalStatsData[selected]}
                    title={`${t('chart.generalGraph.title')}`}
                    legend
                    isLoading={isLoading}
                    isError={tabsErrorMessages[selected] !== ''}
                />
            </Box>
        </FormProvider>
    );
};
