import * as yup from 'yup';
import { requiredStringSchema, numberSchema } from 'utils';
import type { IDeviceType } from 'types';

export enum DeviceTypeInput {
    NAME = 'name',
    PARAMETER_KV = 'parameterKV',
    BATTERY_LIFETIME = 'parameterBatteryLifetime'
}

export interface IDeviceTypeForm {
    [DeviceTypeInput.NAME]: string;
    [DeviceTypeInput.PARAMETER_KV]: string;
    [DeviceTypeInput.BATTERY_LIFETIME]?: string;
}

export const dynamicDefaultValues = (deviceType?: IDeviceType) => ({
    [DeviceTypeInput.NAME]: deviceType?.deviceTypeName ?? '',
    [DeviceTypeInput.PARAMETER_KV]: deviceType?.parameterKV ? String(deviceType?.parameterKV) : '',
    [DeviceTypeInput.BATTERY_LIFETIME]: deviceType?.parameterBatteryLifetime
        ? String(deviceType?.parameterBatteryLifetime)
        : ''
});

const formSchema = yup.object({
    [DeviceTypeInput.NAME]: requiredStringSchema,
    [DeviceTypeInput.PARAMETER_KV]: numberSchema
        .transform(value => (value === '' ? 0 : value))
        .typeError(value => ({
            key: value ? 'field_required' : 'wrong_parameter_kv'
        }))
        .min(0.1, {
            key: 'min_kv',
            values: { count: 0.1 }
        })
});

export const dynamicFormSchema = (isAdmin: boolean) => {
    switch (true) {
        case isAdmin:
            return formSchema.shape({
                [DeviceTypeInput.BATTERY_LIFETIME]: requiredStringSchema
            });

        default:
            return formSchema;
    }
};
