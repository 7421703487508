import { TFunction } from 'i18next';
import { IAccount, RoleType } from 'types';

export enum TabValue {
    ALL = 'all',
    ACTIVE = 'active',
    PEDNING = 'pending',
    DELETED = 'deleted'
}

export const searchFields = (t: TFunction) => [
    {
        name: 'nameAndSurname',
        label: t('users:filters.filterByNameAndSurname')
    },
    {
        name: 'nip',
        label: t('users:filters.filterByNip')
    }
];

export const hideColumns: Array<keyof IAccount> = ['uuid', 'isDeleted'];

export const translateTableData = (data: Array<IAccount>, t: TFunction) =>
    data.map(item => ({
        ...item,
        role: `${t(`common:role.${item.role}`)}`
    }));

export const selectRoleOptions = (t: TFunction) =>
    Object.keys(RoleType)
        .filter(role => role !== 'Device')
        .map(role => ({
            label: `${t(`common:role.${role}`)}`,
            value: `${t(`common:role.${role}`)}`
        }));
