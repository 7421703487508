import { regex } from './regex';

import { isStringColor } from './color';

export const isHexColor = (color: string) => regex.HEX_COLOR.test(`#${color}`);

export const convertToHexColor = (color: string) => {
    if (isStringColor(color)) {
        switch (color) {
            case 'green':
                return '#008000';
            case 'yellow':
                return '#FFFF00';
            case 'blue':
                return '#0000FF';
            case 'red':
                return '#FF0000';
            case 'white':
                return '#FFFFFF';
            default:
                return '#000000';
        }
    }

    if (isHexColor(color)) {
        return color.length === 8 ? `#${color.slice(0, -2)}` : `#${color}`;
    }

    return color;
};
