import { useTranslation } from 'react-i18next';

export const useTranslateError = () => {
    const { t } = useTranslation('alerts');

    const translateError = (error: string) => {
        switch (error) {
            case 'Unauthorized':
                return t('invalidUserOrPassword');
            case 'password must be longer than or equal to 6 characters':
                return t('passwordMustBeLongerThan6Characters');
            case 'email must be an email':
                return t('emailMustBeAnEmail');
            case 'Account is not activated':
                return t('accountIsNotActivated');
            case 'Account is not verified':
                return t('accountIsNotVerified');
            case 'Invalid password reset token':
                return t('invalidPasswordResetToken');
            case 'Company does not exists':
                return t('companyDoesNotExists');
            default:
                return t('unknownError', {
                    error
                });
        }
    };

    return { translateError };
};
