import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
    useQueryGetStatsUsers,
    useQueryGetStatsDevices,
    useQueryGetStatsEventTypes,
    QueryKey
} from 'api';
import { dateFromTimestamp, startAndEndTimestamp, cutDatasetToPresentDay } from 'utils';
import { color } from 'modules/app';
import { GeneralGraphsTab } from '../general-graphs/general-graphs.config';

export const useGetGeneralStatsData = (date: Date, selected: GeneralGraphsTab) => {
    const { t } = useTranslation('statistics');
    const queryClient = useQueryClient();

    const [tabsErrorMessages, setTabsErrorMessages] = useState<Record<GeneralGraphsTab, string>>({
        [GeneralGraphsTab.STATS_USERS]: '',
        [GeneralGraphsTab.STATS_INSTALLED_DEVICES]: '',
        [GeneralGraphsTab.STATS_EVENT_TYPES]: ''
    });

    const setFetchError = (tab: GeneralGraphsTab) => {
        setTabsErrorMessages(prev => ({
            ...prev,
            [tab]: t('alerts:fetchDataError')
        }));
    };

    const clearFetchError = (tab: GeneralGraphsTab) => {
        const isError = tabsErrorMessages[tab] !== '';

        if (isError) {
            setTabsErrorMessages(prev => ({
                ...prev,
                [tab]: ''
            }));
        }
    };

    const range = startAndEndTimestamp(date);

    const {
        data: statsUsers,
        refetch: refetchStatsUsers,
        isFetching: statsUsersLoading
    } = useQueryGetStatsUsers(range, {
        enabled: selected === GeneralGraphsTab.STATS_USERS,
        onError: () => {
            setFetchError(GeneralGraphsTab.STATS_USERS);
        },
        onSuccess: () => {
            clearFetchError(GeneralGraphsTab.STATS_USERS);
        }
    });

    const {
        data: statsDevices,
        refetch: refetchStatsDevices,
        isFetching: statsDevicesLoading
    } = useQueryGetStatsDevices(range, {
        enabled: selected === GeneralGraphsTab.STATS_INSTALLED_DEVICES,
        onError: () => {
            setFetchError(GeneralGraphsTab.STATS_INSTALLED_DEVICES);
        },
        onSuccess: () => {
            clearFetchError(GeneralGraphsTab.STATS_INSTALLED_DEVICES);
        }
    });

    const {
        data: statsEventTypes,
        refetch: refetchStatsEventTypes,
        isFetching: statsEventTypesLoading
    } = useQueryGetStatsEventTypes(range, {
        enabled: selected === GeneralGraphsTab.STATS_EVENT_TYPES,
        onError: () => {
            setFetchError(GeneralGraphsTab.STATS_EVENT_TYPES);
        },
        onSuccess: () => {
            clearFetchError(GeneralGraphsTab.STATS_EVENT_TYPES);
        }
    });

    useEffect(
        () => () => {
            setTabsErrorMessages({
                [GeneralGraphsTab.STATS_USERS]: '',
                [GeneralGraphsTab.STATS_INSTALLED_DEVICES]: '',
                [GeneralGraphsTab.STATS_EVENT_TYPES]: ''
            });
        },
        []
    );

    const isLoading = statsUsersLoading || statsDevicesLoading || statsEventTypesLoading;

    const generalStatsData = {
        [GeneralGraphsTab.STATS_USERS]: {
            labels: statsUsers?.map(({ timestamp }) => dateFromTimestamp(timestamp, 'DD.MM')) ?? [],
            datasets: [
                {
                    label: `${t('chart.users.activeUsersCount')}`,
                    backgroundColor: color.green,
                    borderColor: color.greenLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsUsers?.map(({ activeUsersCount }) => activeUsersCount)
                        ) ?? []
                },
                {
                    label: `${t('chart.users.notVerifiedUsersCount')}`,
                    backgroundColor: color.orange,
                    borderColor: color.orangeLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsUsers?.map(({ notVerifiedUsersCount }) => notVerifiedUsersCount)
                        ) ?? []
                },
                {
                    label: `${t('chart.users.removedUsersCount')}`,
                    backgroundColor: color.red,
                    borderColor: color.redLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsUsers?.map(({ removedUsersCount }) => removedUsersCount)
                        ) ?? []
                }
            ]
        },
        [GeneralGraphsTab.STATS_INSTALLED_DEVICES]: {
            labels:
                statsDevices?.map(({ timestamp }) => dateFromTimestamp(timestamp, 'DD.MM')) ?? [],
            datasets: [
                {
                    label: `${t('chart.devices.installedDevicesCount')}`,
                    backgroundColor: color.blue,
                    borderColor: color.blueLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsDevices?.map(({ installedDevicesCount }) => installedDevicesCount)
                        ) ?? []
                }
            ]
        },
        [GeneralGraphsTab.STATS_EVENT_TYPES]: {
            labels:
                statsEventTypes?.map(({ timestamp }) => dateFromTimestamp(timestamp, 'DD.MM')) ??
                [],
            datasets: [
                {
                    label: `${t('chart.eventTypes.Event')}`,
                    backgroundColor: color.blue,
                    borderColor: color.blueLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsEventTypes?.map(({ rules: { Event } }) => Event)
                        ) ?? []
                },
                {
                    label: `${t('chart.eventTypes.Battery')}`,
                    backgroundColor: color.green,
                    borderColor: color.greenLight,
                    data:
                        cutDatasetToPresentDay(
                            date,
                            statsEventTypes?.map(({ rules: { Battery } }) => Battery)
                        ) ?? []
                }
            ]
        }
    };

    const refetchMethods = {
        [GeneralGraphsTab.STATS_USERS]: () => {
            refetchStatsUsers();
            queryClient.invalidateQueries([QueryKey.STATS_USERS]);
        },
        [GeneralGraphsTab.STATS_INSTALLED_DEVICES]: () => {
            refetchStatsDevices();
            queryClient.invalidateQueries([QueryKey.STATS_INSTALLED_DEVICES]);
        },
        [GeneralGraphsTab.STATS_EVENT_TYPES]: () => {
            refetchStatsEventTypes();
            queryClient.invalidateQueries([QueryKey.STATS_EVENT_TYPES]);
        }
    };

    return { generalStatsData, refetchMethods, isLoading, tabsErrorMessages };
};
