export enum DeviceDetailsTab {
    EVENTS = 'events',
    MAINTENANCE = 'maintenance',
    LOCATION = 'location',
    SUBSCRIPTION = 'subscription'
}

export enum DeviceMaintenanceType {
    SERVICE = 'Service',
    INSTALATION = 'Installation',
    LOCATION = 'Localisation',
    SUBSCRIPTION = 'Subscription'
}

export interface IPostDeviceData {
    deviceTypeUUID: string;
    psc: string;
    deviceId: string;
    deviceName: string;
    ownershipCompanyNip: number;
    installerNip?: number;
    location: {
        lat: number;
        long: number;
    };
    subscriptionExipryUnixTime: number;
    subscriptionPhonenumber: string;
}

export type IDeviceResponse = {
    deviceUUID: string;
    deviceId: string;
    devicePSC: string;
    deviceName: string;
    deviceTypeName: string;
    deviceOwnerName: string;
    deviceOwnerNip: string;
    deviceInstalledUnixTime?: number | string;
    deviceInstallerName: string;
    deviceInstallerNip?: string;
    subscriptionTransitMethod: string;
    subscriptionPhonenumber: string;
    subscriptionExpiresAt?: number | string;
    lastEventText: string | null;
    lastEventIcon: string | null;
    lastEventColor: string | null;
    lastEventAt: string | null;
    batteryLevel: number | null;
    batteryLevelAt: string | null;
    batteryLifetime: number | null;
    batteryLifetimeCounter: number | null;
    waterDrainedInMetersSq: number | string;
    waterDrainedInMinutes: number | string;
    location: {
        lat: number;
        long: number;
    };
};

export type IDevice = Omit<IDeviceResponse, 'location'> & {
    lat: number;
    lng: number;
};

// prettier-ignore
export type IDeviceList = Omit<IDeviceResponse,'location' | 'waterDrainedInMinutes' | 'batteryLifetime' | 'batteryLifetimeCounter'
> & {
    isActive: boolean | string;
    isAwake: boolean;
};

export interface IPostDeviceDetailsEvent {
    deviceUUID: string;
    id: string;
}

export interface IDeviceHistoryEventResponse {
    color: string;
    createdAt: number;
    hex: string;
    text: string;
    type: string;
    icon: string;
}
export type IDeviceHistoryEvent = Omit<IDeviceHistoryEventResponse, 'createdAt'> & {
    createdAt: string;
};

export interface IDeviceHistoryMaintenanceResponse {
    name: string;
    surname: string;
    companyName: string;
    companyNip: string;
    fromDeviceTypeName: string;
    toDeviceTypeName: string;
    createdAt: string | Date;
    batteryLifetimeCounter: number | null;
}
// prettier-ignore
export type IDecviceHistoryMaintenance = Omit<IDeviceHistoryMaintenanceResponse, 'name' | 'surname'> & {
    nameAndSurname: string;
}

export interface IDeviceHistoryLocationResponse {
    name: string;
    surname: string;
    email: string;
    companyName: string;
    companyNip: string;
    lat: number;
    long: number;
    createdAt: string | Date;
}
// prettier-ignore
export type IDeviceHistoryLocation = Omit<IDeviceHistoryLocationResponse, 'long' | 'name' | 'surname'> & {
    lng: number;
    nameAndSurname: string;
};

export interface IDeviceHistorySubscriptionResponse {
    fromUnixTime: number;
    name: string;
    phonenumber: string;
    surname: string;
    toUnixTime: number;
}

// prettier-ignore
export type IDeviceHistorySubscription = Omit<IDeviceHistorySubscriptionResponse, 'fromUnixTime' | 'toUnixTime'> & {
    fromUnixTime: string,
    toUnixTime: string
}

export interface IPostDeviceLocation {
    deviceUUID: string;
    location: {
        lat: number;
        long: number;
    };
}

type IDynamicData = Record<string, string | number | null>;

export interface IPatchDeviceDetails extends IDynamicData {
    deviceUUID: string;
}

export type IPostDeleteDevice = {
    deviceUUID: string;
};

export type IPostMaintenance = Pick<IDevice, 'deviceUUID'> & {
    deviceTypeUUID: string;
};

export type IPostDeviceOwnership = Pick<IDevice, 'deviceUUID'> & {
    companyNip: number;
};

export type IPostDeleteDeviceBatteryCouter = Pick<IPostDeleteDevice, 'deviceUUID'>;
