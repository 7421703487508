import { IDevice, RoleType } from 'types';

export const hideColumns: Array<keyof IDevice> = [
    'deviceUUID',
    'deviceInstalledUnixTime',
    'subscriptionTransitMethod',
    'lastEventText',
    'lastEventIcon',
    'lastEventColor',
    'lastEventAt',
    'batteryLevel',
    'batteryLevelAt',
    'batteryLifetime'
];

export const hideColumnsByRole = (role: RoleType): Array<keyof IDevice> => {
    switch (role) {
        case RoleType.Client:
            return ['subscriptionPhonenumber', ...hideColumns];
        default:
            return hideColumns;
    }
};

export const allowedEditKeys = (role: RoleType): Array<keyof IDevice> => {
    switch (role) {
        case RoleType.Client:
            return ['deviceName'];

        case RoleType.Admin:
            return [
                'deviceOwnerNip',
                'deviceTypeName',
                'subscriptionPhonenumber',
                'subscriptionExpiresAt'
            ];

        case RoleType.Installer:
            return ['deviceTypeName', 'deviceOwnerNip'];

        default:
            return [];
    }
};
