import { styled } from '@mui/material/styles';
import { Button as MUIButton, Box as MUIBox } from '@mui/material';

export const Button = styled(MUIButton)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const Box = styled(MUIBox)(({ theme }) => ({
    marginLeft: theme.spacing(2),

    '& .MuiCircularProgress-root': {
        color: theme.palette.primary.contrastText
    }
}));
