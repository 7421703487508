import { useLocation, Outlet, Navigate } from 'react-router-dom';
import { paths } from 'utils';
import { RoleType } from 'types';

interface IRequireAuthRoleProps {
    currentRole: RoleType;
    allowedRoles: Array<RoleType>;
}

export const RequireAuthRole = ({ currentRole, allowedRoles }: IRequireAuthRoleProps) => {
    const location = useLocation();

    return allowedRoles.includes(currentRole) ? (
        <Outlet />
    ) : (
        <Navigate to={paths.dashboard.self} state={{ from: location }} replace />
    );
};
