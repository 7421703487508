import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useQueryGetCompanyList, useMutationDeleteCompany, QueryKey } from 'api';
import { Table, Filters, Button, ButtonIcon, Modal, Dialog, type ITableHandlers } from 'components';
import { paths } from 'utils';
import type { ICompanyListItem } from 'types';
import { CSVExportButton, SectionWrapper, FiltersWrapper } from 'modules/dashboard';
import { DashboardCompaniesForm } from './companies-form';
import { searchFields } from './companies.config';

export const DashboardCompaniesPage = () => {
    const { t } = useTranslation('companies');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [confirmDeleteCompany, setConfirmDeleteCompany] = useState<ICompanyListItem>();
    const [openAddComapnyModal, setOpenAddCompanyModal] = useState(false);
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});
    const { data: companies, isLoading, isError: isGetCompanyListError } = useQueryGetCompanyList();

    const { mutate: deleteCompany, reset } = useMutationDeleteCompany({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.COMPANY_LIST]);
            setConfirmDeleteCompany(undefined);
            enqueueSnackbar(t('alert.deleteCompanySuccess'), { variant: 'success' });
        },
        onError: () => {
            setConfirmDeleteCompany(undefined);
            enqueueSnackbar(t('alert.deleteCompanyError'), { variant: 'error' });
        }
    });

    const handleEdit = (company: ICompanyListItem) => {
        navigate(`${paths.dashboard.companies}/${company.uuid}`);
    };

    const handleDelete = (uuid: string) => {
        deleteCompany({
            companyUUID: uuid
        });
    };

    const handleOpenConfirmDeleteCompanyDialog = (company: ICompanyListItem) => {
        setConfirmDeleteCompany(company);
    };

    const tableHandlers: Array<ITableHandlers<ICompanyListItem>> = [
        {
            name: t('tableHandlers.editCompany'),
            handler: handleEdit,
            icon: ButtonIcon.EDIT
        },
        {
            name: t('tableHandlers.deleteCompany'),
            handler: handleOpenConfirmDeleteCompanyDialog,
            icon: ButtonIcon.DELETE,
            color: 'error'
        }
    ];

    return (
        <SectionWrapper
            title={`${t('title')}`}
            isLoading={isLoading}
            isError={isGetCompanyListError}
        >
            <FiltersWrapper>
                <Filters
                    fields={searchFields(t)}
                    setSearchParams={setSearchParams}
                    disabled={companies?.length === 0}
                />

                <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
                    <Button onClick={() => setOpenAddCompanyModal(true)} icon={ButtonIcon.ADD}>
                        {t('addCompany.buttonLabel')}
                    </Button>
                    <CSVExportButton data={companies ?? []} filename="companies" />
                </Box>
            </FiltersWrapper>

            <Table<ICompanyListItem>
                data={companies ?? []}
                hide={['uuid']}
                tableHandlers={tableHandlers}
                searchParams={searchParams}
            />

            {confirmDeleteCompany && (
                <Dialog
                    open={Boolean(confirmDeleteCompany)}
                    onClose={() => {
                        setConfirmDeleteCompany(undefined);
                        reset();
                    }}
                    onConfirm={() => handleDelete(confirmDeleteCompany.uuid as string)}
                    desc={`${t('alert.deleteCompany', {
                        name: confirmDeleteCompany.companyName
                    })}`}
                />
            )}

            {openAddComapnyModal && (
                <Modal
                    title={`${t('addCompany.modalTitle')}`}
                    open={openAddComapnyModal}
                    onClose={() => setOpenAddCompanyModal(false)}
                >
                    <DashboardCompaniesForm
                        variant="outlined"
                        handleClose={() => setOpenAddCompanyModal(false)}
                    />
                </Modal>
            )}
        </SectionWrapper>
    );
};
