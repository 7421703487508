import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, PaperProps, Box } from '@mui/material';
import { useMediaQuery, Theme } from '@material-ui/core';
import { Input, Button } from 'components';
import { ErrorMessage } from 'modules/common';
import { paths } from 'utils';
import { useMutationPostCompany, QueryKey } from 'api';
import {
    FormWrapper,
    CancelButton,
    FromButtonsWrapper,
    formFullWidtStyle
} from 'modules/dashboard';
import type { ICompanyListItem } from 'types';
import {
    CompanyFormInput,
    defaultValues,
    dynamicDefaultValues,
    formSchema
} from './companies-form.config';

interface IProps extends PaperProps {
    companyData?: ICompanyListItem;
    handleClose?: () => void;
}

export const DashboardCompaniesForm = ({ companyData, handleClose, ...rest }: IProps) => {
    const { t } = useTranslation('companyDetails');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isEdit = Boolean(companyData?.uuid);

    const methods = useForm<ICompanyListItem>({
        defaultValues: companyData ? dynamicDefaultValues(companyData) : defaultValues,
        resolver: yupResolver(formSchema)
    });

    const {
        mutate: postCompany,
        error,
        isLoading
    } = useMutationPostCompany(isEdit, {
        onSuccess: () => {
            Promise.all([
                queryClient.invalidateQueries([QueryKey.COMPANY_LIST]),
                queryClient.invalidateQueries([QueryKey.COMPANY])
            ]);
            handleClose && handleClose();
            isEdit && navigate(paths.dashboard.companies);
            enqueueSnackbar(t(`alert.${isEdit ? 'editCompanySuccess' : 'addCompanySuccess'}`), {
                variant: 'success'
            });
        },
        onError: () => {
            enqueueSnackbar(t(`alert.${isEdit ? 'editCompanyError' : 'addCompanyError'}`), {
                variant: 'error'
            });
        }
    });

    const onSubmit = (data: ICompanyListItem) => {
        const editCompanyData = {
            companyUUID: companyData?.uuid,
            name: data.companyName,
            street: data.street,
            city: data.city,
            country: data.country,
            postalcode: data.postalcode,
            province: data.province
        };

        const postCompanyData = {
            name: data.companyName,
            nip: Number(data.nip),
            address: {
                street: data.street,
                city: data.city,
                country: data.country,
                postalcode: data.postalcode,
                province: data.province
            }
        };

        postCompany(isEdit ? editCompanyData : postCompanyData);
    };

    const fullWidthOnMobile = isMobileView ? formFullWidtStyle : {};

    useEffect(() => {
        if (companyData) {
            methods.reset(dynamicDefaultValues(companyData));
        }
    }, [companyData]);

    return (
        <FormProvider {...methods}>
            <Paper {...rest}>
                <FormWrapper component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box sx={formFullWidtStyle}>
                        <ErrorMessage error={error} />
                    </Box>
                    <Input
                        name={CompanyFormInput.COMPANY_NAME}
                        label={t('form.label.companyName')}
                        sx={formFullWidtStyle}
                    />
                    <Input
                        name={CompanyFormInput.NIP}
                        label={t('form.label.nip')}
                        sx={formFullWidtStyle}
                        type="number"
                        disabled={isEdit}
                    />

                    <Input
                        name={CompanyFormInput.STREET}
                        label={t('form.label.street')}
                        sx={formFullWidtStyle}
                    />
                    <Input
                        name={CompanyFormInput.POSTALCODE}
                        label={t('form.label.postalcode')}
                        sx={fullWidthOnMobile}
                    />
                    <Input
                        name={CompanyFormInput.CITY}
                        label={t('form.label.city')}
                        sx={fullWidthOnMobile}
                    />
                    <Input
                        name={CompanyFormInput.PROVINCE}
                        label={t('form.label.province')}
                        sx={fullWidthOnMobile}
                    />
                    <Input
                        name={CompanyFormInput.COUNTRY}
                        label={t('form.label.country')}
                        sx={fullWidthOnMobile}
                    />

                    <FromButtonsWrapper>
                        <Button
                            isLoading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                        >
                            {t('form.submitButton')}
                        </Button>
                        {Boolean(companyData) && (
                            <CancelButton
                                type="reset"
                                variant="contained"
                                onClick={
                                    companyData
                                        ? () => methods.reset(dynamicDefaultValues(companyData))
                                        : undefined
                                }
                            >
                                {t('form.resetButton')}
                            </CancelButton>
                        )}
                    </FromButtonsWrapper>
                </FormWrapper>
            </Paper>
        </FormProvider>
    );
};
