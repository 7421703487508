export interface IFilters {
    [key: string]: string;
}

export const dynamicDefaultValues = <T>(arr: Array<T>) => {
    const dynamicValues: IFilters = arr.reduce(
        (acc, item) => ({ ...acc, [item as string]: '' }),
        {}
    );

    return {
        ...dynamicValues
    };
};
