import { useEffect, useState, useCallback } from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom';
import { BrowserHistory, createBrowserHistory } from 'history';

interface Props extends BrowserRouterProps {
    history: BrowserHistory;
}

export const customHistory = createBrowserHistory();

export const CustomRouter = ({ basename, history, children }: Props) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    const handlePop = useCallback(() => {
        history.listen(setState);
    }, [history]);

    useEffect(handlePop, [history]);

    return (
        <Router
            navigator={customHistory}
            location={state.location}
            navigationType={state.action}
            children={children}
            basename={basename}
        />
    );
};
