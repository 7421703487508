import * as yup from 'yup';
import { emailSchema } from 'utils';

export enum PasswordForgotInput {
    EMAIL = 'email'
}

export interface IPasswordForgotForm {
    [PasswordForgotInput.EMAIL]: string;
}

export const defaultValues: IPasswordForgotForm = {
    [PasswordForgotInput.EMAIL]: ''
};

export const formSchema = yup.object({
    [PasswordForgotInput.EMAIL]: emailSchema
});
