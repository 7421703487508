import { AxiosError } from 'axios';
import { Alert, AlertProps } from '@mui/material';
import { useTranslateError } from 'hooks';

interface IError {
    error: string;
    message: Array<string> | string;
    statusCode: number;
}

interface QueryErrorMessageProps extends AlertProps {
    error: AxiosError | null;
}

export const QueryErrorMessage = ({ error, ...rest }: QueryErrorMessageProps) => {
    const { translateError } = useTranslateError();
    const err = error?.response?.data as IError;

    if (error === null) {
        return null;
    }

    return (
        <Alert severity="error" {...rest}>
            {translateError(typeof err.message === 'string' ? err.message : err.message[0])}
        </Alert>
    );
};
