import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostDeviceType } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDeviceType = (
    isEdit: boolean,
    options?: IMutationOptions<IPostDeviceType>
) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeviceType>(
        async data => api[isEdit ? 'patch' : 'post'](Endpoint.DEVICE_TYPE, data),
        options
    );
