import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { dateFromTimestamp } from 'utils';
import type { IDeviceHistorySubscriptionResponse, IDeviceHistorySubscription } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

type IQueryResponse = Array<IDeviceHistorySubscriptionResponse>;

export const getDeviceHistorySubscription = async (deviceUUID: string) => {
    const response = await api.get<Array<IDeviceHistorySubscriptionResponse>>(
        Endpoint.DEVICE_HISTORY_SUBSCRIPTION,
        {
            params: {
                deviceUUID
            }
        }
    );

    return response.data;
};

export const useQueryGetDeviceHistorySubscription = (
    deviceUUID: string,
    options?: IQueryOptions<IQueryResponse, Array<IDeviceHistorySubscription>>
) =>
    useQuery<IQueryResponse, AxiosError, Array<IDeviceHistorySubscription>>(
        [QueryKey.DEVICE_HISTORY_SUBSCRIPTION],
        () => getDeviceHistorySubscription(deviceUUID),
        {
            select: data =>
                data.map(({ name, surname, phonenumber, toUnixTime, fromUnixTime }) => ({
                    fromUnixTime: dateFromTimestamp(fromUnixTime),
                    toUnixTime: dateFromTimestamp(toUnixTime),
                    phonenumber,
                    name,
                    surname
                })),
            ...options
        }
    );
