import { useTranslation } from 'react-i18next';
import { ButtonIcon } from 'components';
import { now } from 'utils';
import * as S from './csv-button.styled';

interface IProps<T> {
    data: Array<T>;
    filename: string;
}

export const CSVExportButton = <T extends object>({ data, filename }: IProps<T>) => {
    const { t } = useTranslation('common');

    return (
        <S.Button icon={ButtonIcon.DOWNLOAD}>
            <S.CSVLinkStyled data={data} target="_blank" filename={`${filename}-${now}`}>
                {t('csvButton.label')}
            </S.CSVLinkStyled>
        </S.Button>
    );
};
