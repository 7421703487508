import * as yup from 'yup';
import { passwordSchema, passwordRepeatSchema } from 'utils';

export enum PasswordResetInput {
    NEW_PASSWORD = 'password',
    NEW_PASSWORD_CONFIRM = 'passwordConfirmation'
}

export interface IPasswordResetForm {
    [PasswordResetInput.NEW_PASSWORD]: string;
    [PasswordResetInput.NEW_PASSWORD_CONFIRM]: string;
}

export const defaultValues: IPasswordResetForm = {
    [PasswordResetInput.NEW_PASSWORD]: '',
    [PasswordResetInput.NEW_PASSWORD_CONFIRM]: ''
};

export const formSchema = yup.object({
    [PasswordResetInput.NEW_PASSWORD]: passwordSchema,
    [PasswordResetInput.NEW_PASSWORD_CONFIRM]: passwordRepeatSchema
});
