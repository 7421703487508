import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Input, Button, Select } from 'components';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { FormWrapper, formFullWidtStyle, FromButtonsWrapper } from 'modules/dashboard';
import { useQueryGetCompanyList, useMutationCreateAccount, QueryKey } from 'api';
import { phonePrefix } from 'utils';
import { ErrorMessage } from 'modules/common';
import {
    defaultValues,
    UserCreateInput,
    formSchema,
    selectRoleOptions,
    companiesSelectOptions,
    type ICreateUserForm
} from './create-user-form.config';

interface IProps {
    open: boolean;
    onClose: () => void;
}

export const DashboardCreateUserForm = ({ open, onClose }: IProps) => {
    const { t } = useTranslation('users');
    const { data: companies } = useQueryGetCompanyList();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: postAccount, error } = useMutationCreateAccount({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.ACCOUNT_LIST]);
            enqueueSnackbar(t('alert.createUserSuccess'), {
                variant: 'success'
            });
            onClose();
        },
        onError: () => {
            enqueueSnackbar(t('alert.createUserError'), {
                variant: 'error'
            });
        }
    });

    const methods = useForm<ICreateUserForm>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const onSubmit = (formData: ICreateUserForm) => {
        postAccount({
            ...formData,
            phonenumber: `${phonePrefix}${formData.phonenumber}`
        });
    };

    return (
        <Modal open={open} onClose={onClose} title={String(t('createUser.title'))}>
            <FormProvider {...methods}>
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)} component="form">
                    <ErrorMessage
                        error={error}
                        sx={{
                            ...formFullWidtStyle,
                            marginBottom: 1
                        }}
                    />
                    <Select
                        name={UserCreateInput.ROLE}
                        label={t(`createUser.${UserCreateInput.ROLE}`)}
                        sx={{ ...formFullWidtStyle }}
                        options={selectRoleOptions(t)}
                    />
                    {companies && (
                        <Select
                            name={UserCreateInput.COMPANY_ID}
                            label={t(`createUser.${UserCreateInput.COMPANY_ID}`)}
                            options={companiesSelectOptions(companies)}
                            sx={{ ...formFullWidtStyle }}
                        />
                    )}

                    <Input
                        name={UserCreateInput.NAME}
                        label={t(`createUser.${UserCreateInput.NAME}`)}
                    />
                    <Input
                        name={UserCreateInput.SURNAME}
                        label={t(`createUser.${UserCreateInput.SURNAME}`)}
                    />
                    <Input
                        name={UserCreateInput.EMAIL}
                        label={t(`createUser.${UserCreateInput.EMAIL}`)}
                        type="email"
                    />
                    <Input
                        name={UserCreateInput.PHONE}
                        label={t(`createUser.${UserCreateInput.PHONE}`)}
                    />

                    <FromButtonsWrapper>
                        <Button type="submit" variant="contained">
                            {t('createUser.create')}
                        </Button>
                    </FromButtonsWrapper>
                </FormWrapper>
            </FormProvider>
        </Modal>
    );
};
