import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceHistoryMaintenanceResponse, IDecviceHistoryMaintenance } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceHistoryMaintenance = async (deviceUUID: string) => {
    const response = await api.get<Array<IDeviceHistoryMaintenanceResponse>>(
        Endpoint.DEVICE_HISTORY_MAINTENANCE,
        {
            params: {
                deviceUUID
            }
        }
    );

    return response.data;
};

export const useQueryGetDeviceHistoryMaintenance = (
    deviceUUID: string,
    // prettier-ignore
    options?: IQueryOptions<Array<IDeviceHistoryMaintenanceResponse>, Array<IDecviceHistoryMaintenance>>
) =>
    // prettier-ignore
    useQuery<Array<IDeviceHistoryMaintenanceResponse>,AxiosError, Array<IDecviceHistoryMaintenance>>([QueryKey.DEVICE_HISTORY_MAINTENANCE], () => getDeviceHistoryMaintenance(deviceUUID), {
        select: data =>
            data.map(
                ({
                    name,
                    surname,
                    companyName,
                    companyNip,
                    fromDeviceTypeName,
                    toDeviceTypeName,
                    createdAt,
                    batteryLifetimeCounter,
                }) => ({
                    createdAt: new Date(createdAt),
                    nameAndSurname: `${name} ${surname}`,
                    companyName,
                    companyNip,
                    fromDeviceTypeName,
                    toDeviceTypeName,
                    batteryLifetimeCounter
                })
            ),
        ...options
    });
