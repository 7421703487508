export enum DeviceRuleType {
    Opened = 'Opened',
    Closed = 'Closed',
    PowerOn = 'PowerOn',
    Event = 'Event',
    Battery = 'Battery'
}

export enum DeviceTypeRuleIcon {
    Opened = 'Opened',
    Closed = 'Closed',
    BatteryLevel = 'BatteryLevel',
    ImpactLevel = 'ImpactLevel'
}

export interface IDeviceTypeRule {
    color: string;
    deviceRuleUUID: string;
    type: string;
    hex: string;
    icon: string | null;
    text: string;
    formulaValue?: number;
}

export type IPostDeviceTypeRuleData = Omit<IDeviceTypeRule, 'deviceRuleUUID'> & {
    deviceTypeUUID?: string;
    deviceTypeRuleUUID?: string;
};

export interface IDeleteDeviceTypeRuleData {
    deviceTypeRuleUUID: string;
}
