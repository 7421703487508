import * as yup from 'yup';
import { regexSchema } from 'utils';
import { IAccount } from 'types';

export enum AccountEditInput {
    NAME = 'name',
    SURNAME = 'surname'
}

export interface IEditAccoutForm {
    [AccountEditInput.NAME]: string;
    [AccountEditInput.SURNAME]: string;
}

export const dynamicDefaultValues = (account: IAccount) => {
    const [name, surname] = account.nameAndSurname.split(' ');

    return {
        [AccountEditInput.NAME]: name,
        [AccountEditInput.SURNAME]: surname
    };
};

export const formSchema = yup.object({
    [AccountEditInput.NAME]: regexSchema('name'),
    [AccountEditInput.SURNAME]: regexSchema('surname')
});
