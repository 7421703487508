import { styled } from '@mui/material/styles';
import { Box as MUIBox, Typography } from '@mui/material';
import { Button as CustomButton } from 'components';

export const Container = styled(MUIBox)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    maxWidth: 400
}));

export const Title = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium
}));

Title.defaultProps = {
    variant: 'h5'
};

export const Description = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    textAlign: 'center'
}));

Description.defaultProps = {
    variant: 'body1'
};

export const Button = styled(CustomButton)(() => ({
    width: 'fit-content'
}));

Button.defaultProps = {
    variant: 'contained'
};
