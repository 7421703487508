const stringColors = ['green', 'yellow', 'blue', 'red', 'white', 'orange'];

export const isStringColor = (color: string) => stringColors.includes(color);

export const getDeviceStatusColor = (isAwake: boolean, isActive: boolean) => {
    if (!isAwake) {
        return 'red';
    }

    if (isAwake && !isActive) {
        return 'orange';
    }

    if (isAwake && isActive) {
        return 'green';
    }

    return 'white';
};
