import MapIcon from '@mui/icons-material/Map';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import RouterIcon from '@mui/icons-material/Router';
import FireHydrantAltIcon from '@mui/icons-material/FireHydrantAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import { paths } from 'utils';
import { RoleType } from 'types';

export enum NavItemName {
    MAP = 'map',
    USERS = 'users',
    COMPANIES = 'companies',
    DEVICES = 'devices',
    DEVICE_TYPES = 'deviceTypes',
    STATISTICS = 'statistics',
    SETTINGS = 'settings'
}

export interface INavItem {
    name: NavItemName;
    icon: React.ReactNode;
    path: string;
}

type IAuthRolesNavItems = Record<RoleType, Array<INavItem>>;

export const navItems = {
    map: {
        name: NavItemName.MAP,
        icon: <MapIcon />,
        path: paths.dashboard.map
    },
    users: {
        name: NavItemName.USERS,
        icon: <PeopleIcon />,
        path: paths.dashboard.users
    },
    companies: {
        name: NavItemName.COMPANIES,
        icon: <BusinessIcon />,
        path: paths.dashboard.companies
    },
    devices: {
        name: NavItemName.DEVICES,
        icon: <RouterIcon />,
        path: paths.dashboard.devices
    },
    deviceTypes: {
        name: NavItemName.DEVICE_TYPES,
        icon: <FireHydrantAltIcon />,
        path: paths.dashboard.devicesTypes
    },
    statistics: {
        name: NavItemName.STATISTICS,
        icon: <QueryStatsIcon />,
        path: paths.dashboard.statistics
    },
    settings: {
        name: NavItemName.SETTINGS,
        icon: <SettingsIcon />,
        path: paths.dashboard.settings
    }
};

export const authRolesNavItems: IAuthRolesNavItems = {
    [RoleType.Admin]: [
        navItems.map,
        navItems.users,
        navItems.companies,
        navItems.devices,
        navItems.deviceTypes,
        navItems.statistics,
        navItems.settings
    ],
    [RoleType.Installer]: [navItems.map, navItems.devices],
    [RoleType.Client]: [navItems.map, navItems.devices],
    [RoleType.Device]: [navItems.map, navItems.devices]
};

export const authRoles = {
    map: [RoleType.Admin, RoleType.Installer, RoleType.Client, RoleType.Device],
    user: [RoleType.Admin],
    company: [RoleType.Admin],
    device: [RoleType.Admin, RoleType.Installer, RoleType.Client, RoleType.Device],
    deviceTypes: [RoleType.Admin],
    statistics: [RoleType.Admin],
    settings: [RoleType.Admin]
};
