import CloseIcon from '@material-ui/icons/Close';
import * as S from './modal.styled';

interface IProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
}

export const Modal = ({ open, onClose, title, children }: IProps) => (
    <S.Modal open={open} onClose={onClose}>
        <S.Paper>
            <S.CloseButton onClick={onClose}>
                <CloseIcon />
            </S.CloseButton>
            {title && <S.Title>{title}</S.Title>}

            {children}
        </S.Paper>
    </S.Modal>
);
