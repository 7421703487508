import { CircularProgress, CircularProgressProps } from '@mui/material';
import * as S from './spinner.styled';

interface IProps extends CircularProgressProps {
    text?: string;
}

export const Spinner = ({ text, ...rest }: IProps) => (
    <S.Wrąpper>
        <CircularProgress {...rest} />
        {text && <S.Text variant="h6">{text}</S.Text>}
    </S.Wrąpper>
);
