import { styled } from '@mui/material/styles';
import { Box as MUIBox, AppBar as MUIAppBar, Toolbar as MUIToolbar, Stack } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

export const Box = styled(MUIBox)(() => ({
    flexGrow: 1
}));

export const LogoWrapper = styled(MUIBox)(() => ({
    width: '250px',
    justifySelf: 'center',
    alignSelf: 'center',
    margin: '0 auto'
}));

export const AppBar = styled(MUIAppBar)(({ theme }) => ({
    position: 'fixed',
    background: theme.palette.grey[800],
    zIndex: theme.zIndex.drawer + 1
}));

export const Toolbar = styled(MUIToolbar)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
}));

interface IMenuIcons {
    isOpen: boolean;
}

export const StyledMenuIcon = styled(MenuIcon, {
    shouldForwardProp: prop => prop !== 'isOpen'
})<IMenuIcons>(({ theme, isOpen }) => ({
    color: theme.palette.common.white,
    transition: 'all 0.3s ease-in-out',
    transform: `rotate(${isOpen ? 180 : 0}deg)`
}));

export const StyledLogoutIcon = styled(LogoutIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

export const UserBox = styled(Stack)(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    paddingLeft: theme.spacing(1),
    columnGap: theme.spacing(0.5)
}));
