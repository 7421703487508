import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Stack, Box } from '@mui/material';
import { Button } from 'components';
import { useMutationPasswordForgot } from 'api';
import { useStatus } from 'hooks';
import { paths } from 'utils';
import { FormWrapper, AuthInput, AuthInfo } from 'modules/auth';
import { QueryErrorMessage } from 'modules/common/components/error-message';
import {
    IPasswordForgotForm,
    defaultValues,
    formSchema,
    PasswordForgotInput
} from './password-forgot-page.config';

export const AuthPasswordForgotPage = () => {
    const { t } = useTranslation('passwordForgot');
    const { status, setStatus, StatusType } = useStatus();

    const methods = useForm<IPasswordForgotForm>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const {
        mutate: postPasswordForgot,
        isLoading,
        error
    } = useMutationPasswordForgot({
        onSuccess: () => {
            methods.reset();
            setStatus(StatusType.SUCCESS);
        },
        onError: () => {
            setStatus(StatusType.ERROR);
        }
    });

    const onSubmit = (data: IPasswordForgotForm) => {
        postPasswordForgot(data);
    };

    return (
        <>
            {status === StatusType.SUCCESS ? (
                <AuthInfo
                    title={t('info.title')}
                    description={t('info.description')}
                    buttonText={t('info.button')}
                    redirectPath={paths.auth.self}
                />
            ) : (
                <FormWrapper title={t('title')}>
                    <FormProvider {...methods}>
                        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                            <QueryErrorMessage error={error} />

                            <Stack mb={2}>
                                <AuthInput
                                    name={PasswordForgotInput.EMAIL}
                                    label={t('form.email')}
                                    disabled={isLoading}
                                />
                            </Stack>

                            <Button
                                sx={{ mb: 1 }}
                                disabled={isLoading}
                                isLoading={isLoading}
                                fullWidth
                                type="submit"
                            >
                                {t('form.submit')}
                            </Button>
                            <Button disabled={isLoading} to={paths.auth.self} fullWidth>
                                {t('form.cancel')}
                            </Button>
                        </Box>
                    </FormProvider>
                </FormWrapper>
            )}
        </>
    );
};
