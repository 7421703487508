import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IPostRegister, IPostRegisterResponse } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostRegister = (
    options?: IMutationOptions<IPostRegister, IPostRegisterResponse>
) =>
    useMutation<IPostRegisterResponse, AxiosError, IPostRegister>(
        async data => {
            const response = await api.post<IPostRegisterResponse>(Endpoint.USER_REGISTER, data);

            return response.data;
        },
        {
            ...options
        }
    );
