import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostActivate } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationActivateUser = (options?: IMutationOptions<IPostActivate>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostActivate>(
        async token => api.post(Endpoint.USER_ACTIVATE, token),
        options
    );
