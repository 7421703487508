import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { ISelectOptions } from 'types';
import * as S from './select.styled';
import { useHandleInputError } from '../hooks';

interface IProps extends SelectProps {
    name: string;
    label: string;
    options: Array<ISelectOptions>;
    selectAll?: boolean;
    customOnChange?: (
        e: SelectChangeEvent<unknown>,
        onChange: (...event: Array<unknown>) => void
    ) => void;
}

export const Select = ({ name, label, options, selectAll, customOnChange, ...rest }: IProps) => {
    const { t } = useTranslation(['validation', 'common']);
    const { handleInputError } = useHandleInputError();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ref, value, onBlur }, fieldState: { error } }) => {
                const errorMessage = handleInputError(error);

                return (
                    <S.Wrapper sx={rest.sx}>
                        <FormControl fullWidth>
                            <InputLabel error={Boolean(errorMessage)} id={`select-${name}-label`}>
                                {label}
                            </InputLabel>
                            <S.Select
                                error={Boolean(errorMessage)}
                                labelId={`select-${name}-label`}
                                id={`select-${name}`}
                                inputRef={ref}
                                value={value}
                                onBlur={onBlur}
                                label={name}
                                onChange={
                                    customOnChange ? e => customOnChange(e, onChange) : onChange
                                }
                                {...rest}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                whiteSpace: 'wrap'
                                            },
                                            overflow: 'scroll'
                                        },
                                        style: {
                                            maxHeight: 600,
                                            maxWidth: 600
                                        }
                                    }
                                }}
                            >
                                {selectAll && (
                                    <S.MenuItem value="">{t('common:selectAll')}</S.MenuItem>
                                )}
                                {options.map(({ value, label }) => (
                                    <S.MenuItem key={`select-option-${label}`} value={value}>
                                        {label}
                                    </S.MenuItem>
                                ))}
                            </S.Select>
                            {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
                        </FormControl>
                    </S.Wrapper>
                );
            }}
        />
    );
};
