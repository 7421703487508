import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { formatDate, removeKeysFromObject, shouldDisplayEmptyValue } from 'utils';
import * as S from './list.styled';

interface IProps<T> {
    data: T;
    hide?: Array<keyof T>;
    title?: string;
    edit?: {
        keys: Array<keyof T>;
        handler: (key: keyof T) => void;
    };
    columnGap?: number;
}

export const List = <T extends object>({ data, title, hide, edit, columnGap }: IProps<T>) => {
    const { t } = useTranslation(['table', 'common']);

    const formatCell = (cell: string | number | boolean | Date): string | JSX.Element => {
        switch (true) {
            case cell instanceof Date:
                return formatDate(cell.toString());
            case !cell || shouldDisplayEmptyValue(cell.toString()):
                return '-';
            default:
                return String(cell);
        }
    };

    return (
        <S.Wrapper>
            <S.List>
                {title && <S.Title>{title}</S.Title>}
                {Object.entries(hide ? removeKeysFromObject(data, hide) : data).map(
                    ([key, value]) => (
                        <S.ListItem key={key} gap={columnGap}>
                            <S.Text disableTypography>{t(`headLabel.${key}`)}:</S.Text>
                            <S.Text disableTypography>
                                {formatCell(value)}
                                {edit?.keys?.includes(key as keyof T) && (
                                    <S.Button
                                        onClick={() => edit?.handler(key as keyof T)}
                                        startIcon={<EditIcon />}
                                    >
                                        {t('common:edit')}
                                    </S.Button>
                                )}
                            </S.Text>
                        </S.ListItem>
                    )
                )}
            </S.List>
        </S.Wrapper>
    );
};
