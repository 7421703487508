import { useTranslation } from 'react-i18next';
import { ErrorPageWrapper } from 'modules/common';
import { paths } from 'utils';

export const NotFoundPage = () => {
    const { t } = useTranslation('page404');

    return (
        <ErrorPageWrapper
            code="404"
            title={t('title')}
            description={t('description') as string}
            buttonText={t('button')}
            redirectPath={paths.home}
        />
    );
};
