import { INVALID_TIMESTAMP } from './constants';

const emptyValues = [INVALID_TIMESTAMP];

export const shouldDisplayEmptyValue = (value: string): boolean => {
    const lowercaseValue = value.toLowerCase();

    const isEmpty = emptyValues
        .map(item => item.toLowerCase())
        .some((item: string) => lowercaseValue.includes(item));

    return isEmpty;
};
