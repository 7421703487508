import { useState, useEffect, type Dispatch, type SetStateAction } from 'react';
import { filterArrOfObj } from 'utils';

export const useFilter = <T>(
    input: Array<T>,
    params: Record<string, string>,
    setPage: Dispatch<SetStateAction<number>>
) => {
    const [data, setData] = useState(input);

    useEffect(() => {
        const isNotEmpty = Object.values(params).some(value => value !== '');

        setData(isNotEmpty ? filterArrOfObj(input, params) : input);

        if (isNotEmpty) {
            setPage(0);
        }
    }, [params, input]);

    return data;
};
