import { Box, BoxProps } from '@mui/material';

type IProps = {
    children: React.ReactNode;
} & BoxProps;

export const FiltersWrapper = ({ children, ...rest }: IProps) => (
    <Box
        sx={theme => {
            const isDesktop = theme.breakpoints.up('md');

            return {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                columnGap: theme.spacing(2),
                flexDirection: 'column',
                marginBottom: theme.spacing(2),

                '& .MuiButtonBase-root': {
                    alignSelf: 'flex-end',
                    marginTop: theme.spacing(1.5),

                    '&:not(:last-child)': {
                        marginRight: theme.spacing(1)
                    },

                    [isDesktop]: {
                        marginTop: 0
                    }
                },

                [isDesktop]: {
                    flexDirection: 'row'
                }
            };
        }}
        {...rest}
    >
        {children}
    </Box>
);
