import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostPasswordForgot } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPasswordForgot = (options?: IMutationOptions<IPostPasswordForgot>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostPasswordForgot>(
        async email => api.post(Endpoint.USER_PASSWORD_RESET, email),
        options
    );
