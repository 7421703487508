import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LineChart, DatePicker } from 'components';
import { calculateDate } from 'utils';
import type { IDate } from 'types';
import { EventsGraphInput, defaultValues, type IEventsGraphForm } from './events-graph.config';
import { useGetEventsStatsData } from '../hooks';
import * as S from '../common';

export const EventsGraph = () => {
    const { t } = useTranslation('statistics');

    const { STATS_EVENTS_DATE } = EventsGraphInput;

    const methods = useForm<IEventsGraphForm>({
        defaultValues
    });

    const statsEventsDate = useWatch({
        control: methods.control,
        name: STATS_EVENTS_DATE
    });

    const { eventsStatsData, isLoading, refetchEventsStats, isGetStatsEventsError } =
        useGetEventsStatsData(statsEventsDate);

    const handleChangeDate = (
        date: IDate,
        type: 'add' | 'subtract',
        range: 'month' | 'year' | 'day' = 'month'
    ) => {
        const newDate = calculateDate(date, type, 1, range);
        methods.setValue(STATS_EVENTS_DATE, newDate);
    };

    useEffect(() => {
        refetchEventsStats();
    }, [statsEventsDate]);

    return (
        <FormProvider {...methods}>
            <Box component="form">
                <S.Wrapper>
                    <DatePicker
                        name={STATS_EVENTS_DATE}
                        format={['year', 'month']}
                        openTo="month"
                        label={t(`chart.inputLabel.month`)}
                        size="small"
                        disableFuture
                        disabled={isLoading}
                    />

                    <Box>
                        <S.IconButton
                            onClick={() => handleChangeDate(statsEventsDate, 'subtract')}
                            disabled={isLoading}
                        >
                            <Tooltip title={t('chart.buttonTooltip.previousMonth')}>
                                <ArrowBackIosIcon />
                            </Tooltip>
                        </S.IconButton>
                        <S.IconButton
                            disabled={dayjs(statsEventsDate).isSame(dayjs(), 'month') || isLoading}
                            onClick={() => handleChangeDate(statsEventsDate, 'add')}
                        >
                            <Tooltip title={t('chart.buttonTooltip.nextMonth')}>
                                <ArrowForwardIosIcon />
                            </Tooltip>
                        </S.IconButton>
                    </Box>
                </S.Wrapper>
                <LineChart
                    data={eventsStatsData}
                    title={`${t('chart.eventsGraph.title')}`}
                    legend
                    isLoading={isLoading}
                    isError={isGetStatsEventsError}
                />
            </Box>
        </FormProvider>
    );
};
