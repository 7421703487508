import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostDeviceLocation } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDeviceLocation = (options?: IMutationOptions<IPostDeviceLocation>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeviceLocation>(
        async data => api.post(Endpoint.DEVICE_LOCATION, data),
        options
    );
