import { useTranslation } from 'react-i18next';
import { Modal, List, Button } from 'components';
import { paths } from 'utils';
import { IDevice } from 'types';
import { hideColumns } from './hydrant-preview.config';
import * as S from './hydrant-preview.styled';

interface IProps {
    data: IDevice;
    onClose: () => void;
}

export const HydrantPreview = ({ data, onClose }: IProps) => {
    const { t } = useTranslation('map');

    return (
        <Modal open={Boolean(data)} onClose={onClose} title={String(data.deviceId)}>
            <S.Wrapper>
                <List<IDevice> data={data} hide={hideColumns} />
            </S.Wrapper>
            <Button to={`${paths.dashboard.devices}/${data.deviceUUID}`}>
                {t('detailsButtonLabel')}
            </Button>
        </Modal>
    );
};
