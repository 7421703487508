import * as yup from 'yup';
import { requiredStringSchema, emailSchema } from 'utils';

export interface ILoginForm {
    email: string;
    password: string;
}

export const defaultValues: ILoginForm = {
    email: '',
    password: ''
};

export const formSchema = yup.object({
    email: emailSchema,
    password: requiredStringSchema
});
