import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Wrąpper = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2)
}));
