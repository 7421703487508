import { styled } from '@mui/material/styles';
import {
    Box,
    List as MUIList,
    ListItem as MUIListItem,
    ListItemProps,
    ListItemText,
    Button as MUIButton,
    Typography
} from '@mui/material';

export const Wrapper = styled(Box)(() => ({
    width: '100%',
    display: 'flex'
}));

export const List = styled(MUIList)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(0.5, 0),
    fontSize: theme.typography.fontSize
}));

interface IListIteProps extends ListItemProps {
    gap?: number;
}

export const ListItem = styled(MUIListItem, {
    shouldForwardProp: prop => prop !== 'drawerWidth'
})<IListIteProps>(({ theme, gap }) => ({
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(gap ?? 6),
    borderRadius: theme.shape.borderRadius,

    '&:nth-of-type(2n+1)': {
        background: theme.palette.grey[100]
    }
}));

export const Text = styled(ListItemText)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '60%',

    '&:nth-of-type(2n+1)': {
        width: '40%'
    }
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
}));

Title.defaultProps = {
    variant: 'body2'
};

export const Button = styled(MUIButton)(() => ({
    padding: 0
}));

Button.defaultProps = {
    variant: 'text',
    size: 'small'
};
