export enum EventsGraphInput {
    STATS_EVENTS_DATE = 'statsEventsDate'
}

export interface IEventsGraphForm {
    [EventsGraphInput.STATS_EVENTS_DATE]: Date;
}

export const defaultValues: IEventsGraphForm = {
    [EventsGraphInput.STATS_EVENTS_DATE]: new Date()
};
