import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MapWrapper = styled(Box)(() => ({
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
}));

export const InfoBox = styled(Box)(() => ({
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: 1
}));
