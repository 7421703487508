import { styled } from '@mui/material/styles';
import { Select as MUISelect, Box, MenuItem as MUIMenuItem, FormHelperText } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
    textTransform: 'capitalize',

    '& .MuiInputLabel-root': {
        paddingRight: '10px'
    },
    '& .MuiInputLabel-root.Mui-focused': {
        paddingRight: '0',
        overflow: 'visible'
    }
}));

export const Select = styled(MUISelect)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',

    '&:hover': {
        backgroundColor: theme.palette.background.paper
    }
}));

export const MenuItem = styled(MUIMenuItem)(() => ({
    textTransform: 'capitalize'
}));

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.error.main,
    margin: theme.spacing(0.5, 0, 0, 1.5),
    fontSize: '12px',
    textTransform: 'none'
}));
