export enum SettingsInput {
    VERIFY_IS_REQUIRED = 'isVerificationCodeRequired'
}

export interface ISettingsForm {
    [SettingsInput.VERIFY_IS_REQUIRED]: boolean;
}

export const dynamicDefaultValues = (verifyRequired: boolean): ISettingsForm => {
    switch (true) {
        case verifyRequired:
            return {
                [SettingsInput.VERIFY_IS_REQUIRED]: true
            };

        default:
            return {
                [SettingsInput.VERIFY_IS_REQUIRED]: false
            };
    }
};
