import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { InputAdornment, IconButton, type TextFieldProps } from '@mui/material';
import * as S from './input.styled';
import { useHandleInputError } from '../hooks';

type IProps = {
    name: string;
    label: string;
    phonePrefix?: string;
    customOnChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        onChange: (...event: Array<unknown>) => void
    ) => void;
} & TextFieldProps;

export const Input = ({ name, label, customOnChange, phonePrefix, ...rest }: IProps) => {
    const { handleInputError } = useHandleInputError();
    const [showPassword, setShowPassword] = useState(false);
    const { control } = useFormContext();

    const isPasswordType = rest.type === 'password';

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ref, value, onBlur }, fieldState: { error } }) => {
                const errorMessage = handleInputError(error);

                return (
                    <S.TextField
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                        inputRef={ref}
                        value={value}
                        onBlur={onBlur}
                        label={label}
                        colorCode={rest.type === 'color' ? value : ''}
                        variant="filled"
                        onChange={customOnChange ? e => customOnChange(e, onChange) : onChange}
                        InputProps={{
                            endAdornment: isPasswordType && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: phonePrefix && (
                                <InputAdornment position="start">{phonePrefix}</InputAdornment>
                            )
                        }}
                        {...rest}
                        type={isPasswordType && showPassword ? 'text' : rest.type}
                    />
                );
            }}
        />
    );
};
