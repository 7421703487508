import { Routes, Route, Navigate } from 'react-router-dom';
import {
    AuthLayout,
    AuthPasswordForgotPage,
    AuthPasswordResetPage,
    AuthSignInPage,
    AuthRegisterPage,
    AuthActivatePage
} from 'modules/auth';
import { paths, ROUTE } from 'utils';

export const AuthRouter = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route index element={<AuthSignInPage />} />
            <Route path={ROUTE.AUTH_PASSWORD_FORGOT} element={<AuthPasswordForgotPage />} />
            <Route path={ROUTE.AUTH_PASSWORD_RESET} element={<AuthPasswordResetPage />} />
            <Route path={ROUTE.AUTH_REGISTER_TYPE} element={<AuthRegisterPage />} />
            <Route path={ROUTE.AUTH_ACTIVATE} element={<AuthActivatePage />} />

            <Route path="*" element={<Navigate to={paths.notFound} replace />} />
        </Route>
    </Routes>
);
