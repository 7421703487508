import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FireHydrantAltIcon from '@mui/icons-material/FireHydrantAlt';
import { Chip, Map } from 'components';
import { useQueryMapDevicesList, useQueryGetDevice, QueryKey } from 'api';
import { SectionWrapper } from 'modules/dashboard';
import { IMarker } from 'types';
import { HydrantPreview } from './hydrant-preview';
import { centerCoordinates } from './map-page.config';
import * as S from './map-page.styled';

export const DashboardMapPage = () => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['map', 'common', 'alerts']);
    const [hydrantPreviewId, setHydrantPreviewId] = useState<string>();
    const {
        data: mapDevicesList,
        isLoading,
        isError: isMapDevicesListError
    } = useQueryMapDevicesList();
    const {
        data: deviceData,
        refetch: deviceDataRefetch,
        remove: deviceDataRemove
    } = useQueryGetDevice(`${hydrantPreviewId}`, {
        enabled: Boolean(hydrantPreviewId),
        onError: () => {
            enqueueSnackbar(t('alerts:fetchDataError'), { variant: 'error' });
        }
    });

    const handleHydrantPreview = (item: IMarker) => {
        setHydrantPreviewId(item?.id);
    };

    useEffect(() => {
        if (hydrantPreviewId) {
            deviceDataRefetch();
        }

        return () => {
            deviceDataRemove();
        };
    }, [hydrantPreviewId]);

    return (
        <SectionWrapper
            isError={isMapDevicesListError}
            sx={{
                paddingInline: 0,
                paddingBottom: 0,
                paddingTop: '32px'
            }}
        >
            <S.MapWrapper>
                <S.MapWrapper>
                    <S.InfoBox>
                        <Chip
                            label={
                                isLoading
                                    ? t('common:loading')
                                    : `${t('chipLabel')}: ${mapDevicesList?.length ?? 0}`
                            }
                            icon={<FireHydrantAltIcon />}
                        />
                    </S.InfoBox>
                    <Map
                        location={centerCoordinates}
                        markers={mapDevicesList}
                        markerHandler={handleHydrantPreview}
                        zoom={7}
                    />
                </S.MapWrapper>

                {deviceData && hydrantPreviewId && (
                    <HydrantPreview
                        data={deviceData}
                        onClose={() => {
                            setHydrantPreviewId(undefined);
                            deviceDataRemove();
                            queryClient.removeQueries([QueryKey.DEVICE]);
                        }}
                    />
                )}
            </S.MapWrapper>
        </SectionWrapper>
    );
};
