import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import RuleIcon from '@mui/icons-material/Rule';
import VerifiedIcon from '@mui/icons-material/Verified';

export enum ButtonIcon {
    DOWNLOAD = 'download',
    EDIT = 'edit',
    ADD = 'add',
    INFO = 'info',
    DELETE = 'delete',
    RULE = 'rule',
    VERIFY = 'verify'
}

export const handleIcon = (icon: ButtonIcon | React.ReactNode) => {
    switch (icon) {
        case ButtonIcon.DOWNLOAD:
            return <DownloadIcon />;
        case ButtonIcon.EDIT:
            return <EditIcon />;
        case ButtonIcon.ADD:
            return <AddIcon />;
        case ButtonIcon.INFO:
            return <InfoIcon />;
        case ButtonIcon.DELETE:
            return <DeleteIcon />;
        case ButtonIcon.RULE:
            return <RuleIcon />;
        case ButtonIcon.VERIFY:
            return <VerifiedIcon />;
        default:
            return icon;
    }
};
