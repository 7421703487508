import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from 'modules/dashboard';
import { ButtonIcon, Tabs, Table, type ITableHandlers } from 'components';
import { CompanyTab, ICompanyUsersList, ICompanyDevices } from 'types';
import { paths } from 'utils';
import { useGetCompanyData } from './hooks';
import { hideColumns, disableCellFormat } from './companies-edit.config';
import { DashboardCompaniesForm } from '../companies-form';

export const DashboardCompaniesEditPage = () => {
    const { t } = useTranslation('companyDetails');
    const { id } = useParams();
    const navigate = useNavigate();
    const [selected, setSelectedValue] = useState<CompanyTab>(CompanyTab.DATA);
    const {
        companyData,
        isCompanyLoading,
        companyUsersData,
        isCompanyUsersDataLoading,
        companyDevicesData,
        isCompanyDevicesDataLoading,
        tabsErrorMessages
    } = useGetCompanyData(id as string, selected);

    const handleShowDeviceDetails = (device: ICompanyDevices) => {
        navigate(`${paths.dashboard.devices}/${device.deviceUUID}`);
    };

    const tableDevicesHandlers: Array<ITableHandlers<ICompanyDevices>> = [
        {
            name: t('tableHandlers.showDetails'),
            icon: ButtonIcon.INFO,
            handler: handleShowDeviceDetails
        }
    ];

    const renderComponent = (view: CompanyTab) => {
        switch (view) {
            case CompanyTab.USERS:
                return (
                    <Table<ICompanyUsersList>
                        data={companyUsersData ?? []}
                        isLoading={isCompanyUsersDataLoading}
                        errorMessage={tabsErrorMessages[CompanyTab.USERS]}
                    />
                );
            case CompanyTab.DEVICES:
                return (
                    <Table<ICompanyDevices>
                        data={companyDevicesData ?? []}
                        tableHandlers={tableDevicesHandlers}
                        hide={hideColumns}
                        isLoading={isCompanyDevicesDataLoading}
                        errorMessage={tabsErrorMessages[CompanyTab.DEVICES]}
                        disableCellFormat={disableCellFormat}
                    />
                );
            default:
                return <DashboardCompaniesForm companyData={companyData} />;
        }
    };

    return (
        <SectionWrapper
            title={`${t('title')}`}
            subtitles={[`${companyData?.companyName}`]}
            isLoading={isCompanyLoading}
            errorMessage={tabsErrorMessages[CompanyTab.DATA]}
        >
            <Tabs<CompanyTab>
                values={Object.values(CompanyTab)}
                selected={selected}
                setValue={setSelectedValue}
            />
            {renderComponent(selected)}
        </SectionWrapper>
    );
};
