import { styled } from '@mui/material/styles';
import { Box, IconButton as MUIIconButton } from '@mui/material';

export const IconButton = styled(MUIIconButton)(() => ({
    '& svg': {
        width: '24px',
        margin: '0 auto'
    }
}));

IconButton.defaultProps = {
    size: 'small',
    disableRipple: true
};

export const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiBox-root': {
        display: 'flex',
        columnGap: theme.spacing(1)
    }
}));
