import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Filters, Button, ButtonIcon, Table, Modal, Dialog, type ITableHandlers } from 'components';
import {
    useQueryGetDeviceTypeRulesList,
    useQueryGetDeviceTypeList,
    useMutationDeleteDeviceTypeRule,
    QueryKey
} from 'api';
import { IDeviceTypeRule, DeviceRuleType } from 'types';
import { SectionWrapper, FiltersWrapper } from 'modules/dashboard';
import { DashboardDeviceTypesRulesForm } from './device-type-rules-form';
import { searchFields, dynamicSelectOptions, translateTableData } from './device-type-rules.config';
import * as S from './device-type-rules.styled';

export const DashboardDeviceTypeRulesPage = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation(['deviceTypeRules', 'common']);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const [selectedRuleId, setSelectedRuleId] = useState<string>();
    const [confirmDeleteDeviceTypeRule, setConfirmDeleteDeviceTypeRule] =
        useState<IDeviceTypeRule>();
    const [openDeviceTypeRulesModal, setOpenDeviceTypeRulesModal] = useState(false);
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});

    const {
        data: deviceTypeRules,
        isLoading: isLoadingDeviceTypeRules,
        isError: isGetDeviceTypeRulesError
    } = useQueryGetDeviceTypeRulesList(id ?? '', {
        cacheTime: 0
    });

    const { data: deviceTypes, isLoading: isLoadingDeviceTypeList } = useQueryGetDeviceTypeList();

    const {
        mutate: deleteDeviceTypeRule,

        reset
    } = useMutationDeleteDeviceTypeRule({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.DEVICE_TYPE_RULES]);
            setConfirmDeleteDeviceTypeRule(undefined);
            enqueueSnackbar(t('alert.deleteRuleSuccess'), { variant: 'success' });
        },
        onError: () => {
            setConfirmDeleteDeviceTypeRule(undefined);
            enqueueSnackbar(t('alert.deleteRuleError'), { variant: 'error' });
        }
    });

    const title = deviceTypes?.find(({ uuid }) => uuid === id)?.deviceTypeName;

    const handleEdit = (deviceRule: IDeviceTypeRule) => {
        setSelectedRuleId(deviceRule.deviceRuleUUID);
        setOpenDeviceTypeRulesModal(true);
    };

    const handleDelete = (deviceTypeRuleUUID: string) => {
        deleteDeviceTypeRule({
            deviceTypeRuleUUID
        });
    };

    const handleOpenConfirmDeleteRuleDialog = (deviceRule: IDeviceTypeRule) => {
        setConfirmDeleteDeviceTypeRule(deviceRule);
    };

    const tableHandlers: Array<ITableHandlers<IDeviceTypeRule>> = [
        {
            name: t('tableHandlers.editRules'),
            handler: handleEdit,
            icon: ButtonIcon.EDIT
        },
        {
            name: t('tableHandlers.delete'),
            handler: handleOpenConfirmDeleteRuleDialog,
            icon: ButtonIcon.DELETE,
            color: 'error'
        }
    ];

    const selectData = [
        {
            name: 'type',
            label: t('filters.type'),
            options: dynamicSelectOptions('eventType', DeviceRuleType, t),
            selectAll: true
        }
    ];

    const handleClose = () => {
        setOpenDeviceTypeRulesModal(false);
        setSelectedRuleId(undefined);
        queryClient.removeQueries([QueryKey.DEVICE_TYPE_RULE]);
    };

    return (
        <SectionWrapper
            isLoading={isLoadingDeviceTypeRules || isLoadingDeviceTypeList}
            title={`${title} - ${t('title')}`}
            isError={isGetDeviceTypeRulesError}
        >
            <FiltersWrapper>
                <Filters
                    selects={selectData}
                    fields={searchFields(t)}
                    setSearchParams={setSearchParams}
                    disabled={deviceTypeRules?.length === 0}
                />
                <S.ButtonsWrapper>
                    <Button
                        icon={ButtonIcon.ADD}
                        onClick={() => setOpenDeviceTypeRulesModal(true)}
                        variant="contained"
                    >
                        {t('addRule.button')}
                    </Button>
                </S.ButtonsWrapper>
            </FiltersWrapper>
            <Table<IDeviceTypeRule>
                data={translateTableData(deviceTypeRules ?? [], t)}
                tableHandlers={tableHandlers}
                hide={['deviceRuleUUID']}
                searchParams={searchParams}
            />

            {confirmDeleteDeviceTypeRule && (
                <Dialog
                    open={Boolean(confirmDeleteDeviceTypeRule)}
                    onClose={() => {
                        setConfirmDeleteDeviceTypeRule(undefined);
                        reset();
                    }}
                    onConfirm={() => handleDelete(confirmDeleteDeviceTypeRule.deviceRuleUUID)}
                    desc={`${t('alert.deleteRule', {
                        name: confirmDeleteDeviceTypeRule.text
                    })}`}
                />
            )}

            {openDeviceTypeRulesModal && (
                <Modal
                    title={
                        selectedRuleId ? `${t('addRule.changeRule')}` : `${t('addRule.newRule')}`
                    }
                    open={openDeviceTypeRulesModal}
                    onClose={handleClose}
                >
                    <DashboardDeviceTypesRulesForm
                        selectedRuleId={selectedRuleId}
                        onClose={handleClose}
                    />
                </Modal>
            )}
        </SectionWrapper>
    );
};
