import { Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuthRole } from 'modules/common';
import {
    DashboardLayout,
    DashboardMapPage,
    DashboardUsersPage,
    DashboardCompaniesPage,
    DashboardCompaniesEditPage,
    DashboardDevicesPage,
    DashboardDevicesDetailsPage,
    DashboardDevicesTypesPage,
    DashboardDeviceTypeRulesPage,
    DashboardStatisticsPage,
    DashboardSettingsPage,
    authRoles
} from 'modules/dashboard';
import { useUserData } from 'stores';
import { RoleType } from 'types';
import { paths, ROUTE } from 'utils';

export const DashboardRouter = () => {
    const { userData } = useUserData();
    const role = userData?.role as RoleType;

    return (
        <Routes>
            <Route element={<DashboardLayout />}>
                <Route
                    element={<RequireAuthRole currentRole={role} allowedRoles={authRoles.map} />}
                >
                    <Route index element={<Navigate to={paths.dashboard.map} replace />} />
                    <Route path={ROUTE.DASHBOARD_MAP} element={<DashboardMapPage />} />
                </Route>

                <Route
                    element={<RequireAuthRole currentRole={role} allowedRoles={authRoles.user} />}
                >
                    <Route path={ROUTE.DASHBOARD_USERS} element={<DashboardUsersPage />} />
                </Route>

                <Route
                    element={
                        <RequireAuthRole currentRole={role} allowedRoles={authRoles.company} />
                    }
                >
                    <Route path={ROUTE.DASHBOARD_COMPANIES} element={<DashboardCompaniesPage />} />
                    <Route
                        path={ROUTE.DASHBOARD_COMPANY}
                        element={<DashboardCompaniesEditPage />}
                    />
                </Route>

                <Route
                    element={<RequireAuthRole currentRole={role} allowedRoles={authRoles.device} />}
                >
                    <Route path={ROUTE.DASHBOARD_DEVICES} element={<DashboardDevicesPage />} />
                    <Route
                        path={ROUTE.DASHBOARD_DEVICE}
                        element={<DashboardDevicesDetailsPage />}
                    />
                </Route>

                <Route
                    element={
                        <RequireAuthRole currentRole={role} allowedRoles={authRoles.deviceTypes} />
                    }
                >
                    <Route
                        path={ROUTE.DASHBOARD_DEVICES_TYPES}
                        element={<DashboardDevicesTypesPage />}
                    />
                    <Route
                        path={ROUTE.DASHBOARD_DEVICES_TYPE_RULES}
                        element={<DashboardDeviceTypeRulesPage />}
                    />
                </Route>

                <Route
                    element={<RequireAuthRole currentRole={role} allowedRoles={authRoles.user} />}
                >
                    <Route
                        path={ROUTE.DASHBOARD_STATISTICS}
                        element={<DashboardStatisticsPage />}
                    />
                </Route>

                <Route
                    element={<RequireAuthRole currentRole={role} allowedRoles={authRoles.user} />}
                >
                    <Route path={ROUTE.DASHBOARD_SETTINGS} element={<DashboardSettingsPage />} />
                </Route>

                <Route path="*" element={<Navigate to={paths.notFound} replace />} />
            </Route>
        </Routes>
    );
};
