import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ICompanyDevices } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getCompanyDevices = async (companyUUID: string) => {
    const response = await api.get<Array<ICompanyDevices>>(Endpoint.COMPANY_DEVICES, {
        params: {
            companyUUID
        }
    });

    return response.data;
};

export const useQueryGetCompanyDevices = (
    companyUUID: string,
    options?: IQueryOptions<Array<ICompanyDevices>, Array<ICompanyDevices>>
) =>
    useQuery<Array<ICompanyDevices>, AxiosError, Array<ICompanyDevices>>(
        [QueryKey.COMPANY_DEVICES],
        () => getCompanyDevices(companyUUID),
        {
            select: data =>
                data.map(
                    ({
                        deviceUUID,
                        deviceId,
                        deviceTypeId,
                        deviceTypeName,
                        createdAt,
                        updatedAt
                    }) => ({
                        deviceUUID,
                        deviceId,
                        deviceTypeId,
                        deviceTypeName,
                        createdAt: new Date(createdAt),
                        updatedAt: new Date(updatedAt)
                    })
                ),
            ...options,
            initialData: []
        }
    );
