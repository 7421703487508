import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'components';
import { useNavigate } from 'react-router-dom';

import { paths } from 'utils';
import { IAccountTypeButtons } from './account-type-modal.config';
import * as S from './account-type-modal.styled';

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    buttons: Array<IAccountTypeButtons>;
}

export const AccountTypeModal = ({ open, onClose, title, buttons }: IProps) => {
    const { t } = useTranslation('register');
    const navigate = useNavigate();

    return (
        <Modal open={open} onClose={onClose} title={title}>
            <S.Wrapper>
                {buttons.map(({ label, type }) => (
                    <Button
                        variant="contained"
                        key={label}
                        fullWidth
                        onClick={() => {
                            sessionStorage.setItem('accountType', type);
                            navigate(`${paths.auth.register}/${type}`);
                        }}
                    >
                        {t(`modal.${label}`)}
                    </Button>
                ))}
            </S.Wrapper>
        </Modal>
    );
};
