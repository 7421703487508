import dayjs from 'dayjs';

export const cutDatasetToPresentDay = (date: Date, data?: Array<number>) => {
    const currentMonth = dayjs().month();
    const selectedMonth = dayjs(date).month();

    const isCurrentMonth = currentMonth === selectedMonth;

    if (data && isCurrentMonth) {
        const currentDay = dayjs().date();

        return data.slice(0, currentDay);
    }

    return data;
};
