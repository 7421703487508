import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, Box } from '@mui/material';
import { Input, Select, DatePicker, Button } from 'components';
import { useMutationPostDevice, QueryKey } from 'api';
import { phonePrefix, dateToTimestamp } from 'utils';
import { RoleType, ILocation } from 'types';
import {
    FormWrapper,
    FromButtonsWrapper,
    formFullWidtStyle,
    useDeviceTypeSelect
} from 'modules/dashboard';
import { ErrorMessage } from 'modules/common';
import {
    IDevicesForm,
    dynamicDefaultValues,
    dynamicFormSchema,
    DeviceFormInput
} from './device-form.config';
import { DashboardDeviceFormLocation } from '../device-form-location';

interface IProps {
    handleClose: () => void;
    role: RoleType;
}

export const DashboardDeviceForm = ({ handleClose, role }: IProps) => {
    const [editLocalizationContent, setEditLocalizationContent] = useState<ILocation>();
    const { t } = useTranslation('devices');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { deviceTypesSelect } = useDeviceTypeSelect();

    const methods = useForm<IDevicesForm>({
        defaultValues: dynamicDefaultValues(role),
        resolver: yupResolver(dynamicFormSchema(role)),
        reValidateMode: 'onBlur'
    });

    const { mutate: postDevice, error } = useMutationPostDevice({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.DEVICE_LIST]);
            handleClose();
            enqueueSnackbar(t('alert.addDeviceSuccess'), { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar(t('alert.addDeviceError'), { variant: 'error' });
        }
    });

    const onSubmit = (formData: IDevicesForm) => {
        const {
            deviceId,
            deviceName,
            deviceTypeUUID,
            locationLat,
            locationLng,
            ownershipCompanyNip,
            psc,
            subscriptionExipryUnixTime,
            subscriptionPhonenumber,
            installerNip
        } = formData;

        const deviceData = {
            deviceId,
            deviceName,
            deviceTypeUUID,
            psc,
            location: {
                lat: Number(locationLat),
                long: Number(locationLng)
            },
            ownershipCompanyNip: Number(ownershipCompanyNip),
            installerNip: installerNip ? Number(installerNip) : undefined,
            subscriptionExipryUnixTime: dateToTimestamp(subscriptionExipryUnixTime),
            subscriptionPhonenumber: `${phonePrefix}${subscriptionPhonenumber}`
        };

        postDevice(deviceData);
    };

    const handleCloseLocationModal = () => {
        setEditLocalizationContent(undefined);
    };

    const handleSetLocation = (location: ILocation) => {
        const { lat, lng } = location;
        methods.setValue(DeviceFormInput.LOCATION_LAT, lat.toString());
        methods.setValue(DeviceFormInput.LOCATION_LNG, lng.toString());
        methods.trigger(DeviceFormInput.LOCATION_LAT);
        methods.trigger(DeviceFormInput.LOCATION_LNG);
        handleCloseLocationModal();
    };

    return (
        <FormProvider {...methods}>
            <Paper>
                <FormWrapper
                    component="form"
                    onSubmit={methods.handleSubmit(onSubmit)}
                    sx={theme => ({
                        rowGap: theme.spacing(2)
                    })}
                >
                    <Box sx={{ ...formFullWidtStyle }}>
                        <ErrorMessage error={error} />
                    </Box>
                    <Select
                        name={DeviceFormInput.DEVICE_TYPE_UUID}
                        label={t(`addDevice.form.label.${DeviceFormInput.DEVICE_TYPE_UUID}`)}
                        options={deviceTypesSelect}
                        sx={formFullWidtStyle}
                    />
                    <Input
                        name={DeviceFormInput.DEVICE_ID}
                        label={t(`addDevice.form.label.${DeviceFormInput.DEVICE_ID}`)}
                    />
                    <Input
                        name={DeviceFormInput.DEVICE_NAME}
                        label={t(`addDevice.form.label.${DeviceFormInput.DEVICE_NAME}`)}
                    />
                    <Input name={DeviceFormInput.PSC} label="PSC" />
                    <Input
                        name={DeviceFormInput.PHONE_NUMBER}
                        label={t(`addDevice.form.label.${DeviceFormInput.PHONE_NUMBER}`)}
                        phonePrefix={phonePrefix}
                    />

                    <Input
                        name={DeviceFormInput.COMPANY_NIP}
                        label={t(`addDevice.form.label.${DeviceFormInput.COMPANY_NIP}`)}
                        type="number"
                        sx={role !== RoleType.Admin ? formFullWidtStyle : {}}
                    />

                    {role === RoleType.Admin && (
                        <Input
                            name={DeviceFormInput.INSTALLER_NIP}
                            type="number"
                            label={t(`addDevice.form.label.${DeviceFormInput.INSTALLER_NIP}`)}
                        />
                    )}

                    <Input
                        name={DeviceFormInput.LOCATION_LAT}
                        label={t(`addDevice.form.label.${DeviceFormInput.LOCATION_LAT}`)}
                        type="number"
                        inputProps={{
                            readOnly: true
                        }}
                        onClick={() => setEditLocalizationContent({ lat: 52.24, lng: 21.01 })}
                    />

                    <Input
                        name={DeviceFormInput.LOCATION_LNG}
                        label={t(`addDevice.form.label.${DeviceFormInput.LOCATION_LNG}`)}
                        type="number"
                        inputProps={{
                            readOnly: true
                        }}
                        onClick={() => setEditLocalizationContent({ lat: 52.24, lng: 21.01 })}
                    />

                    <DatePicker
                        name={DeviceFormInput.SUBSCRIPTION_EXPIRY}
                        label={t(`addDevice.form.label.${DeviceFormInput.SUBSCRIPTION_EXPIRY}`)}
                        sx={formFullWidtStyle}
                        disablePast
                    />

                    {editLocalizationContent && (
                        <DashboardDeviceFormLocation
                            data={editLocalizationContent}
                            title={`${t('addDevice.form.locationFormTitle')}`}
                            onClose={handleCloseLocationModal}
                            submitHandler={handleSetLocation}
                        />
                    )}

                    <FromButtonsWrapper>
                        <Button type="submit" variant="contained">
                            {t('addDevice.form.submitButton')}
                        </Button>
                    </FromButtonsWrapper>
                </FormWrapper>
            </Paper>
        </FormProvider>
    );
};
