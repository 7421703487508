import { BoxProps, Alert, Stack } from '@mui/material';
import { Spinner } from 'components';
import { useTranslation } from 'react-i18next';
import * as S from './section-wrapper.styled';

interface IProps extends BoxProps {
    title?: string;
    subtitles?: Array<string>;
    isLoading?: boolean;
    isError?: boolean;
    errorMessage?: string;
    children: React.ReactNode;
}

export const SectionWrapper = ({
    title,
    subtitles,
    isLoading,
    isError,
    errorMessage,
    children,
    ...rest
}: IProps) => {
    const { t } = useTranslation('alerts');

    const error = isError || errorMessage;

    return (
        <S.Wrapper {...rest}>
            {isLoading && !error && <Spinner />}
            {!isLoading && !error && (
                <>
                    {title && <S.Title variant="h5">{title}</S.Title>}
                    {subtitles?.map((subtitle, index) => (
                        <S.Subtitle key={`subtitle-${index}`} variant="h6">
                            {subtitle}
                        </S.Subtitle>
                    ))}

                    {children}
                </>
            )}

            {error && (
                <Stack height="100%" alignItems="center" justifyContent="center">
                    <Alert severity="error">{errorMessage || t('fetchDataError')}</Alert>
                </Stack>
            )}
        </S.Wrapper>
    );
};
