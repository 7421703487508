import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useUserData } from 'stores';
import { paths } from 'utils';
import { Spinner } from 'components';
import { Box } from '@mui/material';
import { Navbar, Drawer } from '../components';
import { authRolesNavItems } from '../dashboard.config';

export const DashboardLayout = () => {
    const navigate = useNavigate();
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const { isLoggedIn, isPending, userData } = useUserData();

    useEffect(() => {
        if (!isLoggedIn && !isPending) {
            navigate(paths.auth.self);
        }
    }, [isLoggedIn, isPending]);

    return (
        <Box sx={{ display: 'flex', paddingTop: '30px', height: '100vh' }}>
            {isLoggedIn && !isPending && userData ? (
                <>
                    <Navbar toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} />
                    <Drawer
                        isOpen={toggleDrawer}
                        handleClose={() => setToggleDrawer(false)}
                        items={authRolesNavItems[userData.role]}
                    />
                    <Outlet />
                </>
            ) : (
                <Spinner
                    sx={{
                        color: 'primary.dark'
                    }}
                />
            )}
        </Box>
    );
};
