import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Box, BoxProps, Menu } from '@mui/material';
import { Language, CURRENT_LANGUAGE } from 'i18/locale';
import { handleIcon } from './language-switcher.icons';
import * as S from './language-switcher.styled';

interface IProps extends BoxProps {
    reloadRequiredPath?: string;
}

export const LanguageSwitcher = ({ reloadRequiredPath, ...rest }: IProps) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const lng = (localStorage.getItem(CURRENT_LANGUAGE) as Language) ?? Language.PL;
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(lng);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeLang = (lng: Language) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        localStorage.setItem(CURRENT_LANGUAGE, lng);
    };

    const handleOnChange = (lng: Language) => {
        setAnchorEl(null);

        if (lng === selectedLanguage) {
            return;
        }

        handleChangeLang(lng);

        if (location.pathname === reloadRequiredPath) {
            window.location.reload();
        }
    };

    useEffect(() => {
        handleChangeLang(lng);
    }, [lng]);

    return (
        <Box {...rest}>
            <Tooltip title={t('languageSwitcher.tooltip')}>
                <S.IconButton disableRipple id="language-switcher-button" onClick={handleOpenMenu}>
                    {handleIcon(selectedLanguage)}
                </S.IconButton>
            </Tooltip>
            <Menu
                id="language-switcher-menu"
                aria-labelledby="language-switcher-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {Object.values(Language).map(lng => (
                    <S.MenuItem key={`language-menu-${lng}`} onClick={() => handleOnChange(lng)}>
                        {handleIcon(lng)} {lng}
                    </S.MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
