import { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, UseQueryOptions, QueryKey, UseMutationOptions } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    }
});

export type IQueryOptions<T, D> = UseQueryOptions<T, AxiosError, D, QueryKey>;

export type IMutationOptions<T, D = AxiosResponse<null>> = UseMutationOptions<D, AxiosError, T>;
