import { Box, Alert, type BoxProps, type AlertProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ICommonAlert extends BoxProps {
    message?: string;
    severity?: AlertProps['severity'];
}

export const CommonAlert = ({ message, severity, ...rest }: ICommonAlert) => {
    const { t } = useTranslation('alerts');

    return (
        <Box {...rest}>
            <Alert severity={severity || 'error'}>{message || t('fetchDataError')}</Alert>
        </Box>
    );
};
