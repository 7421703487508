import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceHistoryLocationResponse, IDeviceHistoryLocation } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceHistoryLocation = async (deviceUUID: string) => {
    const response = await api.get<Array<IDeviceHistoryLocationResponse>>(
        Endpoint.DEVICE_HISTORY_LOCATION,
        {
            params: {
                deviceUUID
            }
        }
    );

    return response.data;
};

export const useQueryGetDeviceHistoryLocation = (
    deviceUUID: string,
    options?: IQueryOptions<Array<IDeviceHistoryLocationResponse>, Array<IDeviceHistoryLocation>>
) =>
    useQuery<Array<IDeviceHistoryLocationResponse>, AxiosError, Array<IDeviceHistoryLocation>>(
        [QueryKey.DEVICE_HISTORY_LOCATION],
        () => getDeviceHistoryLocation(deviceUUID),
        {
            select: data =>
                data.map(
                    ({ name, surname, email, companyName, companyNip, lat, long, createdAt }) => ({
                        createdAt: new Date(createdAt),
                        nameAndSurname: `${name} ${surname}`,
                        email,
                        companyName,
                        companyNip,
                        lat,
                        lng: long
                    })
                ),
            ...options
        }
    );
