import { useTranslation } from 'react-i18next';
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    type DialogProps,
    type ButtonProps,
    type DialogContentTextProps
} from '@mui/material';

import * as S from './dialog.styled';

interface IProps extends Omit<DialogProps, 'onClose' | 'open'> {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    desc?: string;
    textContentProps?: DialogContentTextProps;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    confirmButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
}

export const Dialog = ({
    open,
    onClose,
    onConfirm,
    title,
    desc,
    textContentProps,
    cancelButtonLabel,
    confirmButtonLabel,
    confirmButtonProps,
    cancelButtonProps,
    ...rest
}: IProps) => {
    const { t } = useTranslation();

    return (
        <S.Dialog keepMounted={false} onClose={onClose} open={open} {...rest}>
            <DialogTitle>{title ?? t('confirm.title')}</DialogTitle>
            {desc && (
                <DialogContent>
                    <DialogContentText {...textContentProps}>{desc}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} {...cancelButtonProps}>
                    {cancelButtonLabel ?? t('confirm.cancel')}
                </Button>
                <Button onClick={onConfirm} {...confirmButtonProps}>
                    {confirmButtonLabel ?? t('confirm.yes')}
                </Button>
            </DialogActions>
        </S.Dialog>
    );
};
