import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tabs, Table } from 'components';
import {
    DeviceDetailsTab,
    IDecviceHistoryMaintenance,
    IDeviceHistoryLocation,
    IDeviceHistoryEvent,
    RoleType
} from 'types';
import { useUserData } from 'stores';
import { SectionWrapper } from 'modules/dashboard';
import { deviceDetailsTabsByRole } from './device-details-page.config';
import { useGetDeviceDetailsData } from '../hooks';
import { DeviceDetailsCard, DeviceDetailsCardLocation } from '../device-details-card';
import * as S from './device-details-page.styled';

export const DashboardDevicesDetailsPage = () => {
    const { t } = useTranslation(['deviceDetails', 'common', 'alerts']);
    const { userData } = useUserData();
    const { id } = useParams();

    const [selected, setSelectedValue] = useState<DeviceDetailsTab>(DeviceDetailsTab.EVENTS);
    const {
        deviceData,
        deviceHistoryEventsData,
        isDeviceHistoryEventsDataLoading,
        deviceHistoryMaintenanceData,
        isDeviceHistoryMaintenanceDataLoading,
        deviceHistoryLocationData,
        isDeviceHistoryLocationDataLoading,
        deviceHistorySubscriptionData,
        isDeviceHistorySubscriptionDataLoading,
        isLoading,
        tabsErrorMessages,
        isDeviceDataError
    } = useGetDeviceDetailsData(id as string, selected);

    const role = userData?.role as RoleType;

    const renderComponent = (view: DeviceDetailsTab) => {
        switch (view) {
            case DeviceDetailsTab.MAINTENANCE:
                return (
                    <Table<IDecviceHistoryMaintenance>
                        data={deviceHistoryMaintenanceData ?? []}
                        errorMessage={tabsErrorMessages[DeviceDetailsTab.MAINTENANCE]}
                        isLoading={isDeviceHistoryMaintenanceDataLoading}
                    />
                );
            case DeviceDetailsTab.LOCATION:
                return (
                    <Table<IDeviceHistoryLocation>
                        data={deviceHistoryLocationData ?? []}
                        errorMessage={tabsErrorMessages[DeviceDetailsTab.LOCATION]}
                        isLoading={isDeviceHistoryLocationDataLoading}
                    />
                );
            case DeviceDetailsTab.SUBSCRIPTION:
                return (
                    <Table
                        data={deviceHistorySubscriptionData ?? []}
                        errorMessage={tabsErrorMessages[DeviceDetailsTab.SUBSCRIPTION]}
                        isLoading={isDeviceHistorySubscriptionDataLoading}
                    />
                );
            default:
                return (
                    <Table<IDeviceHistoryEvent>
                        data={deviceHistoryEventsData ?? []}
                        errorMessage={tabsErrorMessages[DeviceDetailsTab.EVENTS]}
                        isLoading={isDeviceHistoryEventsDataLoading}
                    />
                );
        }
    };

    return (
        <SectionWrapper title={`${t('title')}`} isLoading={isLoading} isError={isDeviceDataError}>
            {deviceData && (
                <S.CardsWrapper>
                    <DeviceDetailsCard data={deviceData} />
                    <DeviceDetailsCardLocation data={deviceData} />
                </S.CardsWrapper>
            )}

            <Tabs<DeviceDetailsTab>
                values={deviceDetailsTabsByRole(role)}
                selected={selected}
                setValue={setSelectedValue}
            />
            {renderComponent(selected)}
        </SectionWrapper>
    );
};
