import { useState, useEffect } from 'react';
import { useQueryGetDeviceTypeList } from 'api';
import { IDeviceType, ISelectOptions } from 'types';

export const useDeviceTypeSelect = (hold?: boolean) => {
    const [deviceTypesSelect, setDeviceTypesSelect] = useState<Array<ISelectOptions>>([]);
    const { data: deviceTypes, isFetched } = useQueryGetDeviceTypeList({
        enabled: !hold
    });

    const createSelectData = (deviceTypes: Array<IDeviceType>): Array<ISelectOptions> =>
        deviceTypes.map(({ deviceTypeName, uuid }) => ({
            label: deviceTypeName,
            value: uuid
        }));

    useEffect(() => {
        if (isFetched && deviceTypes) {
            setDeviceTypesSelect(createSelectData(deviceTypes));
        }
    }, [isFetched, deviceTypes]);

    const getDeviceValueByLabel = (deviceTypeLabel: string) =>
        deviceTypesSelect
            .filter(deviceType => deviceType.label === deviceTypeLabel)
            .map(deviceType => deviceType.value)
            .toString();

    return { deviceTypesSelect, getDeviceValueByLabel };
};
