import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Button } from 'components';
import { useMutationPostLogin } from 'api';
import { FormWrapper, AccountTypeModal, accountTypeButtons, AuthInput } from 'modules/auth';
import { ErrorMessage } from 'modules/common';
import { paths } from 'utils';
import { ILoginForm, defaultValues, formSchema } from './sign-in-form.config';
import * as S from './sign-in-page.styled';

export const AuthSignInPage = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const { t } = useTranslation(['login', 'register']);

    const methods = useForm<ILoginForm>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const { mutate: postLogin, isLoading, error } = useMutationPostLogin();

    const onSubmit = (data: ILoginForm) => {
        postLogin(data);
    };

    return (
        <FormWrapper title={t('title')}>
            <ErrorMessage error={error} />

            <FormProvider {...methods}>
                <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <S.Wrapper>
                        <AuthInput name="email" label={t('form.email')} disabled={isLoading} />
                        <AuthInput
                            name="password"
                            type="password"
                            label={t('form.password')}
                            disabled={isLoading}
                        />
                    </S.Wrapper>

                    <S.ButtonsWrapper>
                        <Button
                            isLoading={isLoading}
                            disabled={isLoading}
                            fullWidth
                            variant="contained"
                            type="submit"
                        >
                            {t('form.submit')}
                        </Button>
                        <Button disabled={isLoading} to={paths.auth.forgotPassword} fullWidth>
                            {t('form.forgot')}
                        </Button>
                        <Button disabled={isLoading} onClick={() => setToggleModal(true)} fullWidth>
                            {t('form.register')}
                        </Button>
                    </S.ButtonsWrapper>
                </Box>
            </FormProvider>
            {toggleModal && (
                <AccountTypeModal
                    open={toggleModal}
                    onClose={() => setToggleModal(false)}
                    title={t('register:modal.title')}
                    buttons={accountTypeButtons}
                />
            )}
        </FormWrapper>
    );
};
