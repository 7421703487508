import * as yup from 'yup';
import {
    regexSchema,
    requiredStringSchema,
    emailSchema,
    passwordSchema,
    passwordRepeatSchema,
    isCheckedSchema,
    nipSchema,
    phoneSchema
} from 'utils';

export enum RegisterInput {
    NAME = 'name',
    SURNAME = 'surname',
    EMAIL = 'email',
    PASSWORD = 'password',
    PASSWORD_CONFIRMATION = 'passwordConfirmation',
    PHONENUMBER = 'phonenumber',
    COMPANY_NIP = 'companyNip',
    COMPANY_NAME = 'companyName',
    COMPANY_ADDRESS_STREET = 'companyAddressStreet',
    COMPANY_ADDRESS_COUNTRY = 'companyAddressCountry',
    COMPANY_ADDRESS_CITY = 'companyAddressCity',
    COMPANY_ADDRESS_POSTALCODE = 'companyAddressPostalcode',
    COMPANY_ADDRESS_PROVINCE = 'companyAddressProvince',
    VERIFICATION_CODE = 'verificationCode',
    TERMS_AGREEMENT = 'termsAgreement',
    RODO_AGREEMENT = 'rodoAgreement'
}

export interface IRegisterFrom {
    [RegisterInput.NAME]: string;
    [RegisterInput.SURNAME]: string;
    [RegisterInput.EMAIL]: string;
    [RegisterInput.PASSWORD]: string;
    [RegisterInput.PASSWORD_CONFIRMATION]: string;
    [RegisterInput.PHONENUMBER]: string;
    [RegisterInput.COMPANY_NIP]: string;
    [RegisterInput.COMPANY_NAME]: string;
    [RegisterInput.COMPANY_ADDRESS_STREET]: string;
    [RegisterInput.COMPANY_ADDRESS_COUNTRY]: string;
    [RegisterInput.COMPANY_ADDRESS_CITY]: string;
    [RegisterInput.COMPANY_ADDRESS_POSTALCODE]: string;
    [RegisterInput.COMPANY_ADDRESS_PROVINCE]: string;
    [RegisterInput.VERIFICATION_CODE]?: string;
    [RegisterInput.TERMS_AGREEMENT]: boolean;
    [RegisterInput.RODO_AGREEMENT]: boolean;
}

const defaultValues: IRegisterFrom = {
    [RegisterInput.NAME]: '',
    [RegisterInput.SURNAME]: '',
    [RegisterInput.EMAIL]: '',
    [RegisterInput.PASSWORD]: '',
    [RegisterInput.PASSWORD_CONFIRMATION]: '',
    [RegisterInput.PHONENUMBER]: '',
    [RegisterInput.COMPANY_NIP]: '',
    [RegisterInput.COMPANY_NAME]: '',
    [RegisterInput.COMPANY_ADDRESS_STREET]: '',
    [RegisterInput.COMPANY_ADDRESS_COUNTRY]: '',
    [RegisterInput.COMPANY_ADDRESS_CITY]: '',
    [RegisterInput.COMPANY_ADDRESS_POSTALCODE]: '',
    [RegisterInput.COMPANY_ADDRESS_PROVINCE]: '',
    [RegisterInput.TERMS_AGREEMENT]: false,
    [RegisterInput.RODO_AGREEMENT]: false
};

export const dynamicDefaultValues = (verifyRequired: boolean): IRegisterFrom => {
    switch (true) {
        case verifyRequired:
            return {
                ...defaultValues,
                verificationCode: ''
            };

        default:
            return defaultValues;
    }
};

const formSchema = yup.object({
    [RegisterInput.NAME]: regexSchema('name'),
    [RegisterInput.SURNAME]: regexSchema('surname'),
    [RegisterInput.EMAIL]: emailSchema,
    [RegisterInput.PASSWORD]: passwordSchema,
    [RegisterInput.PASSWORD_CONFIRMATION]: passwordRepeatSchema,
    [RegisterInput.PHONENUMBER]: phoneSchema,
    [RegisterInput.COMPANY_NIP]: nipSchema,
    [RegisterInput.COMPANY_NAME]: requiredStringSchema,
    [RegisterInput.COMPANY_ADDRESS_STREET]: requiredStringSchema,
    [RegisterInput.COMPANY_ADDRESS_COUNTRY]: requiredStringSchema,
    [RegisterInput.COMPANY_ADDRESS_CITY]: requiredStringSchema,
    [RegisterInput.COMPANY_ADDRESS_POSTALCODE]: regexSchema('zip_code'),
    [RegisterInput.COMPANY_ADDRESS_PROVINCE]: requiredStringSchema,
    [RegisterInput.TERMS_AGREEMENT]: isCheckedSchema,
    [RegisterInput.RODO_AGREEMENT]: isCheckedSchema
});

export const dynamicFormSchema = (verifyRequired: boolean) => {
    switch (true) {
        case verifyRequired:
            return formSchema.shape({
                [RegisterInput.VERIFICATION_CODE]: requiredStringSchema
            });

        default:
            return formSchema;
    }
};
