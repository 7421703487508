import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

export const Wrapper = styled(Paper)(({ theme }) => ({
    width: '90%',
    padding: theme.spacing(5, 4),

    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 8),
        maxWidth: 580
    }
}));

export const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.h4,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
}));
