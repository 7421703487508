import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MainContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    justifyContent: 'center',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
    paddingTop: theme.spacing(2)
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gridColumn: '1 / -1',
    marginTop: theme.spacing(1),
    rowGap: theme.spacing(1)
}));
