import { styled } from '@mui/material/styles';
import { TextField as MUITextField } from '@mui/material';

interface IColorBoxProps {
    colorCode?: string;
}

export const TextField = styled(MUITextField, {
    shouldForwardProp: prop => prop !== 'colorCode'
})<IColorBoxProps>(({ theme, colorCode }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper
    },
    '& .MuiInputBase-root .Mui-focused': {
        backgroundColor: theme.palette.background.paper
    },
    '&:hover': {
        backgroundColor: theme.palette.background.paper
    },
    '& .MuiInputLabel-root': {
        zIndex: 1
    },
    '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        whiteSpace: 'wrap'
    },

    '& input::-webkit-color-swatch-wrapper': {
        width: '24px',
        height: '24px',
        padding: '2px'
    },
    '& input::-webkit-color-swatch': {
        borderRadius: '50% !important',
        boxShadow: `0 0 1px ${colorCode}, 0 0 2px ${colorCode}, 0 0 4px ${colorCode}`,
        border: `1px solid ${colorCode === '#FFFFFF' ? theme.palette.grey[200] : 'transparent'}`,
        outline: 'none'
    },

    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
        {
            WebkitAppearance: 'none'
        }
}));
