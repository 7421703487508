export enum RoleType {
    Admin = 'Admin',
    Installer = 'Installer',
    Client = 'Client',
    Device = 'Device'
}

export interface IUserLoginResponse {
    accessToken: string;
    refreshToken: string;
}

export interface IPostLogin {
    email: string;
    password: string;
}

export interface IUserData {
    accountUUID: string;
    email: string;
    exp: number;
    iat: number;
    name: string;
    role: RoleType;
    sessionUUID: string;
    surname: string;
}

export type IPostDeleteAccount = {
    accountUUID: string;
};

export type IPostPasswordForgot = Pick<IPostLogin, 'email'>;

export type IPostActivateAccount = Pick<IPostDeleteAccount, 'accountUUID'>;

export type IPostVerifyAccount = Pick<IPostDeleteAccount, 'accountUUID'>;

export type IPostCreateAccount = {
    companyUUID: string;
    name: string;
    surname: string;
    email: string;
    role: string;
    phonenumber: string;
};

export interface IPostActivate {
    token: string;
}

export interface IPostPasswordReset {
    password: string;
    token: string;
}
