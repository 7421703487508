import { IGoogleMapsConfig } from 'types';

import HydrantIcon from 'assets/hydrant.svg';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'lib';

export const googleMapsConfig: IGoogleMapsConfig = {
    libraries: ['places'],
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || '',
    mapOptions: {
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: true,
        mapTypeControlOptions: {
            position: 3,
        },
    },
    markerOptions: {
        icon: {
            url: HydrantIcon
        }
    }
};
