import { TFunction } from 'i18next';
import { IDeviceList, RoleType } from 'types';

export enum TabValue {
    ALL_DEVICES = 'allDevices',
    ACTIVE_DEVICES = 'activeDevices',
    INACTIVE_DEVICES = 'inactiveDevices'
}

export const searchFields = (t: TFunction) => [
    {
        name: 'deviceId',
        label: t('devices:filters.deviceId')
    },
    {
        name: 'devicePSC',
        label: t('devices:filters.devicePSC')
    },
    {
        name: 'deviceOwnerName',
        label: t('devices:filters.deviceOwnerName')
    },
    {
        name: 'deviceOwnerNip',
        label: t('devices:filters.deviceOwnerNip')
    }
];

export const hideColumns: Array<keyof IDeviceList> = [
    'deviceUUID',
    'deviceName',
    'isAwake',
    'subscriptionTransitMethod',
    'subscriptionPhonenumber',
    'lastEventText',
    'lastEventIcon',
    'lastEventColor',
    'lastEventAt',
    'batteryLevel',
    'batteryLevelAt',
    'waterDrainedInMetersSq'
];

export const disableCellFormat: Array<keyof IDeviceList> = ['deviceId'];

export const allowedUsers = [RoleType.Admin, RoleType.Installer];
