import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutationEditAccount, QueryKey } from 'api';
import { Modal, Input, Button } from 'components';
import { ErrorMessage } from 'modules/common';
import { IAccount } from 'types';
import { FormWrapper, FromButtonsWrapper, formFullWidtStyle } from 'modules/dashboard';
import {
    IEditAccoutForm,
    AccountEditInput,
    dynamicDefaultValues,
    formSchema
} from './user-edit-from.config';

interface IProps {
    data: IAccount;
    onClose: () => void;
}

export const DashboardUsersEditForm = ({ data, onClose }: IProps) => {
    const { t } = useTranslation('users');
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm<IEditAccoutForm>({
        defaultValues: dynamicDefaultValues(data),
        resolver: yupResolver(formSchema)
    });

    const { mutate: editAccount, error } = useMutationEditAccount({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.ACCOUNT_LIST]);
            enqueueSnackbar(t('alert.editUserSuccess'), {
                variant: 'success'
            });
            onClose();
        },
        onError: () => {
            enqueueSnackbar(t('alert.editUserError'), {
                variant: 'error'
            });
        }
    });

    const onSubmit = (formData: IEditAccoutForm) => {
        const { name, surname } = formData;

        const editAccountData = {
            accountUUID: `${data.uuid}`,
            name,
            surname
        };

        editAccount(editAccountData);
    };

    return (
        <Modal
            open={Boolean(data)}
            onClose={onClose}
            title={`${t('form.title', {
                name: data.nameAndSurname
            })}`}
        >
            <FormProvider {...methods}>
                <FormWrapper
                    onSubmit={methods.handleSubmit(onSubmit)}
                    component="form"
                    sx={{
                        padding: 1
                    }}
                >
                    <ErrorMessage
                        error={error}
                        sx={{
                            ...formFullWidtStyle,
                            marginBottom: 1
                        }}
                    />
                    {Object.values(AccountEditInput).map(item => (
                        <Input
                            key={`accout-input-${item}`}
                            name={item}
                            label={t(`form.inputLabel.${item}`)}
                            style={formFullWidtStyle}
                        />
                    ))}
                    <FromButtonsWrapper>
                        <Button type="submit" variant="contained" color="primary">
                            {t('form.save')}
                        </Button>
                    </FromButtonsWrapper>
                </FormWrapper>
            </FormProvider>
        </Modal>
    );
};
