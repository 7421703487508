import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostCreateAccount } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationCreateAccount = (options?: IMutationOptions<IPostCreateAccount>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostCreateAccount>(
        async data => api.post(Endpoint.ACCOUNT, data),
        options
    );
