import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ICompanyUsersResponse, ICompanyUsersList } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getCompanyUsers = async (companyUUID: string) => {
    const response = await api.get<Array<ICompanyUsersResponse>>(Endpoint.COMPANY_USERS, {
        params: {
            companyUUID
        }
    });

    return response.data;
};

export const useQueryGetCompanyUsers = (
    companyUUID: string,
    options?: IQueryOptions<Array<ICompanyUsersResponse>, Array<ICompanyUsersList>>
) =>
    useQuery<Array<ICompanyUsersResponse>, AxiosError, Array<ICompanyUsersList>>(
        [QueryKey.COMPANY_USERS],
        () => getCompanyUsers(companyUUID),
        {
            select: data =>
                data.map(
                    ({
                        createdAt,
                        email,
                        isActivated,
                        isVerified,
                        name,
                        surname,
                        phonenumber
                    }) => ({
                        name,
                        surname,
                        email,
                        phonenumber,
                        createdAt: new Date(createdAt),
                        isActivated,
                        isVerified
                    })
                ),

            ...options
        }
    );
