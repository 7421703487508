import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IGetStatsParams, IStatsEventsData } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

type IStatsEvents = Array<IStatsEventsData>;

export const getStatsEvents = async (params: IGetStatsParams) => {
    const response = await api.get<IStatsEvents>(Endpoint.STATS_EVENTS, {
        params
    });

    return response.data;
};

export const useQueryGetStatsEvents = (
    params: IGetStatsParams,
    options?: IQueryOptions<IStatsEvents, IStatsEvents>
) =>
    useQuery<IStatsEvents, AxiosError, IStatsEvents>(
        [QueryKey.STATS_EVENTS],
        () => getStatsEvents(params),
        {
            ...options
        }
    );
