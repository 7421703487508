import { styled } from '@mui/material/styles';
import { Modal as MUIModal, Paper as MUIPaper, Typography, IconButton } from '@mui/material';

export const Modal = styled(MUIModal)(() => ({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backdropFilter: 'blur(2px)'
    }
}));

export const Paper = styled(MUIPaper)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '95%',

    [theme.breakpoints.up('sm')]: {
        maxWidth: 500
    }
}));

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(2),
    width: '90%'
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
    width: 'fit-content',
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(1)
}));

Title.defaultProps = {
    variant: 'h6'
};
