import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { ICompanyResponse, ICompany } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getCompany = async (companyUUID: string) => {
    const response = await api.get<ICompanyResponse>(Endpoint.COMPANY, {
        params: {
            companyUUID
        }
    });

    return response.data;
};

export const useQueryGetCompany = (
    companyUUID: string,
    options?: IQueryOptions<ICompanyResponse, ICompany>
) =>
    useQuery<ICompanyResponse, AxiosError, ICompany>(
        [QueryKey.COMPANY],
        () => getCompany(companyUUID),
        {
            select: ({ address, name, nip, uuid }) => ({
                ...address,
                companyName: name,
                nip: Number(nip),
                uuid
            }),
            ...options
        }
    );
