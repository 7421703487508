import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
    useQueryGetAccountList,
    useMutationDeleteAccount,
    useMutationActivateAccount,
    useMutationVerifyAccount,
    QueryKey
} from 'api';
import type { IAccoutListParams } from 'types';

export const useUsersData = (onClose: () => void, params?: IAccoutListParams) => {
    const { t } = useTranslation('users');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const {
        data: usersData,
        isFetching: usersDataIsLoading,
        refetch: usersDataRefetch,
        isError: isGetAccountListError
    } = useQueryGetAccountList(params, {
        cacheTime: 0
    });

    const { mutate: deleteUser, reset: deleteUserMutationReset } = useMutationDeleteAccount({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.ACCOUNT_LIST]);
            onClose();
            enqueueSnackbar(t('alert.deleteUserSuccess'), { variant: 'success' });
        },
        onError: () => {
            onClose();
            enqueueSnackbar(t('alert.deleteUserError'), { variant: 'error' });
        }
    });

    const { mutate: activateUser } = useMutationActivateAccount({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.ACCOUNT_LIST]);
            enqueueSnackbar(t('alert.activateUserSuccess'), { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar(t('alert.activateUserError'), { variant: 'error' });
        }
    });

    const { mutate: verifyUser } = useMutationVerifyAccount({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.ACCOUNT_LIST]);
            enqueueSnackbar(t('alert.verifyUserSuccess'), { variant: 'success' });
        },

        onError: () => {
            enqueueSnackbar(t('alert.verifyUserError'), { variant: 'error' });
        }
    });

    return {
        usersData,
        usersDataRefetch,
        usersDataIsLoading,
        deleteUser,
        deleteUserMutationReset,
        activateUser,
        verifyUser,
        isGetAccountListError
    };
};
