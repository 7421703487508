import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Select as CustomSelect } from '../form';

export const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
    },

    columnGap: theme.spacing(2)
}));

export const Select = styled(CustomSelect)(() => ({
    minWidth: '190px'
}));

Select.defaultProps = {
    size: 'small',
    variant: 'filled'
};
