import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ISystemConditionsResponse, ISystemCondition } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getSystemConditions = async () => {
    const response = await api.get<ISystemConditionsResponse>(Endpoint.SYSTEM_CONDITIONS_PUBLIC);

    return response.data;
};

export const useQueryGetSystemConditions = (
    options?: IQueryOptions<ISystemConditionsResponse, ISystemCondition>
) =>
    useQuery<ISystemConditionsResponse, AxiosError, ISystemCondition>(
        [QueryKey.SYSTEM_CONDITIONS_PUBLIC],
        getSystemConditions,
        {
            select: ({ isVerificationCodeRequired }) => ({
                verifyIsRequired: isVerificationCodeRequired
            }),
            ...options
        }
    );
