import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Link } from '@mui/material';
import { Button, Switch, Spinner } from 'components';
import { useMutationPostRegister, useQueryGetSystemConditions } from 'api';
import { FormWrapper, AuthInput } from 'modules/auth';
import { ErrorMessage } from 'modules/common';
import { paths, phonePrefix } from 'utils';
import { RoleType } from 'types';
import {
    IRegisterFrom,
    dynamicDefaultValues,
    dynamicFormSchema,
    RegisterInput
} from './register-form.config';
import * as S from './register-page.styled';

export const AuthRegisterPage = () => {
    const { t } = useTranslation('register');
    const navigate = useNavigate();
    const { type } = useParams<{ type: RoleType }>();
    const isInstaler = type === RoleType.Installer;
    const {
        data,
        isFetching: verifyIsRequiredLoading,
        error: verifyIsRequiredError,
        remove
    } = useQueryGetSystemConditions({
        enabled: isInstaler
    });

    const verifyIsRequired = isInstaler && Boolean(data?.verifyIsRequired);

    const methods = useForm<IRegisterFrom>({
        defaultValues: dynamicDefaultValues(verifyIsRequired),
        resolver: yupResolver(dynamicFormSchema(verifyIsRequired))
    });

    const {
        mutate: postRegister,
        error: registerError,
        isLoading: registerIsLoading
    } = useMutationPostRegister({
        onSuccess: () => {
            methods.reset();
            navigate(paths.auth.activate);
        }
    });

    const onSubmit = (formData: IRegisterFrom) => {
        const {
            name,
            surname,
            email,
            password,
            phonenumber,
            companyNip,
            companyName,
            companyAddressStreet,
            companyAddressCountry,
            companyAddressCity,
            companyAddressPostalcode,
            companyAddressProvince
        } = formData;

        postRegister({
            role: type as RoleType,
            name,
            surname,
            email,
            password,
            phonenumber: `${phonePrefix}${phonenumber}`,
            companyNip: Number(companyNip),
            companyName,
            companyAddressStreet,
            companyAddressCountry,
            companyAddressCity,
            companyAddressPostalcode,
            companyAddressProvince
        });
    };

    useEffect(() => {
        const registerTypes = Object.values(RoleType);

        if (type && !registerTypes.includes(type)) {
            navigate(paths.notFound);
        }
    }, [type]);

    useEffect(() => () => remove(), []);

    return (
        <FormWrapper
            sx={{
                minHeight: '743px'
            }}
            title={`${isInstaler ? `${t('titleInstaller')}` : `${t('titleCustomer')}`}`}
        >
            {registerError && <Alert severity="error">{t('generalError')}</Alert>}
            {verifyIsRequiredError && <ErrorMessage error={verifyIsRequiredError} />}
            {!verifyIsRequiredLoading ? (
                <FormProvider {...methods}>
                    <S.MainContainer component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <AuthInput name={RegisterInput.NAME} label={t('form.label.name')} />
                        <AuthInput name={RegisterInput.SURNAME} label={t('form.label.surname')} />
                        <AuthInput name={RegisterInput.EMAIL} label={t('form.label.email')} />
                        <AuthInput
                            name={RegisterInput.PHONENUMBER}
                            type="number"
                            label={t('form.label.phonenumber')}
                            phonePrefix={phonePrefix}
                        />
                        <AuthInput
                            name={RegisterInput.PASSWORD}
                            type="password"
                            label={t('form.label.password')}
                        />
                        <AuthInput
                            name={RegisterInput.PASSWORD_CONFIRMATION}
                            type="password"
                            label={t('form.label.passwordConfirmation')}
                        />
                        <AuthInput
                            sx={{ gridColumn: '1/-1' }}
                            name={RegisterInput.COMPANY_NAME}
                            label={t('form.label.companyName')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_NIP}
                            type="number"
                            label={t('form.label.nip')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_ADDRESS_STREET}
                            label={t('form.label.street')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_ADDRESS_CITY}
                            label={t('form.label.city')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_ADDRESS_POSTALCODE}
                            label={t('form.label.postalCode')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_ADDRESS_PROVINCE}
                            label={t('form.label.province')}
                        />
                        <AuthInput
                            name={RegisterInput.COMPANY_ADDRESS_COUNTRY}
                            label={t('form.label.country')}
                        />
                        {verifyIsRequired && (
                            <AuthInput
                                sx={{ gridColumn: '1 / -1' }}
                                name={RegisterInput.VERIFICATION_CODE}
                                label={t('form.label.verificationCode')}
                            />
                        )}
                        <S.Wrapper>
                            <Switch
                                name={RegisterInput.TERMS_AGREEMENT}
                                label={
                                    <Trans
                                        i18nKey="register:form.label.privacyPolicy"
                                        components={{
                                            privacyLink: <Link href={paths.privacyPolicy} />
                                        }}
                                    />
                                }
                            />
                            <Switch
                                name={RegisterInput.RODO_AGREEMENT}
                                label={
                                    <Trans
                                        i18nKey="register:form.label.termsOfService"
                                        components={{
                                            termsLink: <Link href={paths.termsAndConditions} />
                                        }}
                                    />
                                }
                            />
                        </S.Wrapper>
                        <S.Wrapper sx={{ width: '100%' }}>
                            <Button
                                disabled={registerIsLoading}
                                sx={{ my: 1 }}
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                {t('form.submitButton')}
                            </Button>
                            <Button disabled={registerIsLoading} fullWidth to={paths.auth.self}>
                                {t('form.cancelButton')}
                            </Button>
                        </S.Wrapper>
                    </S.MainContainer>
                </FormProvider>
            ) : (
                <Spinner />
            )}
        </FormWrapper>
    );
};
