import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'field_required'
    },
    string: {
        min: ({ min }) => ({
            key: 'enter_min_chars',
            values: { count: min }
        }),
        max: ({ max }) => ({
            key: 'enter_max_chars',
            values: { count: max }
        }),
        email: 'wrong_email'
    },
    number: {
        min: ({ min }) => ({
            key: 'enter_min_number',
            values: { count: min }
        }),
        max: ({ max }) => ({
            key: 'enter_max_number',
            values: { count: max }
        })
    },
    boolean: {
        isValue: params => {
            const { value } = params;

            if (value) {
                return 'is_checked';
            }

            return '';
        }
    },
    date: {
        min: ({ min }) => ({
            key: 'enter_min_date',
            values: { date: new Date(min).toLocaleDateString() }
        }),
        max: ({ max }) => ({
            key: 'enter_max_date',
            values: { date: new Date(max).toLocaleDateString() }
        })
    }
});
