import * as S from './pagination.styled';

type IMouseEvent = React.MouseEvent<HTMLButtonElement>;

interface ITablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: IMouseEvent, newPage: number) => void;
}

export const TablePaginationActions = (props: ITablePaginationActionsProps) => {
    const { count, rowsPerPage, page, onPageChange } = props;

    return (
        <S.Pagination
            page={page + 1}
            hidePrevButton={page === 0}
            hideNextButton={page === Math.ceil(count / rowsPerPage)}
            count={Math.ceil(count / rowsPerPage)}
            size="small"
            shape="rounded"
            defaultPage={0}
            onChange={(event: unknown, newPage) => onPageChange(event as IMouseEvent, newPage - 1)}
        />
    );
};
