import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { dateFromTimestamp } from 'utils';
import type { IAccountResponse, IAccoutListParams, IAccount } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getAccountList = async (params?: IAccoutListParams) => {
    const response = await api.get<Array<IAccountResponse>>(Endpoint.ACCOUNT_LIST, {
        params
    });

    return response.data;
};

export const useQueryGetAccountList = (
    params?: IAccoutListParams,
    options?: IQueryOptions<Array<IAccountResponse>, Array<IAccount>>
) =>
    useQuery<Array<IAccountResponse>, AxiosError, Array<IAccount>>(
        [QueryKey.ACCOUNT_LIST],
        () => getAccountList(params),
        {
            select: data =>
                data.map(
                    ({
                        uuid,
                        name,
                        surname,
                        role,
                        email,
                        isDeleted,
                        isVerified,
                        isActivated,
                        createdAt,
                        company
                    }) => ({
                        uuid,
                        createdAt: dateFromTimestamp(+createdAt),
                        nameAndSurname: `${name} ${surname}`,
                        role,
                        email,
                        companyName: company.name,
                        nip: company.nip,
                        isDeleted,
                        isVerified,
                        isActivated
                    })
                ),
            ...options
        }
    );
