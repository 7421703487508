import login from './login.json';
import register from './register.json';
import passwordForgot from './passwordForgot.json';
import passwordReset from './passwordReset.json';
import dashboard from './dashboard.json';
import map from './map.json';
import users from './users.json';
import common from './common.json';
import companies from './companies.json';
import devices from './devices.json';
import deviceTypes from './deviceTypes.json';
import companyDetails from './companyDetails.json';
import table from './table.json';
import deviceDetails from './deviceDetails.json';
import deviceTypeRules from './deviceTypeRules.json';
import validation from './validation.json';
import page500 from './page500.json';
import page404 from './page404.json';
import alerts from './alerts.json';
import settings from './settings.json';
import statistics from './statistics.json';

export default {
    login,
    register,
    passwordForgot,
    passwordReset,
    dashboard,
    map,
    users,
    common,
    companies,
    devices,
    deviceTypes,
    companyDetails,
    table,
    deviceDetails,
    deviceTypeRules,
    validation,
    page500,
    page404,
    alerts,
    settings,
    statistics
};
