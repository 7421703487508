import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ChartOptions,
    ChartData,
    Legend
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { Spinner } from 'components';
import { CommonAlert } from 'modules/dashboard';
import * as S from './line-chart.styled';

interface IProps {
    data: ChartData<'line'>;
    title?: string;
    legend?: boolean;
    isLoading?: boolean;
    isError?: boolean;
}

export const LineChart = ({ data, title, legend, isLoading, isError }: IProps) => {
    const { t } = useTranslation('common');
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options: ChartOptions<'line'> = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
            line: {
                tension: 0.2,
                borderWidth: 2,
                pointRadius: 2
            }
        },
        plugins: {
            title: {
                display: Boolean(title),
                text: `${title}`,
                color: '#313030',
                align: 'start',
                padding: {
                    top: 10,
                    bottom: 0
                },
                font: {
                    size: 16,
                    weight: '500',
                    family: 'Roboto'
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: context => `${context.dataset?.label}: ${context.parsed.y}`
                }
            },
            legend: {
                display: Boolean(legend),
                onHover: event => {
                    ((event.native as Event).target as HTMLElement).style.cursor = 'pointer';
                },
                onLeave: event => {
                    ((event.native as Event).target as HTMLElement).style.cursor = 'default';
                },
                labels: {
                    usePointStyle: true
                }
            }
        }
    };

    return (
        <S.Container>
            {isError && !isLoading && (
                <CommonAlert
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                />
            )}
            {isLoading && !isError && <Spinner text={`${t('fetching')}`} />}
            {!isLoading && !isError && data && <Line options={options} data={data} />}
        </S.Container>
    );
};
