import { styled } from '@mui/material/styles';
import { Box, TypographyProps, Switch as MUISwitch } from '@mui/material';

interface ILabelProps extends TypographyProps {
    isError?: boolean;
}

export const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    padding: theme.spacing(0.5, 0)
}));

export const Label = styled(Box, {
    shouldForwardProp: prop => prop !== 'isError'
})<ILabelProps>(({ theme, isError }) => ({
    color: isError ? theme.palette.error.main : theme.palette.text.secondary,
    alignSelf: 'flex-end',
    fontSize: theme.typography.body2.fontSize,

    a: {
        textDecoration: 'none'
    }
}));

export const Switch = styled(MUISwitch, {
    shouldForwardProp: prop => prop !== 'isError'
})(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.common.white}`
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.grey[300],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));
