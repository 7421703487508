import { styled } from '@mui/material/styles';
import { IconButton as MUIIconButton, MenuItem as MUIMenuItem } from '@mui/material';

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1)
    },
    svg: {
        width: '1.5rem',
        height: '1.5rem'
    }
}));

export const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        textTransform: 'uppercase',

        svg: {
            width: '1.5rem',
            height: '1.5rem',
            marginRight: theme.spacing(1)
        }
    }
}));
