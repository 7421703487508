import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useUserData } from 'stores';
import { Spinner } from 'components';
import { LanguageSwitcher } from 'modules/common';
import { paths } from 'utils';
import AuthLogo from 'assets/AuthLogo.svg';
import * as S from './auth-layout.styled';

export const AuthLayout = () => {
    const navigate = useNavigate();
    const { isLoggedIn, isPending } = useUserData();

    useEffect(() => {
        if (isLoggedIn && !isPending) {
            navigate(paths.dashboard.self);
        }
    }, [isLoggedIn, isPending]);

    return (
        <S.Grid container>
            {!isPending && !isLoggedIn ? (
                <>
                    <LanguageSwitcher />
                    <S.Box>
                        <img src={AuthLogo} style={{ height: '90.35px', lineHeight: 1 }} />
                    </S.Box>
                    <Outlet />
                </>
            ) : (
                <Spinner
                    sx={{
                        color: 'primary.contrastText'
                    }}
                />
            )}
        </S.Grid>
    );
};
