import { useTranslation } from 'react-i18next';
import { SectionWrapper } from 'modules/dashboard';
import { EventsGraph, GeneralGraphs } from './graphs';
import * as S from './statistics.styled';

export const DashboardStatisticsPage = () => {
    const { t } = useTranslation('statistics');

    return (
        <SectionWrapper title={`${t('title')}`}>
            <S.Container>
                <EventsGraph />
                <GeneralGraphs />
            </S.Container>
        </SectionWrapper>
    );
};
