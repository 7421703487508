import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IMapDevicesListResponse, IMarker } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getMapDevicesList = async () => {
    const response = await api.get<Array<IMapDevicesListResponse>>(Endpoint.MAP_DEVICES);

    return response.data;
};

export const useQueryMapDevicesList = (
    options?: IQueryOptions<Array<IMapDevicesListResponse>, Array<IMarker>>
) =>
    useQuery<Array<IMapDevicesListResponse>, AxiosError, Array<IMarker>>(
        [QueryKey.MAP_DEVICES],
        getMapDevicesList,
        {
            select: data =>
                data.map(({ deviceuuid, lat, long }) => ({
                    id: deviceuuid,
                    lat: Number(lat),
                    lng: Number(long)
                })),
            ...options
        }
    );
