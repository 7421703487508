import { useTranslation } from 'react-i18next';
import { Tabs as MUITabs, Paper, TabsProps } from '@mui/material';
import * as S from './tabs.styled';

interface IProps<T> extends TabsProps {
    values: Array<T>;
    selected: T;
    setValue: (value: T) => void;
}

export const Tabs = <T,>({ values, selected, setValue, ...rest }: IProps<T>) => {
    const { t } = useTranslation('table');
    const handleChange = (_event: React.SyntheticEvent, newValue: T) => {
        setValue(newValue);
    };

    return (
        <S.Container component={Paper}>
            <MUITabs
                value={selected}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                {...rest}
            >
                {values.map(value => (
                    <S.Tab key={`tab-${value}`} value={value} label={t(`tabs.${value}`)} />
                ))}
            </MUITabs>
        </S.Container>
    );
};
