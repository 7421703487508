import { styled } from '@mui/material/styles';
import { Box as MUIBox, Typography } from '@mui/material';
import { Button as CustomButton } from 'components';

export const Wrapper = styled(MUIBox)(({ theme }) => ({
    backgroundImage: `linear-gradient(to right, #bdc3c7, #2c3e50)`,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,

    '& #language-switcher-button': {
        position: 'absolute',
        top: 20,
        right: 20,

        '& svg': {
            width: '1.8rem',
            height: '1.8rem'
        }
    }
}));

export const Code = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(4, 0, 1, 0),
    fontWeight: theme.typography.fontWeightMedium
}));

Code.defaultProps = {
    variant: 'h2',
    letterSpacing: 10
};

export const Title = styled(Typography)(() => ({
    textAlign: 'center'
}));

Title.defaultProps = {
    variant: 'h5'
};

export const Description = styled(Typography)(({ theme }) => ({
    maxWidth: 400,
    marginTop: theme.spacing(2),
    textAlign: 'center'
}));

Description.defaultProps = {
    variant: 'body1'
};

export const Button = styled(CustomButton)(({ theme }) => ({
    marginTop: theme.spacing(5)
}));

Button.defaultProps = {
    variant: 'contained'
};
