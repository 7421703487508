import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IDeviceTypeRule } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceTypeRule = async (deviceTypeRuleUUID: string) => {
    const response = await api.get<IDeviceTypeRule>(Endpoint.DEVICE_TYPE_RULE, {
        params: {
            deviceTypeRuleUUID
        }
    });

    return response.data;
};

export const useQueryDeviceTypeRule = (
    deviceTypeRuleUUID: string,
    options?: IQueryOptions<IDeviceTypeRule, IDeviceTypeRule>
) =>
    useQuery<IDeviceTypeRule, AxiosError, IDeviceTypeRule>(
        [QueryKey.DEVICE_TYPE_RULE],
        () => getDeviceTypeRule(deviceTypeRuleUUID),
        {
            select: data => ({
                ...data,
                hex: data.hex.toUpperCase()
            }),
            ...options
        }
    );
