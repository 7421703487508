import { include } from 'named-urls';

export enum ROUTE {
    HOME = '/',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    PRIVACY_POLICY = '/privacy-policy',
    AUTH = '/auth',
    AUTH_PASSWORD_FORGOT = 'password-forgot',
    AUTH_PASSWORD_RESET = 'password-reset',
    AUTH_REGISTER = 'register',
    AUTH_REGISTER_TYPE = 'register/:type',
    AUTH_ACTIVATE = 'activate',
    DASHBOARD = '/dashboard',
    DASHBOARD_MAP = 'map',
    DASHBOARD_USERS = 'users',
    DASHBOARD_COMPANIES = 'companies',
    DASHBOARD_COMPANY = 'companies/:id',
    DASHBOARD_DEVICES = 'devices',
    DASHBOARD_DEVICE = 'devices/:id',
    DASHBOARD_DEVICES_TYPES = 'devices-types',
    DASHBOARD_DEVICES_TYPE_RULES = 'devices-types/:id/rules',
    DASHBOARD_STATISTICS = 'statistics',
    DASHBOARD_SETTINGS = 'settings',
    SERVER_ERROR = '/500',
    NOT_FOUND = '/404'
}

export const paths = {
    home: ROUTE.HOME,
    termsAndConditions: ROUTE.TERMS_AND_CONDITIONS,
    privacyPolicy: ROUTE.PRIVACY_POLICY,
    auth: include(`${ROUTE.AUTH}`, {
        self: '',
        forgotPassword: ROUTE.AUTH_PASSWORD_FORGOT,
        resetPassword: ROUTE.AUTH_PASSWORD_RESET,
        register: ROUTE.AUTH_REGISTER,
        activate: ROUTE.AUTH_ACTIVATE
    }),
    dashboard: include(`${ROUTE.DASHBOARD}`, {
        self: '',
        map: ROUTE.DASHBOARD_MAP,
        users: ROUTE.DASHBOARD_USERS,
        companies: ROUTE.DASHBOARD_COMPANIES,
        devices: ROUTE.DASHBOARD_DEVICES,
        devicesTypes: ROUTE.DASHBOARD_DEVICES_TYPES,
        statistics: ROUTE.DASHBOARD_STATISTICS,
        settings: ROUTE.DASHBOARD_SETTINGS
    }),
    notFound: `${ROUTE.NOT_FOUND}`,
    serverError: `${ROUTE.SERVER_ERROR}`
};
