import { useTranslation } from 'react-i18next';
import { ErrorPageWrapper } from 'modules/common';
import { paths } from 'utils';

export const ServerErrorPage = () => {
    const { t } = useTranslation('page500');

    return (
        <ErrorPageWrapper
            code="500"
            title={t('title')}
            description={t('description') as string}
            buttonText={t('button')}
            redirectPath={paths.home}
        />
    );
};
