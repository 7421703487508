import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IGetStatsParams, IStatsDevicesData } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

type IStatsDevices = Array<IStatsDevicesData>;

export const getStatsDevices = async (params: IGetStatsParams) => {
    const response = await api.get<IStatsDevices>(Endpoint.STATS_INSTALLED_DEVICES, {
        params
    });

    return response.data;
};

export const useQueryGetStatsDevices = (
    params: IGetStatsParams,
    options?: IQueryOptions<IStatsDevices, IStatsDevices>
) =>
    useQuery<IStatsDevices, AxiosError, IStatsDevices>(
        [QueryKey.STATS_INSTALLED_DEVICES],
        () => getStatsDevices(params),
        {
            ...options
        }
    );
