import * as yup from 'yup';
import { regexSchema, phoneSchema, requiredStringSchema, emailSchema } from 'utils';
import { RoleType, type ICompanyListItem } from 'types';
import { TFunction } from 'i18next';

export enum UserCreateInput {
    COMPANY_ID = 'companyUUID',
    NAME = 'name',
    SURNAME = 'surname',
    EMAIL = 'email',
    ROLE = 'role',
    PHONE = 'phonenumber'
}

export interface ICreateUserForm {
    [UserCreateInput.COMPANY_ID]: string;
    [UserCreateInput.NAME]: string;
    [UserCreateInput.SURNAME]: string;
    [UserCreateInput.EMAIL]: string;
    [UserCreateInput.ROLE]: RoleType;
    [UserCreateInput.PHONE]: string;
}

export const defaultValues = {
    [UserCreateInput.COMPANY_ID]: '',
    [UserCreateInput.NAME]: '',
    [UserCreateInput.SURNAME]: '',
    [UserCreateInput.EMAIL]: '',
    [UserCreateInput.ROLE]: RoleType.Client,
    [UserCreateInput.PHONE]: ''
};

export const formSchema = yup.object({
    [UserCreateInput.COMPANY_ID]: requiredStringSchema,
    [UserCreateInput.NAME]: regexSchema('name'),
    [UserCreateInput.SURNAME]: regexSchema('surname'),
    [UserCreateInput.EMAIL]: emailSchema,
    [UserCreateInput.ROLE]: requiredStringSchema,
    [UserCreateInput.PHONE]: phoneSchema
});

export const selectRoleOptions = (t: TFunction) =>
    [RoleType.Client, RoleType.Installer].map(role => ({
        label: `${t(`common:role.${role}`)}`,
        value: role
    }));

export const companiesSelectOptions = (companies: Array<ICompanyListItem>) =>
    companies.map(company => ({
        label: company.companyName,
        value: String(company.uuid)
    }));
