import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { Button as CustomButton } from 'components';

export const Container = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto'
}));

export const Button = styled(CustomButton)(({ theme }) => ({
    marginTop: theme.spacing(5)
}));

Button.defaultProps = {
    variant: 'contained'
};
