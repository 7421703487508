import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleType } from 'types';
import { useUserData } from 'stores';

export const useUserType = () => {
    const { t } = useTranslation('common', { keyPrefix: 'userType' });
    const { userData } = useUserData();

    const { role } = userData ?? {};

    const userType = useMemo(() => {
        switch (role) {
            case RoleType.Admin:
                return t(RoleType.Admin);
            case RoleType.Installer:
                return t(RoleType.Installer);
            case RoleType.Client:
                return t(RoleType.Client);
            case RoleType.Device:
                return t(RoleType.Device);
            default:
                return null;
        }
    }, [role]);

    return userType;
};
