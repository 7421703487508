import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useUserData } from 'stores';
import { Cookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import type { IUserLoginResponse, IPostLogin, IUserData } from 'types';
import { api, Endpoint, Cookie, IMutationOptions } from '../..';

const cookies = new Cookies();

export const useMutationPostLogin = (
    options?: IMutationOptions<IPostLogin, IUserLoginResponse>
) => {
    const { setUserData } = useUserData(state => state);

    return useMutation<IUserLoginResponse, AxiosError, IPostLogin>(
        data => api.post<IUserLoginResponse>(Endpoint.AUTH_SESSION, data).then(({ data }) => data),
        {
            onSuccess: ({ accessToken, refreshToken }) => {
                cookies.set(Cookie.ACCESS_TOKEN, accessToken, { path: '/' });
                cookies.set(Cookie.REFRESH_TOKEN, refreshToken, { path: '/' });

                setUserData(jwt_decode(accessToken) as IUserData);
            },
            ...options
        }
    );
};
