export const regex = {
    NAME: /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]+$/,
    PASSWORD:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%|£§`'"/:=^&*()_+{}[\]:;<>,.?~\\-]{8,30}$/,
    POSITIVE_NUMBER: /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
    NOT_EMPTY: /\S/,
    ZIP_CODE: /^\d{2}-\d{3}$/,
    HEX: /^#?[a-f\d]{2}$/i,
    HEX_COLOR: /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/
};
