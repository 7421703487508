import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
    FormWrapper,
    FromButtonsWrapper,
    formFullWidtStyle,
    useDeviceTypeSelect
} from 'modules/dashboard';
import { Modal, Input, Select, Button, DatePicker } from 'components';
import { ErrorMessage } from 'modules/common';
import { phonePrefix, dateToTimestamp } from 'utils';
import { useEditDeviceDetails } from '../hooks';
import {
    IDeviceDetailsEditForm,
    dynamicFormSchema,
    deviceTypesSelectOptions,
    dynamicDefaultValues,
    DeviceDetailsEditType
} from './device-details-edit-general-from.config';

interface IProps {
    data: Record<string, string>;
    onClose: () => void;
}

export const DashboardDeviceDetailsEditGeneralForm = ({ data, onClose }: IProps) => {
    const { id } = useParams();
    const { t } = useTranslation(['deviceDetails', 'table']);

    const title = Object.keys(data)[0];

    const isDeviceTypeEdit = title === DeviceDetailsEditType.DEVICE_TYPE_NAME;
    const isPhoneNumberEdit = title === DeviceDetailsEditType.DEVICE_PHONE_NUMBER;
    const isOwnerNipEdit = title === DeviceDetailsEditType.DEVICE_OWNER_NIP;
    const isSubscriptionExpiresAtEdit =
        title === DeviceDetailsEditType.DEVICE_SUBSCRIPTION_EXPIRES_AT;

    const {
        patchDevice,
        patchDeviceError,
        postDeviceMaintenance,
        postDeviceMaintenanceError,
        postDeviceOwnership,
        postDeviceOwnershipError
    } = useEditDeviceDetails(onClose, title);

    const error = patchDeviceError || postDeviceMaintenanceError || postDeviceOwnershipError;

    const { deviceTypesSelect, getDeviceValueByLabel } = useDeviceTypeSelect(!isDeviceTypeEdit);

    const methods = useForm<IDeviceDetailsEditForm>({
        defaultValues: dynamicDefaultValues(data, title),
        resolver: yupResolver(dynamicFormSchema(title))
    });

    const onSubmit = (formData: IDeviceDetailsEditForm) => {
        const deviceUUID = String(id);

        if (isDeviceTypeEdit) {
            postDeviceMaintenance({
                deviceUUID,
                deviceTypeUUID: getDeviceValueByLabel(formData.deviceTypeName)
            });

            return;
        }

        if (isOwnerNipEdit) {
            postDeviceOwnership({
                deviceUUID,
                companyNip: Number(formData.deviceOwnerNip)
            });

            return;
        }

        if (isPhoneNumberEdit) {
            patchDevice({
                deviceUUID,
                subscriptionPhonenumber: formData.subscriptionPhonenumber
                    ? `${phonePrefix}${formData.subscriptionPhonenumber}`
                    : null
            });

            return;
        }

        if (isSubscriptionExpiresAtEdit) {
            patchDevice({
                deviceUUID,
                toUnixTime: dateToTimestamp(formData.subscriptionExpiresAt)
            });

            return;
        }

        patchDevice({
            deviceUUID,
            [title]: formData[title]
        });
    };

    const renderInputs = (title: string) => {
        switch (title) {
            case 'deviceTypeName':
                return (
                    <Select
                        name="deviceTypeName"
                        label={t(`table:headLabel.${title}`)}
                        sx={formFullWidtStyle}
                        options={deviceTypesSelectOptions(deviceTypesSelect)}
                        variant="filled"
                    />
                );
            case 'subscriptionPhonenumber':
                return (
                    <Input
                        name="subscriptionPhonenumber"
                        label={t(`table:headLabel.${title}`)}
                        sx={formFullWidtStyle}
                        phonePrefix={phonePrefix}
                    />
                );
            case 'subscriptionExpiresAt':
                return (
                    <DatePicker
                        name="subscriptionExpiresAt"
                        sx={formFullWidtStyle}
                        label={t('table:headLabel.subscriptionExpiresAt')}
                        disablePast
                    />
                );
            default:
                return (
                    <Input
                        name={title}
                        label={t(`table:headLabel.${title}`)}
                        sx={formFullWidtStyle}
                    />
                );
        }
    };

    return (
        <Modal open={Boolean(data)} onClose={onClose} title={`${t(`editModal.${title}`)}`}>
            <FormProvider {...methods}>
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)} component="form" padding={1}>
                    <ErrorMessage
                        error={error}
                        sx={{
                            ...formFullWidtStyle,
                            marginBottom: 1
                        }}
                    />
                    {renderInputs(title)}
                    <FromButtonsWrapper>
                        <Button type="submit" variant="contained" color="primary">
                            {t('form.submitButton')}
                        </Button>
                    </FromButtonsWrapper>
                </FormWrapper>
            </FormProvider>
        </Modal>
    );
};
