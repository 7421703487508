import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryGetCompany, useQueryGetCompanyUsers, useQueryGetCompanyDevices } from 'api';
import { CompanyTab } from 'types';

export const useGetCompanyData = (companyUUID: string, selected: CompanyTab) => {
    const { t } = useTranslation();
    const [tabsErrorMessages, setTabsErrorMessages] = useState<Record<CompanyTab, string>>({
        [CompanyTab.DATA]: '',
        [CompanyTab.USERS]: '',
        [CompanyTab.DEVICES]: ''
    });

    const setFetchError = (tab: CompanyTab) => {
        setTabsErrorMessages(prev => ({
            ...prev,
            [tab]: t('alerts:fetchDataError')
        }));
    };

    const clearFetchError = (tab: CompanyTab) => {
        const isError = tabsErrorMessages[tab] !== '';

        if (isError) {
            setTabsErrorMessages(prev => ({
                ...prev,
                [tab]: ''
            }));
        }
    };

    const { data: companyData, isFetching: isCompanyLoading } = useQueryGetCompany(companyUUID, {
        cacheTime: 0,
        enabled: selected === CompanyTab.DATA,
        onError: () => {
            setFetchError(CompanyTab.DATA);
        },
        onSuccess: () => {
            clearFetchError(CompanyTab.DATA);
        }
    });

    const { data: companyUsersData, isFetching: isCompanyUsersDataLoading } =
        useQueryGetCompanyUsers(companyUUID, {
            enabled: selected === CompanyTab.USERS,
            cacheTime: 0,
            onError: () => {
                setFetchError(CompanyTab.USERS);
            },
            onSuccess: () => {
                clearFetchError(CompanyTab.USERS);
            }
        });

    const { data: companyDevicesData, isFetching: isCompanyDevicesDataLoading } =
        useQueryGetCompanyDevices(companyUUID, {
            enabled: selected === CompanyTab.DEVICES,
            cacheTime: 0,
            onError: () => {
                setFetchError(CompanyTab.DEVICES);
            },
            onSuccess: () => {
                clearFetchError(CompanyTab.DEVICES);
            }
        });

    useEffect(
        () => () => {
            setTabsErrorMessages({
                [CompanyTab.DATA]: '',
                [CompanyTab.USERS]: '',
                [CompanyTab.DEVICES]: ''
            });
        },
        []
    );

    return {
        companyData,
        isCompanyLoading,
        companyUsersData,
        isCompanyUsersDataLoading,
        companyDevicesData,
        isCompanyDevicesDataLoading,
        tabsErrorMessages
    };
};
