export interface IDeviceTypeResponse {
    name: string;
    deviceTypeUUID: string;
    parameterKV: number;
    parameterBatteryLifetime: number;
}

export interface IDeviceType {
    uuid: string;
    deviceTypeName: string;
    parameterKV: number;
    parameterBatteryLifetime: number;
}

export enum AddDeviceType {
    ADD = 'put',
    EDIT = 'patch'
}

export type IPostDeviceType = Pick<IDeviceType, 'parameterKV'> & {
    name: string;
    deviceTypeUUID?: string;
    parameterBatteryLifetime?: number;
};

export type IDeleteDeviceType = Pick<IPostDeviceType, 'deviceTypeUUID'>;
