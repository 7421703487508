import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IDeviceTypeResponse, IDeviceType } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceTypeList = async () => {
    const response = await api.get<Array<IDeviceTypeResponse>>(Endpoint.DEVICE_TYPE_LIST);

    return response.data;
};

export const useQueryGetDeviceTypeList = (
    options?: IQueryOptions<Array<IDeviceTypeResponse>, Array<IDeviceType>>
) =>
    useQuery<Array<IDeviceTypeResponse>, AxiosError, Array<IDeviceType>>(
        [QueryKey.DEVICE_TYPE_LIST],
        getDeviceTypeList,
        {
            select: data =>
                data.map(({ deviceTypeUUID, name, parameterKV, parameterBatteryLifetime }) => ({
                    uuid: deviceTypeUUID,
                    deviceTypeName: name,
                    parameterKV,
                    parameterBatteryLifetime
                })),
            ...options
        }
    );
