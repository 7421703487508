import { useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, debounce } from '@mui/material';
import { Modal, Button, Map } from 'components';
import { ErrorMessage } from 'modules/common';
import { CancelButton } from 'modules/dashboard';
import { ILocation } from 'types';
import { ILocationForm, defaultValues, LocationFormInput } from './device-form-location.config';
import * as S from './device-form-location.styled';

interface IProps {
    data: ILocation;
    title: string;
    submitHandler: (location: ILocation) => void;
    onClose: () => void;
    error?: AxiosError<unknown, any> | null;
}

export const DashboardDeviceFormLocation = ({
    data,
    title,
    submitHandler,
    onClose,
    error
}: IProps) => {
    const { t } = useTranslation('deviceDetails');
    const [selected, setSelected] = useState<ILocation>({
        lat: data.lat,
        lng: data.lng
    });

    const methods = useForm<ILocationForm>({
        defaultValues
    });

    const formData = methods.watch();

    const getLocation = async (data: ILocationForm) => {
        const address = data.searchQuery;
        const result = await getGeocode({ address });
        const { lat, lng } = getLatLng(result[0]);
        setSelected({ lat, lng });
    };

    const onChangeFunc = useCallback(
        debounce(() => {
            methods.handleSubmit((data: ILocationForm) => {
                const lat = Number(data.lat);
                const lng = Number(data.lng);
                setSelected({ lat, lng });

                if (data.searchQuery) {
                    methods.resetField(LocationFormInput.SEARCH_QUERY);
                }
            })();
        }, 1000),

        []
    );

    const handleSetInitLocation = () => {
        const { lat, lng } = data;
        setSelected({ lat, lng });
    };

    const handlePostDeviceLocation = () => {
        if (selected) {
            submitHandler(selected);
        }
    };

    useEffect(() => {
        handleSetInitLocation();
    }, [data]);

    useEffect(() => {
        if (selected) {
            const { lat, lng } = selected;
            methods.setValue('lng', lng);
            methods.setValue('lat', lat);
        }
    }, [selected]);

    return (
        <Modal open={Boolean(data)} onClose={onClose} title={title}>
            <FormProvider {...methods}>
                <Box component="form" onSubmit={methods.handleSubmit(getLocation)}>
                    {error && (
                        <Box>
                            <ErrorMessage error={error} />
                        </Box>
                    )}
                    <S.Input name="searchQuery" label={t('form.label.searchQuery')} />

                    <Map
                        location={selected}
                        handleSelect={setSelected}
                        sx={{
                            height: '400px'
                        }}
                    />
                </Box>
                <S.Wrapper>
                    <S.Input
                        name="lng"
                        label={t('form.label.lng')}
                        value={formData.lng}
                        customOnChange={(e, onChange) => {
                            onChange(e);
                            onChangeFunc();
                        }}
                    />
                    <S.Input
                        name="lat"
                        label={t('form.label.lat')}
                        value={formData.lat}
                        customOnChange={(e, onChange) => {
                            onChange(e);
                            onChangeFunc();
                        }}
                    />
                </S.Wrapper>
                <S.Wrapper>
                    <Button type="button" variant="contained" onClick={handlePostDeviceLocation}>
                        {t('form.submitButton')}
                    </Button>
                    <CancelButton type="reset" variant="contained" onClick={handleSetInitLocation}>
                        {t('form.resetButton')}
                    </CancelButton>
                </S.Wrapper>
            </FormProvider>
        </Modal>
    );
};
