import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TableBody,
    TableHead,
    TableSortLabel,
    Paper,
    Tooltip,
    Box,
    Stack,
    TableContainer,
    CircularProgress,
    type ButtonProps,
    Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
    formatDate,
    isHexColor,
    isStringColor,
    convertToHexColor,
    shouldDisplayEmptyValue
} from 'utils';
import * as S from './table.styled';
import * as helpers from './table.helpers';
import { Pagination, ButtonIcon } from '..';

import { useFilter } from 'hooks/useFilter';

export interface ITableHandlers<T> extends ButtonProps {
    name: string;
    handler: (value: T) => void;
    icon?: ButtonIcon;
    hideKeys?: Array<keyof T>;
    isPernamentDelete?: boolean;
}

interface IProps<T> {
    data: Array<T>;
    tableHandlers?: Array<ITableHandlers<T>>;
    numbering?: boolean;
    hide?: Array<keyof T>;
    disableCellFormat?: Array<keyof T>;
    defaultSortBy?: keyof T;
    searchParams?: Record<string, string>;
    errorMessage?: string;
    isLoading?: boolean;
}

export const Table = <T extends object & { isDeleted?: boolean; uuid?: string }>({
    data,
    tableHandlers,
    hide,
    disableCellFormat,
    defaultSortBy,
    numbering = true,
    searchParams,
    errorMessage,
    isLoading
}: IProps<T>) => {
    const { t } = useTranslation('table');
    const {
        sort,
        paginate,
        getKeys,
        Direction: { ASC, DESC }
    } = helpers;

    const [order, setOrder] = useState(DESC);
    const [orderBy, setOrderBy] = useState<keyof T | undefined>(defaultSortBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const tableHeadData = useMemo(() => (data.length > 0 ? getKeys(data[0]) : []), [data]);

    const filteredData = useFilter(data ?? [], searchParams ?? {}, setPage);

    const sortedTableData = useMemo(
        () => (filteredData && orderBy ? sort(filteredData, orderBy, order) : filteredData),
        [filteredData, orderBy, order]
    );

    const isRows = useMemo(
        () => sortedTableData && sortedTableData.length > 0 && !errorMessage && !isLoading,
        [sortedTableData, isLoading, errorMessage]
    );

    const hideCellsIndexes = useMemo(
        () =>
            hide ? hide.map(cell => tableHeadData.indexOf(cell) + (numbering ? 2 : 1)) : undefined,
        [hide, tableHeadData, numbering]
    );

    const disableCellFormatIndexes = useMemo(
        () =>
            disableCellFormat
                ? disableCellFormat.map(cell => tableHeadData.indexOf(cell))
                : undefined,
        [disableCellFormat, tableHeadData]
    );

    const formatCell = useCallback(
        (cell: string | number | boolean | Date): string | JSX.Element => {
            switch (true) {
                case typeof cell === 'boolean':
                    return (
                        <Tooltip title={cell ? `${t('yesTooltip')}` : `${t('noTooltip')}`}>
                            {cell ? <CheckIcon /> : <CloseIcon />}
                        </Tooltip>
                    );
                case cell instanceof Date:
                    return formatDate(cell.toString());
                case typeof cell === 'string' && isHexColor(cell):
                    return <S.ColorBox color={convertToHexColor(cell as string)} />;
                case typeof cell === 'string' && isStringColor(cell):
                    return <S.ColorBox color={`${cell}`} />;
                case (!cell && cell !== 0) || shouldDisplayEmptyValue(cell.toString()):
                    return '-';
                default:
                    return String(cell);
            }
        },
        [t]
    );

    const handleSort = useCallback(
        (cell: keyof T) => {
            setPage(0);
            setOrderBy(cell);

            if (orderBy === cell) {
                setOrder(prev => (prev === ASC ? DESC : ASC));
            }
        },
        [orderBy]
    );

    return (
        <Box>
            {isRows && (
                <TableContainer
                    component={Paper}
                    sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                >
                    <S.MUITable stickyHeader>
                        <TableHead>
                            <S.HeadRow>
                                {numbering && <S.HeadCell>{}</S.HeadCell>}
                                {tableHeadData.map(cell => (
                                    <S.HeadCell
                                        key={`table-cell-${String(cell)}`}
                                        hide={hideCellsIndexes}
                                    >
                                        <Tooltip title={t('sortLabel')}>
                                            <TableSortLabel
                                                active={orderBy === cell}
                                                direction={order === ASC ? ASC : DESC}
                                                onClick={() => handleSort(cell)}
                                            >
                                                {t(`headLabel.${String(cell)}`)}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </S.HeadCell>
                                ))}
                            </S.HeadRow>
                        </TableHead>
                        <TableBody>
                            {paginate(sortedTableData, page, rowsPerPage).map((row, index) => (
                                <S.BodyRow key={`table-row-${index}`} disabled={row?.isDeleted}>
                                    {numbering && (
                                        <S.BodyCell
                                            sx={{
                                                fontWeight: 'bold',
                                                padding: '0 14px'
                                            }}
                                        >
                                            {helpers.numberOfItem(index, page, rowsPerPage)}
                                        </S.BodyCell>
                                    )}
                                    {Object.values(row).map(
                                        (cell: string | number | boolean | Date, index: number) => (
                                            <S.BodyCell
                                                hide={hideCellsIndexes}
                                                component="td"
                                                key={`table-cell-${index}`}
                                                align={
                                                    typeof cell === 'boolean' ? 'center' : 'left'
                                                }
                                            >
                                                {disableCellFormatIndexes?.includes(index) ? (
                                                    <>{cell}</>
                                                ) : (
                                                    formatCell(cell)
                                                )}
                                            </S.BodyCell>
                                        )
                                    )}
                                    {tableHandlers && (
                                        <S.BodyCell>
                                            <S.Wrapper>
                                                {tableHandlers.map(
                                                    (
                                                        {
                                                            name,
                                                            handler,
                                                            icon,
                                                            hideKeys,
                                                            isPernamentDelete,
                                                            ...rest
                                                        },
                                                        index
                                                    ) => {
                                                        const shouldHide = hideKeys?.some(key =>
                                                            Boolean(row[key])
                                                        );

                                                        const pernamentDelete =
                                                            isPernamentDelete && row.isDeleted;

                                                        return (
                                                            <S.HandlerButton
                                                                hide={shouldHide}
                                                                key={`table-handler-${index}`}
                                                                onClick={() => handler(row)}
                                                                icon={!pernamentDelete && icon}
                                                                {...rest}
                                                            >
                                                                {pernamentDelete
                                                                    ? t('permanentlyDelete')
                                                                    : name}
                                                            </S.HandlerButton>
                                                        );
                                                    }
                                                )}
                                            </S.Wrapper>
                                        </S.BodyCell>
                                    )}
                                </S.BodyRow>
                            ))}
                        </TableBody>
                    </S.MUITable>
                    <Pagination
                        length={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            )}
            {!isRows && !errorMessage && !isLoading && (
                <S.StyledAlert severity="warning">{t('emptyLabel')}</S.StyledAlert>
            )}
            {errorMessage && !isLoading && (
                <S.StyledAlert severity="error">{errorMessage}</S.StyledAlert>
            )}

            {isLoading && (
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    marginY="48px"
                    rowGap="24px"
                >
                    <CircularProgress />
                    <Typography>{t('fetchingData')}</Typography>
                </Stack>
            )}
        </Box>
    );
};
