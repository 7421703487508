import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { Button } from 'components';

interface IFormWrapperProps extends BoxProps {
    noValidate?: boolean;
}

export const FormWrapper = styled(Box)<IFormWrapperProps>(({ theme }) => ({
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1.5),
    padding: theme.spacing(2, 3, 3, 3),
    justifyContent: 'center'
}));

FormWrapper.defaultProps = {
    noValidate: true
};

export const FromButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: theme.spacing(2),
    marginTop: theme.spacing(3)
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    background: theme.palette.grey[400],
    color: theme.palette.text.primary,

    '&:hover': {
        background: theme.palette.grey[500]
    }
}));

export const formFullWidtStyle = {
    gridColumn: '1 / -1'
};
