import { BoxProps, Box } from '@mui/material';
import { LanguageSwitcher } from 'modules/common';
import AuthLogo from 'assets/AuthLogo.svg';
import * as S from './page-wrapper.styled';

interface IProps extends BoxProps {
    children?: React.ReactNode;
    code: string;
    title: string;
    buttonText: string;
    redirectPath: string;
    description?: string;
}

export const ErrorPageWrapper = ({
    code,
    title,
    buttonText,
    redirectPath,
    description,
    children,
    ...rest
}: IProps) => (
    <S.Wrapper {...rest}>
        <LanguageSwitcher />
        <Box>
            <img src={AuthLogo} style={{ height: '90.35px', lineHeight: 1 }} />
        </Box>
        <S.Code>{code}</S.Code>
        <S.Title>{title}</S.Title>
        {description && <S.Description>{description}</S.Description>}
        <S.Button href={redirectPath}>{buttonText}</S.Button>
        {children}
    </S.Wrapper>
);
