import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography, Stack } from '@mui/material';
import { useMediaQuery, Theme } from '@material-ui/core';
import { LanguageSwitcher } from 'modules/common';
import { useUserData } from 'stores';
import { paths } from 'utils';
import { useUserType } from 'hooks';
import { ReactComponent as NavLogo } from 'assets/logoNav.svg';
import * as S from './navbar.styled';

interface IProps {
    toggleDrawer: boolean;
    setToggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Navbar = ({ toggleDrawer, setToggleDrawer }: IProps) => {
    const { t } = useTranslation('dashboard');
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const { logOut, userData } = useUserData();
    const userType = useUserType();

    return (
        <S.Box>
            <S.AppBar>
                <S.Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setToggleDrawer(prev => !prev)}
                    >
                        <S.StyledMenuIcon isOpen={toggleDrawer} />
                    </IconButton>
                    <S.LogoWrapper>
                        <NavLogo />
                    </S.LogoWrapper>

                    <LanguageSwitcher reloadRequiredPath={paths.dashboard.map} />

                    {!isMobileView && (
                        <S.UserBox direction="row" alignItems="center">
                            <Stack>
                                <Typography variant="body2">{userData?.email}</Typography>
                                <Typography variant="caption">{userType}</Typography>
                            </Stack>
                            <Tooltip title={t('nav.logout')}>
                                <IconButton onClick={logOut}>
                                    <S.StyledLogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </S.UserBox>
                    )}
                </S.Toolbar>
            </S.AppBar>
        </S.Box>
    );
};
