import './utils/yup/setLocale';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppRouter, theme } from 'modules/app';
import { queryClient } from 'api';
import { CustomRouter, customHistory } from 'utils';
import './i18/locale';
import { NODE_ENV } from 'lib';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const isDevelopment = NODE_ENV === 'development';

root.render(
    <QueryClientProvider client={queryClient}>
        {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        <CustomRouter history={customHistory}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={5} autoHideDuration={3000}>
                    <AppRouter />
                </SnackbarProvider>
            </ThemeProvider>
        </CustomRouter>
    </QueryClientProvider>
);
