import { styled } from '@mui/material/styles';

import {
    Table,
    TableRow,
    TableRowProps,
    TableCell,
    TableCellProps,
    Box,
    Alert
} from '@mui/material';
import { Button as CustomButton } from '..';

interface ICellProps extends TableCellProps {
    hide?: Array<number>;
}

interface ITableRowProps extends TableRowProps {
    disabled?: boolean;
}

interface IHandlerButtonProps {
    hide?: boolean;
}

const hideCells = (indexes: Array<number>) => {
    const cells = indexes.reduce(
        (acc, index) => ({
            ...acc,
            [`&:nth-of-type(${index})`]: {
                display: 'none'
            }
        }),
        {}
    );

    return cells;
};

export const MUITable = styled(Table)(({ theme }) => ({
    minWidth: theme.breakpoints.values.sm,
    tableLayout: 'auto'
}));

export const HeadRow = styled(TableRow)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

export const HeadCell = styled(TableCell, {
    shouldForwardProp: prop => prop !== 'hide'
})<ICellProps>(({ theme, hide }) => ({
    border: 'none',
    padding: `${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
    color: theme.palette.grey[500],

    ...(hide && hideCells(hide))
}));

export const BodyRow = styled(TableRow, {
    shouldForwardProp: prop => prop !== 'disabled'
})<ITableRowProps>(({ theme, disabled }) => ({
    background: disabled ? theme.palette.grey[300] : 'none',
    '& .MuiTableCell-root': {
        color: disabled ? theme.palette.grey[600] : theme.palette.grey[900],
        borderBottom: disabled && `1px solid ${theme.palette.grey[400]}`
    },
    '&:hover': {
        background: disabled ? theme.palette.grey[300] : theme.palette.grey[100]
    }
}));

export const BodyCell = styled(TableCell, {
    shouldForwardProp: prop => prop !== 'hide'
})<ICellProps>(({ theme, hide }) => ({
    padding: `${theme.spacing(0.5)}`,
    fontSize: theme.typography.pxToRem(14),

    ...(hide && hideCells(hide))
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: theme.spacing(1.5)
}));

export const HandlerButton = styled(CustomButton, {
    shouldForwardProp: prop => prop !== 'hide'
})<IHandlerButtonProps>(({ theme, hide }) => ({
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(13),
    display: hide ? 'none' : 'flex',
    cursor: 'pointer'
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBlock: theme.spacing(6),
    marginInline: 'auto',
    maxWidth: theme.breakpoints.values.sm
}));

interface IColorBoxProps {
    color: string;
}

export const ColorBox = styled(Box, {
    shouldForwardProp: prop => prop !== 'color'
})<IColorBoxProps>(({ theme, color }) => ({
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: '50%',
    background: color,
    boxShadow: `0 0 1px ${color}, 0 0 2px ${color}, 0 0 4px ${color}`,
    border: `1px solid ${color === 'white' ? theme.palette.grey[200] : 'transparent'}`
}));
