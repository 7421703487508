import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPatchDeviceDetails } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPatchDevice = (options?: IMutationOptions<IPatchDeviceDetails>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPatchDeviceDetails>(
        async data => api.patch(Endpoint.DEVICE, data),
        options
    );
