import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useQueryGetDevice,
    useQueryGetDeviceHistoryEvents,
    useQueryGetDeviceHistoryMaintenance,
    useQueryGetDeviceHistoryLocation,
    useQueryGetDeviceHistorySubscription
} from 'api';
import { dateFromTimestamp, minutesDurationToString } from 'utils';
import { DeviceDetailsTab } from 'types';

export const useGetDeviceDetailsData = (deviceId: string, selected: DeviceDetailsTab) => {
    const { t } = useTranslation();
    const [tabsErrorMessages, setTabsErrorMessages] = useState<Record<DeviceDetailsTab, string>>({
        [DeviceDetailsTab.EVENTS]: '',
        [DeviceDetailsTab.MAINTENANCE]: '',
        [DeviceDetailsTab.LOCATION]: '',
        [DeviceDetailsTab.SUBSCRIPTION]: ''
    });

    const setFetchError = (tab: DeviceDetailsTab) => {
        setTabsErrorMessages(prev => ({
            ...prev,
            [tab]: t('alerts:fetchDataError')
        }));
    };

    const clearFetchError = (tab: DeviceDetailsTab) => {
        const isError = tabsErrorMessages[tab] !== '';

        if (isError) {
            setTabsErrorMessages(prev => ({
                ...prev,
                [tab]: ''
            }));
        }
    };

    const {
        data: deviceData,
        isFetching: isLoadingDeviceData,
        isError: isDeviceDataError
    } = useQueryGetDevice(deviceId, {
        cacheTime: 0,
        select: ({
            deviceUUID,
            deviceId,
            devicePSC,
            deviceName,
            deviceTypeName,
            deviceOwnerName,
            deviceOwnerNip,
            deviceInstalledUnixTime,
            deviceInstallerName,
            deviceInstallerNip,
            subscriptionTransitMethod,
            subscriptionPhonenumber,
            subscriptionExpiresAt,
            lastEventText,
            lastEventIcon,
            lastEventColor,
            lastEventAt,
            batteryLevel,
            batteryLevelAt,
            batteryLifetime,
            batteryLifetimeCounter,
            waterDrainedInMetersSq,
            waterDrainedInMinutes,
            location
        }) => ({
            deviceUUID,
            deviceId,
            devicePSC,
            deviceName,
            deviceTypeName,
            waterDrainedInMetersSq: `${Number(waterDrainedInMetersSq).toFixed(2)} m³`,
            waterDrainedInMinutes: waterDrainedInMinutes && minutesDurationToString(+waterDrainedInMinutes, t),
            deviceOwnerName,
            deviceOwnerNip,
            deviceInstalledUnixTime,
            deviceInstallerName,
            deviceInstallerNip,
            subscriptionTransitMethod,
            subscriptionPhonenumber,
            subscriptionExpiresAt: `${dateFromTimestamp(subscriptionExpiresAt)} (${subscriptionTransitMethod})`,
            lastEventText,
            lastEventIcon,
            lastEventColor,
            lastEventAt,
            batteryLevel,
            batteryLevelAt,
            batteryLifetime,
            lat: Number(location.lat),
            lng: Number(location.long),
            batteryLifetimeCounter
        })
    });

    const { data: deviceHistoryEventsData, isFetching: isDeviceHistoryEventsDataLoading } =
        useQueryGetDeviceHistoryEvents(deviceId, {
            enabled: selected === DeviceDetailsTab.EVENTS,
            cacheTime: 0,
            select: data =>
                data.map(item => ({
                    ...item,
                    createdAt: dateFromTimestamp(item.createdAt),
                    type: `${t(`eventType.${item.type}`)}`,
                    text: item.text,
                    icon: `${t(`icon.${item.icon}`)}`,
                    hex: item.hex.toUpperCase()
                })),
            onError: () => {
                setFetchError(DeviceDetailsTab.EVENTS);
            },
            onSuccess: () => {
                clearFetchError(DeviceDetailsTab.EVENTS);
            }
        });

    const {
        data: deviceHistoryMaintenanceData,
        isFetching: isDeviceHistoryMaintenanceDataLoading
    } = useQueryGetDeviceHistoryMaintenance(deviceId, {
        enabled: selected === DeviceDetailsTab.MAINTENANCE,
        cacheTime: 0,
        onError: () => {
            setFetchError(DeviceDetailsTab.MAINTENANCE);
        },
        onSuccess: () => {
            clearFetchError(DeviceDetailsTab.MAINTENANCE);
        }
    });

    const { data: deviceHistoryLocationData, isFetching: isDeviceHistoryLocationDataLoading } =
        useQueryGetDeviceHistoryLocation(deviceId, {
            enabled: selected === DeviceDetailsTab.LOCATION,
            cacheTime: 0,
            onError: () => {
                setFetchError(DeviceDetailsTab.LOCATION);
            },
            onSuccess: () => {
                clearFetchError(DeviceDetailsTab.LOCATION);
            }
        });

    const {
        data: deviceHistorySubscriptionData,
        isFetching: isDeviceHistorySubscriptionDataLoading
    } = useQueryGetDeviceHistorySubscription(deviceId, {
        enabled: selected === DeviceDetailsTab.SUBSCRIPTION,
        cacheTime: 0,
        onError: () => {
            setFetchError(DeviceDetailsTab.SUBSCRIPTION);
        },
        onSuccess: () => {
            clearFetchError(DeviceDetailsTab.SUBSCRIPTION);
        }
    });

    const isLoading = isLoadingDeviceData;

    useEffect(
        () => () => {
            setTabsErrorMessages({
                [DeviceDetailsTab.EVENTS]: '',
                [DeviceDetailsTab.MAINTENANCE]: '',
                [DeviceDetailsTab.LOCATION]: '',
                [DeviceDetailsTab.SUBSCRIPTION]: ''
            });
        },
        []
    );

    return {
        isLoading,
        deviceData,
        deviceHistoryEventsData,
        isDeviceHistoryEventsDataLoading,
        deviceHistoryMaintenanceData,
        isDeviceHistoryMaintenanceDataLoading,
        deviceHistoryLocationData,
        isDeviceHistoryLocationDataLoading,
        deviceHistorySubscriptionData,
        isDeviceHistorySubscriptionDataLoading,
        tabsErrorMessages,
        isDeviceDataError
    };
};
