import { TFunction } from 'i18next';

export const searchFields = (t: TFunction) => [
    {
        name: 'companyName',
        label: t('companies:filters.filterByCompanyName')
    },
    {
        name: 'nip',
        label: t('companies:filters.filterByNip')
    }
];
