import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostDeleteDeviceBatteryCouter } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationDeleteDeviceBatteryCounter = (
    options?: IMutationOptions<IPostDeleteDeviceBatteryCouter>
) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeleteDeviceBatteryCouter>(
        async deviceUUID => api.delete(Endpoint.DEVICE_BATTERY_COUNTER, { data: deviceUUID }),
        options
    );
