import { RoleType, DeviceDetailsTab } from 'types';

export const deviceDetailsTabsByRole = (role: RoleType): Array<DeviceDetailsTab> => {
    switch (role) {
        case RoleType.Client:
            return [
                DeviceDetailsTab.EVENTS,
                DeviceDetailsTab.MAINTENANCE,
                DeviceDetailsTab.LOCATION
            ];
        default:
            return Object.values(DeviceDetailsTab);
    }
};
