import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tabs, Filters, ButtonIcon, Dialog, type ITableHandlers } from 'components';
import type { IAccount, IAccoutListParams } from 'types';
import { CSVExportButton, SectionWrapper, FiltersWrapper } from 'modules/dashboard';
import { Button, Stack } from '@mui/material';
import { useUsersData } from './hooks';
import { DashboardUsersEditForm } from './user-edit-from';
import { DashboardCreateUserForm } from './create-user-form';
import {
    searchFields,
    TabValue,
    hideColumns,
    translateTableData,
    selectRoleOptions
} from './users.config';

export const DashboardUsersPage = () => {
    const { t } = useTranslation('users');
    const [confirmDeleteUser, setConfirmDeleteUser] = useState<IAccount>();
    const [editUserData, setEditUserData] = useState<IAccount>();
    const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
    const [selected, setSelectedValue] = useState<TabValue>(TabValue.ALL);
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});
    const [usersParams, setUsersParams] = useState<IAccoutListParams | undefined>(undefined);

    const handleClose = () => {
        setConfirmDeleteUser(undefined);
    };

    const {
        usersData,
        usersDataRefetch,
        usersDataIsLoading,
        deleteUser,
        deleteUserMutationReset,
        activateUser,
        verifyUser,
        isGetAccountListError
    } = useUsersData(handleClose, usersParams);

    const handleActivateUser = (account: IAccount) => {
        activateUser({
            accountUUID: account.uuid
        });
    };

    const handleVerifyUser = (account: IAccount) => {
        verifyUser({
            accountUUID: account.uuid
        });
    };

    const handleEditUser = (account: IAccount) => {
        setEditUserData(account);
    };

    const handleDelete = (accountUUID: string) => {
        deleteUser({
            accountUUID
        });
    };

    const handleOpenConfirmDeleteUserDialog = (account: IAccount) => {
        setConfirmDeleteUser(account);
    };

    const tableHandlers: Array<ITableHandlers<IAccount>> = [
        {
            name: t('tableHandlers.activateUser'),
            handler: handleActivateUser,
            hideKeys: ['isActivated', 'isDeleted'],
            icon: ButtonIcon.VERIFY
        },
        {
            name: t('tableHandlers.verifyUser'),
            handler: handleVerifyUser,
            hideKeys: ['isVerified', 'isDeleted'],
            icon: ButtonIcon.VERIFY
        },
        {
            name: t('tableHandlers.editUser'),
            handler: handleEditUser,
            icon: ButtonIcon.EDIT,
            hideKeys: ['isDeleted']
        },
        {
            name: t('tableHandlers.deleteUser'),
            handler: handleOpenConfirmDeleteUserDialog,
            icon: ButtonIcon.DELETE,
            color: 'error',
            isPernamentDelete: true
        }
    ];

    const handleChangeTab = useCallback(
        (value: TabValue) => {
            setSelectedValue(value);
            setUsersParams(prev => {
                switch (value) {
                    case TabValue.ACTIVE:
                        return { ...prev, isDeleted: false, isActivated: true, isVerified: true };
                    case TabValue.PEDNING:
                        return { ...prev, isActivated: false, isDeleted: false, isVerified: false };
                    case TabValue.DELETED:
                        return { ...prev, isDeleted: true };
                    default:
                        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
                        const { isDeleted, isActivated, ...rest } = prev || {};

                        return rest;
                }
            });
        },
        [selected]
    );

    const selectData = [
        {
            name: 'role',
            label: t('filters.filterByRole'),
            options: selectRoleOptions(t),
            selectAll: true
        }
    ];

    useEffect(() => {
        usersDataRefetch();
    }, [selected]);

    return (
        <SectionWrapper
            title={`${t('title')}`}
            isLoading={usersDataIsLoading}
            isError={isGetAccountListError}
        >
            <FiltersWrapper>
                <Filters
                    fields={searchFields(t)}
                    setSearchParams={setSearchParams}
                    disabled={usersData?.length === 0}
                    selects={selectData}
                />
                <Stack direction="row" width="100%" justifyContent="flex-end">
                    <Button
                        onClick={() => setOpenCreateUserModal(true)}
                        sx={{
                            whiteSpace: 'nowrap',
                            width: 'min-content'
                        }}
                    >
                        {t('createUser.title')}
                    </Button>
                    <CSVExportButton data={usersData ?? []} filename="users" />
                </Stack>
            </FiltersWrapper>

            <Tabs<TabValue>
                values={Object.values(TabValue)}
                selected={selected}
                setValue={handleChangeTab}
            />
            <Table<IAccount>
                data={translateTableData(usersData ?? [], t)}
                hide={hideColumns}
                tableHandlers={tableHandlers}
                defaultSortBy="createdAt"
                searchParams={searchParams}
            />

            {editUserData && (
                <DashboardUsersEditForm
                    data={editUserData}
                    onClose={() => setEditUserData(undefined)}
                />
            )}
            {confirmDeleteUser && (
                <Dialog
                    maxWidth="md"
                    open={Boolean(confirmDeleteUser)}
                    onClose={() => {
                        setConfirmDeleteUser(undefined);
                        deleteUserMutationReset();
                    }}
                    onConfirm={() => handleDelete(confirmDeleteUser.uuid)}
                    confirmButtonProps={{
                        color: 'error',
                        variant: 'contained'
                    }}
                    confirmButtonLabel={`${
                        confirmDeleteUser.isDeleted
                            ? t('tableHandlers.permanentlyDelete')
                            : t('tableHandlers.deleteUser')
                    }`}
                    desc={`${t(
                        `alert.${
                            confirmDeleteUser.isDeleted ? 'permanentDeleteUser' : 'deleteUser'
                        }`,
                        {
                            name: confirmDeleteUser.nameAndSurname
                        }
                    )}`}
                />
            )}
            {openCreateUserModal && (
                <DashboardCreateUserForm
                    open={openCreateUserModal}
                    onClose={() => setOpenCreateUserModal(false)}
                />
            )}
        </SectionWrapper>
    );
};
