export enum Endpoint {
    // auth
    AUTH_SESSION = '/session/basic-credentials',
    AUTH_REFRESH_TOKEN = '/session/refresh-token',
    AUTH_DESTROY_TOKEN = '/session/destroy-token',
    // map
    MAP_DEVICES = '/map/devices',
    // account
    ACCOUNT = '/account',
    ACCOUNT_LIST = '/account/list',
    ACCOUNT_ACTIVATE = '/account/activate',
    ACCOUNT_VERIFY = '/account/verify',
    // user
    USER = '/user',
    USER_PASSWORD_RESET = '/user/password-reset',
    USER_PASSWORD = '/user/password',
    USER_REGISTER = '/user/register',
    USER_ACTIVATE = '/user/activate',
    // company
    COMPANY = '/company',
    COMPANY_USERS = '/company/users',
    COMPANY_DEVICES = '/company/devices',
    COMPANY_LIST = '/company/list',
    // device
    DEVICE = '/device',
    DEVICE_LIST = '/device/list',
    DEVICE_MAINTENANCE = '/device/maintenance',
    DEVICE_OWNERSHIP = '/device/ownership',
    DEVICE_LOCATION = '/device/location',
    DEVICE_HISTORY_EVENTS = 'history/device/event',
    DEVICE_HISTORY_MAINTENANCE = 'history/device/maintenance',
    DEVICE_HISTORY_LOCATION = 'history/device/location',
    DEVICE_HISTORY_SUBSCRIPTION = 'history/device/subscription',
    DEVICE_BATTERY_COUNTER = '/device/battery-counter',
    // device type
    DEVICE_TYPE = '/device-type',
    DEVICE_TYPE_LIST = '/device-type/list',
    DEVICE_TYPE_RULE = '/device-type/rule',
    DEVICE_TYPE_RULES = '/device-type/rules',
    // system
    SYSTEM_CONDITION = '/system/condition',
    SYSTEM_CONDITIONS_PUBLIC = '/system/conditions/public',
    // stats
    STATS_USERS = '/counter/users',
    STATS_INSTALLED_DEVICES = '/counter/installed-devices',
    STATS_EVENT_TYPES = '/counter/event-types',
    STATS_EVENTS = '/counter/events'
}

export enum QueryKey {
    // user
    USER = 'user',
    USER_ACTIVATE = 'userActivate',
    // map
    MAP_DEVICES = 'mapDevices',
    // account
    ACCOUNT = 'account',
    ACCOUNT_LIST = 'accountList',
    // company
    COMPANY = 'company',
    COMPANY_USERS = 'companyUsers',
    COMPANY_DEVICES = 'companyDevices',
    COMPANY_LIST = 'companyList',
    // device
    DEVICE_LIST = 'deviceList',
    DEVICE = 'device',
    DEVICE_HISTORY_EVENTS = 'deviceHistoryEvents',
    DEVICE_HISTORY_MAINTENANCE = 'deviceHistoryMaintenance',
    DEVICE_HISTORY_LOCATION = 'deviceHistoryLocation',
    DEVICE_HISTORY_SUBSCRIPTION = 'deviceHistorySubscription',
    // device type
    DEVICE_TYPE_LIST = 'deviceTypeList',
    DEVICE_TYPE_RULES = 'deviceTypeRules',
    DEVICE_TYPE_RULE = 'deviceTypeRule',
    // system
    SYSTEM_CONDITIONS_PUBLIC = 'systemConditionsPublic',
    // stats
    STATS_USERS = 'statsUsers',
    STATS_INSTALLED_DEVICES = 'statsInstalledDevices',
    STATS_EVENT_TYPES = 'statsEventTypes',
    STATS_EVENTS = 'statsEvents'
}
