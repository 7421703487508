import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Language, selectedLanguage } from 'i18/locale';
import { googleMapsConfig } from 'utils';
import { BoxProps } from '@mui/system';
import type { ILocation, IMarker } from 'types';
import { Spinner } from 'components/spinner';
import * as S from './map.styled';

const { googleMapsApiKey, libraries, mapOptions, markerOptions } = googleMapsConfig;

interface IProps extends BoxProps {
    location: ILocation;
    markers?: Array<IMarker>;
    markerHandler?: (item: IMarker) => void;
    handleSelect?: (value: React.SetStateAction<ILocation>) => void;
    zoom?: number;
}

export const Map = ({
    location,
    handleSelect,
    markers,
    markerHandler,
    zoom = 10,
    ...rest
}: IProps) => {
    const { t } = useTranslation('map');

    const { isLoaded } = useLoadScript({
        googleMapsApiKey,
        libraries,
        language: selectedLanguage ?? Language.PL
    });

    const selectHandler = (e: google.maps.MapMouseEvent) => {
        handleSelect &&
            handleSelect(prev =>
                e.latLng ? { lat: e.latLng?.lat(), lng: e.latLng?.lng() } : prev
            );
    };

    return (
        <S.MapWrapper {...rest}>
            {isLoaded ? (
                <GoogleMap
                    options={{ ...mapOptions, draggableCursor: 'crosshair' }}
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    zoom={zoom}
                    center={location}
                    onClick={selectHandler}
                >
                    {markers ? (
                        markers?.map((marker, index) => (
                            <Marker
                                key={`map-item-${index}`}
                                position={marker}
                                title={marker.id}
                                options={markerOptions}
                                onClick={() => markerHandler && markerHandler(marker)}
                            />
                        ))
                    ) : (
                        <Marker position={location} options={markerOptions} />
                    )}
                </GoogleMap>
            ) : (
                <Spinner text={`${t('spinnerLabel')}`} />
            )}
        </S.MapWrapper>
    );
};
