import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import localePL from './pl';
import localeEN from './en';

export enum Language {
    PL = 'pl',
    EN = 'en'
}

export const CURRENT_LANGUAGE = 'CURRENT_LANGUAGE ';

export const selectedLanguage = localStorage.getItem(CURRENT_LANGUAGE);

const resources = {
    pl: localePL,
    en: localeEN
};

i18n.use(initReactI18next).init({
    resources,
    lng: selectedLanguage ?? Language.PL,
    fallbackLng: selectedLanguage ?? Language.PL,
    supportedLngs: Object.values(Language),
    defaultNS: 'common',
    returnNull: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
