import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostEditAccoutData } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationEditAccount = (options?: IMutationOptions<IPostEditAccoutData>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostEditAccoutData>(
        async data => api.patch(Endpoint.ACCOUNT, data),
        options
    );
