import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostDeviceData } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDevice = (options?: IMutationOptions<IPostDeviceData>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeviceData>(
        async data => api.post(Endpoint.DEVICE, data),
        options
    );
