import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostDeleteDevice } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationDeleteDevice = (options?: IMutationOptions<IPostDeleteDevice>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeleteDevice>(
        async deviceUUID => api.delete(Endpoint.DEVICE, { data: deviceUUID }),
        options
    );
