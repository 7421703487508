import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { IGetStatsParams, IStatsUsersData } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

type IStatsUsers = Array<IStatsUsersData>;

export const getStatsUsers = async (params: IGetStatsParams) => {
    const response = await api.get<IStatsUsers>(Endpoint.STATS_USERS, {
        params
    });

    return response.data;
};

export const useQueryGetStatsUsers = (
    params: IGetStatsParams,
    options?: IQueryOptions<IStatsUsers, IStatsUsers>
) =>
    useQuery<IStatsUsers, AxiosError, IStatsUsers>(
        [QueryKey.STATS_USERS],
        () => getStatsUsers(params),
        {
            ...options
        }
    );
