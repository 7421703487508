import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';
import { TablePaginationActions } from './pagination.config';

interface IProps {
    length: number;
    rowsPerPage: number;
    page: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
}

export const Pagination = ({ length, rowsPerPage, page, setPage, setRowsPerPage }: IProps) => {
    const { t } = useTranslation('table');
    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t('rowPerPageLabel')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{
                marginRight: length > rowsPerPage ? 0 : 2,

                '& .MuiTablePagination-selectLabel': {
                    flexGrow: 1,
                    textAlign: 'center'
                },
                '& .MuiTablePagination-actions': {
                    display: 'none'
                },
                '& .MuiTablePagination-spacer': {
                    display: 'none'
                }
            }}
        />
    );
};
