export enum GeneralGraphsTab {
    STATS_USERS = 'statsUsers',
    STATS_INSTALLED_DEVICES = 'statsInstalledDevices',
    STATS_EVENT_TYPES = 'statsEventTypes'
}

export enum GeneralGraphsInput {
    GENERAL_STATS_DATE = 'generalStatsDate'
}

export interface IGeneralGraphsForm {
    [GeneralGraphsInput.GENERAL_STATS_DATE]: Date;
}

export const defaultValues: IGeneralGraphsForm = {
    [GeneralGraphsInput.GENERAL_STATS_DATE]: new Date()
};
