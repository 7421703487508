import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostDeviceOwnership } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDeviceOwnership = (options?: IMutationOptions<IPostDeviceOwnership>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeviceOwnership>(
        async data => api.post(Endpoint.DEVICE_OWNERSHIP, data),
        options
    );
