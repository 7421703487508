import { useState } from 'react';

export const useStatus = () => {
    enum StatusType {
        PENDING = 'pending',
        SUCCESS = 'success',
        ERROR = 'error'
    }

    const [status, setStatus] = useState<StatusType>(StatusType.PENDING);

    return { status, setStatus, StatusType };
};
