import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import type { IGetStatsParams, IDate } from 'types';
import { INVALID_TIMESTAMP } from './constants';

const defaultDateFormat = 'DD.MM.YYYY HH:mm';

export const now = dayjs().format('YYYY-MM-DD');

export const formatDate = (date: string | Date, format = defaultDateFormat) =>
    dayjs(date).format(format);

export const calculateDate = (
    date: IDate,
    type: 'add' | 'subtract',
    value: number,
    range: 'month' | 'year' | 'day' = 'month'
) => {
    const dayjsDate = date instanceof Date ? dayjs(date) : date;

    return dayjsDate[type](value, range).toDate();
};

const isValidTimestamp = (timestamp: number | string | undefined | null): number | false => {
    if (
        timestamp !== undefined &&
        timestamp !== null &&
        !isNaN(Number(timestamp)) &&
        timestamp.toString().length >= 10 &&
        timestamp.toString().length <= 16
    ) {
        const timestampNumber = Number(timestamp);

        return timestampNumber;
    }

    return false;
};

export const dateFromTimestamp = (
    timestamp: string | number | undefined | null,
    format: string = defaultDateFormat
) => {
    const timestampNumber = isValidTimestamp(timestamp);

    if (timestampNumber) {
        return dayjs.unix(timestampNumber).format(format);
    }

    return INVALID_TIMESTAMP;
};

export const dateToTimestamp = (date: Date | string) => dayjs(date).unix();

export const formatedDateToTimestamp = (date: string, format: string = defaultDateFormat) =>
    dayjs(date, format).unix();

export const startAndEndTimestamp = (date: Date): IGetStatsParams => {
    const start = dayjs(date).startOf('M').format('YYYY-MM-DD');
    const end = dayjs(date).endOf('M').format('YYYY-MM-DD');

    return {
        fromUnixTime: new Date(start).getTime() / 1000,
        toUnixTime: new Date(end).getTime() / 1000
    };
};

export const minutesDurationToString = (minutes: number, t: TFunction) => {
    const h = Math.floor(minutes / 60);
    const min = minutes % 60;

    return `${t('time.hours.key', { count: h })} ${t('time.minutes.key', {
        count: min
    })}`;
};

export const transformDate = (date: string) => {
    const dateParts = date.split(' ')?.at(0)?.split('.');

    if (dateParts?.length === 3) {
        // prettier-ignore
        const [
            day,
            month,
            year
        ] = dateParts;

        return `${year}-${month}-${day}`;
    }

    return 'Invalid Date';
};

export const dateProperties = [
    'deviceInstalledUnixTime',
    'subscriptionExpiresAt',
    'createdAt',
    'updatedAt',
    'fromUnixTime',
    'toUnixTime'
];
