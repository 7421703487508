export interface ICompanyAddress {
    city: string;
    country: string;
    street: string;
    postalcode: string;
    province: string;
}

export interface ICompanyListResponse {
    uuid?: string;
    nip: number | string;
    name: string;
    address: ICompanyAddress;
}

export type ICompanyListItem = ICompanyAddress & {
    companyName: string;
    nip: string | number;
    uuid?: string;
};

export type ICompanyResponse = Omit<ICompanyListResponse, 'uuid'> & {
    uuid: string;
};

export interface ICompanyUsersResponse {
    createdAt: string;
    email: string;
    isActivated: boolean;
    isVerified: boolean;
    name: string;
    phonenumber: string;
    surname: string;
}

export type ICompanyUsersList = Omit<ICompanyUsersResponse, 'createdAt'> & {
    createdAt: Date;
};

export type ICompany = ICompanyAddress & {
    uuid: string;
    companyName: string;
    nip: number;
};

export type IPostDeleteCompany = {
    companyUUID: string;
};

export type ICompanyDevices = {
    deviceUUID: string;
    deviceId: string;
    deviceTypeId: number;
    deviceTypeName: string;
    createdAt: string | Date;
    updatedAt: string | Date;
};

export enum CompanyTab {
    DATA = 'companyData',
    USERS = 'users',
    DEVICES = 'devices'
}
