import * as yup from 'yup';
import 'yup-phone';
import { polishNipValidator } from './nip';
import { now } from '..';

import { regex } from '../regex';

type IRegexType = 'name' | 'positive_number' | 'zip_code' | 'surname' | 'hex';

const regexHelper = (type: IRegexType) => {
    switch (type) {
        case 'name':
        case 'surname':
            return regex.NAME;
        case 'positive_number':
            return regex.POSITIVE_NUMBER;
        case 'zip_code':
            return regex.ZIP_CODE;
        case 'hex':
            return regex.HEX;
        default:
            return regex.NOT_EMPTY;
    }
};

export const stringSchema = yup.string().trim().max(60);

export const numberSchema = yup.number();

export const requiredStringSchema = stringSchema.required();

export const passwordSchema = requiredStringSchema
    .min(8, {
        key: 'password_too_short',
        values: { count: 8 }
    })
    .max(30, {
        key: 'password_too_long',
        values: { count: 30 }
    })
    .matches(regex.PASSWORD, 'wrong_password');

export const passwordRepeatSchema = yup
    .string()
    .oneOf([yup.ref('password'), null], 'passwords_must_match');

export const emailSchema = requiredStringSchema.email();

export const isCheckedSchema = yup.boolean().oneOf([true], 'is_checked');

export const regexSchema = (type: IRegexType) =>
    requiredStringSchema.matches(regexHelper(type), `wrong_${type}`);

export const nipSchema = requiredStringSchema.test('nip', 'wrong_nip', value => {
    if (value) {
        return polishNipValidator(value);
    }

    return true;
});

export const phoneSchema = requiredStringSchema
    .phone('PL', true, 'wrong_phone')
    .min(9, 'wrong_phone')
    .max(12, 'wrong_phone');

export const dateSchema = yup
    .date()
    .typeError('wrong_date')
    .min(new Date(now), 'greater_than_today');

export const phonePrefix = '+48';
