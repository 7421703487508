import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MapWrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    minHeight: '200px',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
}));
