import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostDeleteAccount } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationDeleteAccount = (options?: IMutationOptions<IPostDeleteAccount>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeleteAccount>(
        async accountUUID => api.delete(Endpoint.ACCOUNT, { data: accountUUID }),
        options
    );
