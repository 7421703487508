import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostPasswordReset } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPasswordReset = (options?: IMutationOptions<IPostPasswordReset>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostPasswordReset>(
        async data => api.post(Endpoint.USER_PASSWORD, data),
        options
    );
