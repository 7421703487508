import { styled } from '@mui/material/styles';
import { Grid as MUIGrid, Box as MUIBox } from '@mui/material';

export const Grid = styled(MUIGrid)(() => ({
    backgroundColor: '#bdc3c7',
    backgroundImage: `linear-gradient(to right, #bdc3c7, #2c3e50)`,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '& #language-switcher-button': {
        position: 'absolute',
        top: 20,
        right: 20,

        '& svg': {
            width: '1.8rem',
            height: '1.8rem'
        }
    }
}));

export const Box = styled(MUIBox)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
}));
