export const filterArrOfObj = <T>(arr: Array<T>, filterParams: Record<string, string>) => {
    const keys = Object.entries(filterParams)
        .filter(([, value]) => value !== '')
        .map(([key]) => key) as Array<keyof T>;
    const values = Object.values(filterParams).filter(value => value);
    const equal = keys.length === values.length;

    return arr.filter((item: T) =>
        keys[equal ? 'every' : 'some']((key, index) => {
            const keyValue = String(item[key]).trim().toLowerCase();
            const filterValue = String(values[index]).trim().toLowerCase();

            return keyValue.includes(filterValue);
        })
    );
};
