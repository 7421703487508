import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
    background: theme.palette.grey[50],
    overflowX: 'auto',
    height: '100%',
    width: '100%',
    padding: `${theme.spacing(7.25)} ${theme.spacing(3)}`
}));

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    '&:last-of-type': {
        marginBottom: theme.spacing(2)
    }
}));
