import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceTypeRule } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getCompany = async (deviceTypeUUID: string) => {
    const response = await api.get<Array<IDeviceTypeRule>>(Endpoint.DEVICE_TYPE_RULES, {
        params: {
            deviceTypeUUID
        }
    });

    return response.data;
};

export const useQueryGetDeviceTypeRulesList = (
    deviceTypeUUID: string,
    options?: IQueryOptions<Array<IDeviceTypeRule>, Array<IDeviceTypeRule>>
) =>
    useQuery<Array<IDeviceTypeRule>, AxiosError, Array<IDeviceTypeRule>>(
        [QueryKey.DEVICE_TYPE_RULES],
        () => getCompany(deviceTypeUUID),
        {
            select: data =>
                data.map(({ color, deviceRuleUUID, hex, icon, text, type, formulaValue }) => ({
                    deviceRuleUUID,
                    type,
                    hex: hex.toUpperCase(),
                    formulaValue,
                    icon,
                    color,
                    text
                })),
            ...options
        }
    );
