import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, PaperProps, Box } from '@mui/material';
import { Input, Button } from 'components';
import { useMutationPostDeviceType, QueryKey } from 'api';
import { ErrorMessage } from 'modules/common';
import { FormWrapper, FromButtonsWrapper, formFullWidtStyle } from 'modules/dashboard';
import { IDeviceType } from 'types';
import {
    IDeviceTypeForm,
    dynamicDefaultValues,
    dynamicFormSchema,
    DeviceTypeInput
} from './device-type-form.config';

interface IProps extends PaperProps {
    device?: IDeviceType;
    onClose: () => void;
    isAdmin: boolean;
    isEdit: boolean;
}

export const DashboardDeviceTypesForm = ({ device, onClose, isAdmin, isEdit, ...rest }: IProps) => {
    const { t } = useTranslation('deviceTypes');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const methods = useForm<IDeviceTypeForm>({
        defaultValues: dynamicDefaultValues(device),
        resolver: yupResolver(dynamicFormSchema(isAdmin))
    });

    const {
        mutate: postDeviceType,
        isLoading,
        error
    } = useMutationPostDeviceType(isEdit, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.DEVICE_TYPE_LIST]);
            onClose();
            enqueueSnackbar(t(`alert.${isEdit ? 'changeNameSuccess' : 'createSuccess'}`), {
                variant: 'success'
            });
        },
        onError: () => {
            enqueueSnackbar(t(`alert.${isEdit ? 'changeNameError' : 'createError'}`), {
                variant: 'error'
            });
        }
    });

    const onSubmit = (data: IDeviceTypeForm) => {
        if (
            isEdit &&
            (device?.deviceTypeName === data.name ||
                device?.parameterKV === Number(data.parameterKV))
        ) {
            onClose();
        }

        postDeviceType({
            name: data.name,
            deviceTypeUUID: device?.uuid,
            parameterKV: Number(data.parameterKV),
            parameterBatteryLifetime: isAdmin ? Number(data.parameterBatteryLifetime) : 0
        });
    };

    return (
        <FormProvider {...methods}>
            <Paper {...rest}>
                <FormWrapper component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box sx={formFullWidtStyle}>
                        <ErrorMessage error={error} />
                    </Box>
                    <Input
                        variant="standard"
                        name={DeviceTypeInput.NAME}
                        label={t('form.label.name')}
                        sx={formFullWidtStyle}
                    />
                    <Input
                        variant="standard"
                        name={DeviceTypeInput.PARAMETER_KV}
                        type="number"
                        label={t('form.label.parameterKV')}
                        sx={formFullWidtStyle}
                    />
                    {isAdmin && (
                        <Input
                            variant="standard"
                            name={DeviceTypeInput.BATTERY_LIFETIME}
                            type="number"
                            label={t('form.label.parameterBatteryLifetime')}
                            sx={formFullWidtStyle}
                        />
                    )}

                    <FromButtonsWrapper>
                        <Button
                            isLoading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                        >
                            {t('form.submitButton')}
                        </Button>
                    </FromButtonsWrapper>
                </FormWrapper>
            </Paper>
        </FormProvider>
    );
};
