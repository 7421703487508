import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { Cookie } from 'api';
import { useUserData } from 'stores';
import { paths, ROUTE } from 'utils';
import { IUserData } from 'types';
import { NotFoundPage, ServerErrorPage } from 'modules/common';
import { AuthRouter } from 'modules/auth';
import { DashboardRouter } from 'modules/dashboard';
import { TermsAndConditionsPage, PrivacyPolicyPage } from 'modules/app';

const cookies = new Cookies();

export const AppRouter = () => {
    const token = cookies.get(Cookie.ACCESS_TOKEN);
    const { setUserData, logOut } = useUserData();

    useEffect(() => {
        if (token) {
            const userData = jwt_decode(token) as IUserData;
            setUserData(userData);
        } else {
            logOut();
        }
    }, [token]);

    return (
        <Routes>
            <Route index element={<Navigate to={paths.auth.self} replace />} />

            <Route path={`${ROUTE.AUTH}/*`} element={<AuthRouter />} />
            <Route path={`${ROUTE.DASHBOARD}/*`} element={<DashboardRouter />} />

            <Route path={ROUTE.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
            <Route path={ROUTE.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

            <Route path={ROUTE.SERVER_ERROR} element={<ServerErrorPage />} />
            <Route path={ROUTE.NOT_FOUND} element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to={paths.notFound} replace />} />
        </Routes>
    );
};
