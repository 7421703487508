import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IDeleteDeviceTypeRuleData } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationDeleteDeviceTypeRule = (
    options?: IMutationOptions<IDeleteDeviceTypeRuleData>
) =>
    useMutation<AxiosResponse<null>, AxiosError, IDeleteDeviceTypeRuleData>(
        async deviceTypeRuleUUID =>
            api.delete(Endpoint.DEVICE_TYPE_RULE, { data: deviceTypeRuleUUID }),
        options
    );
