import { formatedDateToTimestamp, dateProperties } from 'utils';

export enum Direction {
    ASC = 'asc',
    DESC = 'desc'
}

export const sort = <T>(arr: Array<T>, sortBy: keyof T, order: Direction) => {
    const isDate = dateProperties.includes(sortBy as string);

    const convertToString = (value: T[keyof T]) => String(value).toLowerCase().split(' ').join('');

    const sorted = [...arr].sort((a: T, b: T) => {
        const valA = isDate ? formatedDateToTimestamp(`${a[sortBy]}`) : convertToString(a[sortBy]);
        const valB = isDate ? formatedDateToTimestamp(`${b[sortBy]}`) : convertToString(b[sortBy]);

        return valA > valB ? 1 : -1;
    });

    return order === Direction.ASC ? sorted : sorted.reverse();
};

export const paginate = <T>(arr: Array<T>, page: number, rowsPerPage: number) => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;

    return arr.slice(start, end);
};

export const getKeys = <T extends object>(obj: T, withoutItem?: keyof T) => {
    const keys = withoutItem
        ? Object.keys(obj).filter(item => item !== withoutItem)
        : Object.keys(obj);

    return keys as Array<keyof T>;
};

export const numberOfItem = (index: number, page: number, rowsPerPage: number) => {
    const currentPageNumbering = page * rowsPerPage;

    return currentPageNumbering + index + 1;
};
