import { ReactComponent as PolishFlag } from 'assets/poland.svg';
import { ReactComponent as EnglishFlag } from 'assets/uk.svg';
import { Language } from 'i18/locale';

export const handleIcon = (language: Language) => {
    switch (language) {
        case Language.EN:
            return <EnglishFlag />;
        default:
            return <PolishFlag />;
    }
};
