import { useState, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
    Table,
    Tabs,
    Filters,
    Button,
    ButtonIcon,
    Modal,
    Dialog,
    type ITableHandlers
} from 'components';
import { paths } from 'utils';
import { useQueryGetDeviceList, useMutationDeleteDevice, QueryKey } from 'api';
import {
    CSVExportButton,
    SectionWrapper,
    FiltersWrapper,
    useDeviceTypeSelect
} from 'modules/dashboard';
import { IDeviceList, RoleType } from 'types';
import { useUserData } from 'stores';
import { DashboardDeviceForm } from './devices-details/device-form';
import {
    searchFields,
    hideColumns,
    disableCellFormat,
    allowedUsers,
    TabValue
} from './devices.config';

export const DashboardDevicesPage = () => {
    const { t } = useTranslation('devices');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TabValue>(TabValue.ALL_DEVICES);
    const [confirmDeleteDevice, setConfirmDeleteDevice] = useState<IDeviceList>();
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});
    const { deviceTypesSelect } = useDeviceTypeSelect();
    const { userData } = useUserData();
    const role = userData && userData.role;

    const isAdmin = role === RoleType.Admin;

    const { mutate: deleteDevice, reset } = useMutationDeleteDevice({
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.DEVICE_LIST]);
            setConfirmDeleteDevice(undefined);
            enqueueSnackbar(t('alert.deleteDeviceSuccess'), { variant: 'success' });
        },
        onError: () => {
            setConfirmDeleteDevice(undefined);
            enqueueSnackbar(t('alert.deleteDeviceError'), { variant: 'error' });
        }
    });

    const {
        data: devicesListData,
        isLoading,
        isError: isGetDeviceListError
    } = useQueryGetDeviceList();

    const handleShowDetails = (device: IDeviceList) => {
        navigate(`${paths.dashboard.devices}/${device.deviceUUID}`);
    };

    const handleDelete = (deviceUUID: string) => {
        deleteDevice({
            deviceUUID
        });
    };

    const handleOpenConfirmDeleteUserDialog = (device: IDeviceList) => {
        setConfirmDeleteDevice(device);
    };

    const tableHandlers = useMemo(() => {
        const handlers: Array<ITableHandlers<IDeviceList>> = [
            {
                name: t('tableHandlers.showDetails'),
                handler: handleShowDetails,
                icon: ButtonIcon.INFO
            }
        ];

        if (isAdmin) {
            handlers.push({
                name: t('tableHandlers.delete'),
                handler: handleOpenConfirmDeleteUserDialog,
                icon: ButtonIcon.DELETE,
                color: 'error'
            });
        }

        return handlers;
    }, [isAdmin]);

    const selectData = [
        {
            name: 'deviceTypeName',
            label: t('filters.filterByDeviceType'),
            options: deviceTypesSelect.map(deviceType => ({
                label: deviceType.label,
                value: deviceType.label
            })),
            selectAll: true
        }
    ];

    const handleSelectTab = useCallback(
        (value: TabValue) => {
            setSelectedTab(value);
            setSearchParams(prev => {
                switch (value) {
                    case TabValue.ACTIVE_DEVICES:
                        return { ...prev, isActive: 'green' };
                    case TabValue.INACTIVE_DEVICES:
                        return { ...prev, isActive: 'red' };
                    default:
                        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
                        const { isActive, ...rest } = prev;

                        return rest;
                }
            });
        },
        [selectedTab]
    );

    return (
        <SectionWrapper
            title={`${t('title')}`}
            isLoading={isLoading}
            isError={isGetDeviceListError}
        >
            <FiltersWrapper>
                <Filters
                    selects={selectData}
                    fields={searchFields(t)}
                    setSearchParams={setSearchParams}
                    disabled={deviceTypesSelect.length === 0 || devicesListData?.length === 0}
                />

                <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
                    {allowedUsers.includes(role as RoleType) && (
                        <Button
                            disabled={deviceTypesSelect.length === 0}
                            onClick={() => setOpenAddDeviceModal(true)}
                            icon={ButtonIcon.ADD}
                        >
                            {t('addDevice.buttonLabel')}
                        </Button>
                    )}

                    <CSVExportButton data={devicesListData ?? []} filename="devices" />
                </Box>
            </FiltersWrapper>
            <Tabs<TabValue>
                values={Object.values(TabValue)}
                selected={selectedTab}
                setValue={handleSelectTab}
            />
            <Table<IDeviceList>
                data={devicesListData ?? []}
                hide={hideColumns}
                tableHandlers={tableHandlers}
                disableCellFormat={disableCellFormat}
                defaultSortBy="deviceInstalledUnixTime"
                searchParams={searchParams}
            />

            {openAddDeviceModal && role && (
                <Modal
                    title={`${t('addDevice.modalTitle')}`}
                    open={openAddDeviceModal}
                    onClose={() => setOpenAddDeviceModal(false)}
                >
                    <DashboardDeviceForm
                        handleClose={() => setOpenAddDeviceModal(false)}
                        role={role}
                    />
                </Modal>
            )}

            {confirmDeleteDevice && (
                <Dialog
                    open={Boolean(confirmDeleteDevice)}
                    onClose={() => {
                        setConfirmDeleteDevice(undefined);
                        reset();
                    }}
                    onConfirm={() => handleDelete(confirmDeleteDevice.deviceUUID)}
                    desc={`${t('alert.deleteDevice', {
                        name: confirmDeleteDevice.deviceName
                    })}`}
                />
            )}
        </SectionWrapper>
    );
};
