import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CardsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1100)]: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        columnGap: theme.spacing(3)
    }
}));
