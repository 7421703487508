export const polishNipValidator = (nip: string): boolean => {
    const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];

    if (!nip) {
        return false;
    }

    const numbers = Array.from(nip, Number);

    if (numbers.length !== 10) {
        return false;
    }

    const controlNumber = numbers[9];

    if (controlNumber === null) {
        return false;
    }

    if (numbers.every(number => number === numbers[0])) {
        return false;
    }

    // prettier-ignore
    const sum = weight.reduce((result, current, index) => result + (current * numbers[index]), 0)

    return sum % 11 === controlNumber;
};
