import { useEffect, useState } from 'react';

export const useMarkdown = (md: string) => {
    const [markdown, setMarkdown] = useState('');

    const setContent = async () => {
        const res = await fetch(md);
        setMarkdown(await res.text());
    };

    useEffect(() => {
        setContent();
    }, []);

    return markdown;
};
