import { styled } from '@mui/material/styles';
import { Pagination as MUIPagination } from '@mui/material';

export const Pagination = styled(MUIPagination)(({ theme }) => ({
    minWidth: 'fit-content',
    padding: `0 ${theme.spacing(1)}`,
    '& .MuiPaginationItem-root': {
        width: 54,
        height: 23
    }
}));
