import {
    useMutationPatchDevice,
    useMutationPostDeviceMaintenance,
    useMutationPostDeviceLocation,
    useMutationPostDeviceOwnership,
    QueryKey
} from 'api';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useEditDeviceDetails = (onClose: () => void, title?: string) => {
    const { t } = useTranslation('deviceDetails');

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const handleCommonErorr = () => {
        enqueueSnackbar(t('alert.commonError'), {
            variant: 'error'
        });
    };

    const { mutate: patchDevice, error: patchDeviceError } = useMutationPatchDevice({
        onSuccess: () => {
            Promise.all([
                queryClient.invalidateQueries([QueryKey.DEVICE]),
                queryClient.invalidateQueries([QueryKey.DEVICE_HISTORY_SUBSCRIPTION])
            ]);
            onClose();
            enqueueSnackbar(t(`alert.${title}`), {
                variant: 'success'
            });
        },
        onError: () => {
            handleCommonErorr();
        }
    });

    const { mutate: postDeviceMaintenance, error: postDeviceMaintenanceError } =
        useMutationPostDeviceMaintenance({
            onSuccess: () => {
                Promise.all([
                    queryClient.invalidateQueries([QueryKey.DEVICE]),
                    queryClient.invalidateQueries([QueryKey.DEVICE_HISTORY_MAINTENANCE])
                ]);
                onClose();
                enqueueSnackbar(t(`alert.${title}`), {
                    variant: 'success'
                });
            },
            onError: () => {
                handleCommonErorr();
            }
        });

    const { mutate: postDeviceLocation, error: postDeviceLocationError } =
        useMutationPostDeviceLocation({
            onSuccess: () => {
                Promise.all([
                    queryClient.invalidateQueries([QueryKey.DEVICE]),
                    queryClient.invalidateQueries([QueryKey.DEVICE_HISTORY_LOCATION])
                ]);
                enqueueSnackbar(t('alert.editLocationSuccess'), { variant: 'success' });
                onClose();
            },
            onError: () => {
                handleCommonErorr();
            }
        });

    const { mutate: postDeviceOwnership, error: postDeviceOwnershipError } =
        useMutationPostDeviceOwnership({
            onSuccess: () => {
                Promise.all([queryClient.invalidateQueries([QueryKey.DEVICE])]);
                enqueueSnackbar(t('alert.deviceOwnerNip'), { variant: 'success' });
                onClose();
            },
            onError: () => {
                handleCommonErorr();
            }
        });

    return {
        patchDevice,
        patchDeviceError,
        postDeviceMaintenance,
        postDeviceMaintenanceError,
        postDeviceLocation,
        postDeviceLocationError,
        postDeviceOwnership,
        postDeviceOwnershipError
    };
};
