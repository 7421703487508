import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Toolbar, ListItem, Tooltip, Typography, Stack } from '@mui/material';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useUserData } from 'stores';
import { useUserType } from 'hooks';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import type { INavItem } from '../../dashboard.config';
import * as S from './drawer.styled';

interface IProps {
    items: Array<INavItem>;
    handleClose: () => void;
    isOpen: boolean;
}

export const Drawer = ({ items, isOpen, handleClose }: IProps) => {
    const { t } = useTranslation('dashboard');
    const { logOut, userData } = useUserData();
    const userType = useUserType();
    const { pathname } = useLocation();
    const drawerWidth = isOpen ? 240 : 55;
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <S.Drawer
            drawerWidth={drawerWidth}
            variant={isMobileView ? 'temporary' : 'permanent'}
            open={isOpen}
            onClose={isMobileView ? handleClose : undefined}
        >
            <Toolbar />
            <Box>
                <S.List>
                    {items.map(({ name, icon, path }) => (
                        <Tooltip
                            key={`drawer-item-${name}`}
                            placement="right"
                            title={!isOpen ? t(`nav.${name}`) : undefined}
                        >
                            <ListItem
                                onClick={isMobileView ? handleClose : undefined}
                                disablePadding
                            >
                                <S.ListItemButton
                                    selected={pathname.split('/').slice(0, 3).join('/') === path}
                                >
                                    <S.Link to={path}>
                                        <S.ListItemIcon>{icon}</S.ListItemIcon>
                                        <S.ListItemText primary={t(`nav.${name}`)} />
                                    </S.Link>
                                </S.ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
                    {isMobileView && (
                        <ListItem disablePadding onClick={logOut}>
                            <S.ListItemButton>
                                <S.ListItemIcon>
                                    <PowerSettingsNewIcon />
                                </S.ListItemIcon>
                                <S.ListItemText primary={t('nav.logout')} />
                            </S.ListItemButton>
                        </ListItem>
                    )}
                </S.List>
            </Box>
            {isMobileView && (
                <Stack color="primary.contrastText" paddingLeft={2} paddingBottom={2} mt="auto">
                    <Typography>{userData?.email}</Typography>
                    <Typography variant="body2">{userType}</Typography>
                </Stack>
            )}
        </S.Drawer>
    );
};
