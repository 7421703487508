import { Spinner } from 'components';
import * as S from './auth-info.styled';

interface IProps {
    title: string;
    description: string;
    buttonText: string;
    redirectPath: string;
    isLoading?: boolean;
}

export const AuthInfo = ({ title, description, buttonText, redirectPath, isLoading }: IProps) => (
    <S.Container>
        {isLoading ? (
            <Spinner
                sx={{
                    mt: 5,
                    color: 'primary.contrastText'
                }}
            />
        ) : (
            <>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
                <S.Button href={redirectPath}>{buttonText}</S.Button>
            </>
        )}
    </S.Container>
);
