import { styled } from '@mui/material/styles';

import { Box, Tab as MUITab } from '@mui/material';

export const Container = styled(Box)(() => ({
    width: '100%',
    borderBottom: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
}));

export const Tab = styled(MUITab)(({ theme }) => ({
    color: theme.palette.grey[600],
    maxWidth: '100%',

    '&.Mui-selected': {
        color: theme.palette.grey[900]
    },

    '&.MuiButtonBase-root': {
        flexGrow: 1
    }
}));
