import { styled } from '@mui/material/styles';
import {
    Drawer as MUIDrawer,
    ListItemButton as MUIListItemButton,
    ListItemIcon as MUIListItemIcon,
    DrawerProps,
    List as MuiList,
    ListItemText as MUIListItemText
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface IDrawerProps extends DrawerProps {
    drawerWidth: number;
}

export const List = styled(MuiList)(({ theme }) => ({
    color: theme.palette.common.white
}));

export const ListItemText = styled(MUIListItemText)(() => ({
    whiteSpace: 'nowrap'
}));

export const Drawer = styled(MUIDrawer, {
    shouldForwardProp: prop => prop !== 'drawerWidth'
})<IDrawerProps>(({ theme, drawerWidth }) => ({
    width: drawerWidth,
    border: 'none',
    transition: 'all 0.3s ease-in-out',
    [`& .MuiDrawer-paper`]: {
        background: theme.palette.grey[900],
        width: drawerWidth,
        boxSizing: 'border-box',
        overflowX: 'hidden',
        transition: 'inherit'
    }
}));

export const ListItemButton = styled(MUIListItemButton)(({ theme, selected }) => ({
    padding: 0,
    height: '56px',
    [selected ? '&.Mui-selected' : '']: {
        background: theme.palette.error.main,
        ['&.Mui-selected:hover']: {
            background: theme.palette.error.light
        }
    }
}));

export const ListItemIcon = styled(MUIListItemIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    paddingLeft: '16px'
}));

export const Link = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    color: theme.palette.common.white
}));
