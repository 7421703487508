import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostActivateAccount } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationActivateAccount = (options?: IMutationOptions<IPostActivateAccount>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostActivateAccount>(
        async accountUUID => api.post(Endpoint.ACCOUNT_ACTIVATE, accountUUID),
        options
    );
