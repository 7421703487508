import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceResponse, IDevice } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDevice = async (deviceUUID: string) => {
    const response = await api.get<IDeviceResponse>(Endpoint.DEVICE, {
        params: {
            deviceUUID
        }
    });

    return response.data;
};

export const useQueryGetDevice = (
    deviceUUID: string,
    options?: IQueryOptions<IDeviceResponse, IDevice>
) =>
    useQuery<IDeviceResponse, AxiosError, IDevice>([QueryKey.DEVICE], () => getDevice(deviceUUID), {
        select: ({
            deviceUUID,
            deviceId,
            devicePSC,
            deviceName,
            deviceTypeName,
            deviceOwnerName,
            deviceOwnerNip,
            deviceInstalledUnixTime,
            deviceInstallerName,
            deviceInstallerNip,
            subscriptionTransitMethod,
            subscriptionPhonenumber,
            subscriptionExpiresAt,
            lastEventText,
            lastEventIcon,
            lastEventColor,
            lastEventAt,
            batteryLevel,
            batteryLevelAt,
            batteryLifetime,
            batteryLifetimeCounter,
            waterDrainedInMetersSq,
            waterDrainedInMinutes,
            location
        }) => ({
            deviceUUID,
            deviceId,
            devicePSC,
            deviceName,
            deviceTypeName,
            waterDrainedInMetersSq,
            waterDrainedInMinutes,
            deviceOwnerName,
            deviceOwnerNip,
            deviceInstalledUnixTime,
            deviceInstallerName,
            deviceInstallerNip,
            subscriptionTransitMethod,
            subscriptionPhonenumber,
            subscriptionExpiresAt,
            lastEventText,
            lastEventIcon,
            lastEventColor,
            lastEventAt,
            batteryLevel,
            batteryLevelAt,
            batteryLifetime,
            batteryLifetimeCounter,
            lat: Number(location.lat),
            lng: Number(location.long)
        }),
        ...options
    });
