import type { ICompanyListItem } from 'types';
import * as yup from 'yup';
import { nipSchema, requiredStringSchema, regexSchema } from 'utils';

export enum CompanyFormInput {
    NIP = 'nip',
    COMPANY_NAME = 'companyName',
    STREET = 'street',
    CITY = 'city',
    PROVINCE = 'province',
    POSTALCODE = 'postalcode',
    COUNTRY = 'country'
}

export const defaultValues: ICompanyListItem = {
    [CompanyFormInput.NIP]: '',
    [CompanyFormInput.COMPANY_NAME]: '',
    [CompanyFormInput.STREET]: '',
    [CompanyFormInput.CITY]: '',
    [CompanyFormInput.POSTALCODE]: '',
    [CompanyFormInput.PROVINCE]: '',
    [CompanyFormInput.COUNTRY]: ''
};

export const dynamicDefaultValues = (arr: ICompanyListItem) => {
    const dynamicValues = Object.entries(arr).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value
        }),
        {}
    ) as ICompanyListItem;

    return {
        ...dynamicValues
    };
};

export const formSchema = yup.object({
    [CompanyFormInput.NIP]: nipSchema,
    [CompanyFormInput.COMPANY_NAME]: requiredStringSchema,
    [CompanyFormInput.STREET]: requiredStringSchema,
    [CompanyFormInput.CITY]: requiredStringSchema,
    [CompanyFormInput.POSTALCODE]: regexSchema('zip_code'),
    [CompanyFormInput.PROVINCE]: requiredStringSchema,
    [CompanyFormInput.COUNTRY]: requiredStringSchema
});
