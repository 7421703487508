import { IDevice } from 'types';

export const hideColumns: Array<keyof IDevice> = [
    'deviceUUID',
    'deviceId',
    'deviceOwnerName',
    'deviceOwnerNip',
    'deviceInstallerName',
    'deviceInstallerNip',
    'deviceInstalledUnixTime',
    'subscriptionTransitMethod',
    'subscriptionPhonenumber',
    'subscriptionExpiresAt',
    'lastEventText',
    'lastEventIcon',
    'lastEventColor',
    'lastEventAt',
    'batteryLevelAt',
    'waterDrainedInMetersSq',
    'batteryLevel'
];
