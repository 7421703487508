import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { ICompanyListResponse, ICompanyListItem } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getCompanyList = async () => {
    const response = await api.get<Array<ICompanyListResponse>>(Endpoint.COMPANY_LIST);

    return response.data;
};

export const useQueryGetCompanyList = (
    options?: IQueryOptions<Array<ICompanyListResponse>, Array<ICompanyListItem>>
) =>
    useQuery<Array<ICompanyListResponse>, AxiosError, Array<ICompanyListItem>>(
        [QueryKey.COMPANY_LIST],
        getCompanyList,
        {
            select: data =>
                data.map(({ uuid, nip, name, address }) => ({
                    uuid,
                    companyName: name,
                    nip,
                    ...address
                })),
            ...options
        }
    );
