import { createTheme } from '@material-ui/core/styles';

export const color = {
    primary: '#2c3e50',
    secondary: '#004877',
    secondaryLight: '#336d92',
    red: '#ed1c24',
    redLight: '#f14950',
    green: '#467F3C',
    greenLight: '#6b9963',
    orange: '#ee7323',
    orangeLight: '#f18f4f',
    blue: '#1E88E5',
    blueLight: '#4ba0ea',
    white: '#ffffff',
    gray: '#4D4D4D',
    gray800: '#313030',
    lightgray: '#6D6E71',
    sidebar: '#1B1C1C'
};

export const theme = createTheme({
    palette: {
        primary: {
            main: color.primary
        },
        secondary: {
            main: color.secondary
        },
        background: {
            default: 'linear-gradient(to right, #bdc3c7, #2c3e50)'
        },
        grey: {
            500: color.lightgray,
            800: color.gray800,
            900: color.sidebar
        },
        error: {
            main: color.red
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    height: '100vh'
                },
                html: {
                    height: '100vh'
                },
                '#root': {
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto'
                }
            }
        },
        MuiGrid: {
            root: {
                flexGrow: 1
            }
        }
    }
});
