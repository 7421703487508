import 'dayjs/locale/pl';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';
import { useHandleInputError } from '../hooks';

type IProps = {
    name: string;
    disablePast?: boolean;
    disableFuture?: boolean;
    format?: Array<'year' | 'day' | 'month'>;
    openTo?: 'year' | 'day' | 'month';
} & TextFieldProps;

export const DatePicker = ({
    name,
    disablePast,
    disableFuture,
    format = ['year', 'month', 'day'],
    openTo = 'day',
    ...rest
}: IProps) => {
    const { i18n } = useTranslation();
    const { handleInputError } = useHandleInputError();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const errorMessage = handleInputError(error);

                return (
                    <FormControl sx={rest.sx}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                        >
                            <DatePickerMUI
                                {...field}
                                views={format}
                                disableFuture={disableFuture}
                                disablePast={disablePast}
                                openTo={openTo}
                                minDate={new Date('2010-01-01')}
                                maxDate={disableFuture ? new Date() : undefined}
                                renderInput={params => (
                                    <TextField
                                        id={name}
                                        {...params}
                                        {...rest}
                                        error={Boolean(errorMessage)}
                                        helperText={errorMessage}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </FormControl>
                );
            }}
        />
    );
};
