import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostMaintenance } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDeviceMaintenance = (options?: IMutationOptions<IPostMaintenance>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostMaintenance>(
        async data => api.post(Endpoint.DEVICE_MAINTENANCE, data),
        options
    );
