import { styled } from '@mui/material/styles';
import { Chip, ChipProps } from '@mui/material';

interface IChipProps extends ChipProps {
    pointer?: boolean;
}

export const MUIChip = styled(Chip, {
    shouldForwardProp: prop => prop !== 'pointer'
})<IChipProps>(({ theme, pointer }) => ({
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    cursor: pointer ? 'pointer' : 'default'
}));
