import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryGetStatsEvents, QueryKey } from 'api';
import { dateFromTimestamp, startAndEndTimestamp, cutDatasetToPresentDay } from 'utils';
import { color } from 'modules/app';

export const useGetEventsStatsData = (date: Date) => {
    const { t } = useTranslation('statistics');
    const queryClient = useQueryClient();

    const range = startAndEndTimestamp(date);

    const {
        data: statsEvents,
        refetch: refetchStatsEvents,
        isFetching: isLoading,
        isError: isGetStatsEventsError
    } = useQueryGetStatsEvents(range);

    const eventsStatsData = {
        labels: statsEvents?.map(({ timestamp }) => dateFromTimestamp(timestamp, 'DD.MM')) ?? [],
        datasets: [
            {
                label: `${t('chart.events.gsmEventsCount')}`,
                backgroundColor: color.blue,
                borderColor: color.blueLight,
                data:
                    cutDatasetToPresentDay(
                        date,
                        statsEvents?.map(({ gsmEventsCount }) => gsmEventsCount)
                    ) ?? []
            },
            {
                label: `${t('chart.events.sigfoxEventsCount')}`,
                backgroundColor: color.green,
                borderColor: color.greenLight,
                data:
                    cutDatasetToPresentDay(
                        date,
                        statsEvents?.map(({ sigfoxEventsCount }) => sigfoxEventsCount)
                    ) ?? []
            }
        ]
    };

    const refetchEventsStats = () => {
        refetchStatsEvents();
        queryClient.invalidateQueries([QueryKey.STATS_EVENTS]);
    };

    return {
        eventsStatsData,
        isLoading,
        refetchEventsStats,
        isGetStatsEventsError
    };
};
