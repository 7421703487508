import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostDeleteCompany } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationDeleteCompany = (options?: IMutationOptions<IPostDeleteCompany>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeleteCompany>(
        async companyUUID => api.delete(Endpoint.COMPANY, { data: companyUUID }),
        options
    );
