import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceList } from 'types';
import { dateFromTimestamp, getDeviceStatusColor } from 'utils';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceList = async () => {
    const response = await api.get<Array<IDeviceList>>(Endpoint.DEVICE_LIST);

    return response.data;
};

export const useQueryGetDeviceList = (
    options?: IQueryOptions<Array<IDeviceList>, Array<IDeviceList>>
) =>
    useQuery<Array<IDeviceList>, AxiosError, Array<IDeviceList>>(
        [QueryKey.DEVICE_LIST],
        getDeviceList,
        {
            select: data =>
                data.map(
                    ({
                        deviceUUID,
                        isActive,
                        isAwake,
                        deviceId,
                        devicePSC,
                        deviceName,
                        deviceTypeName,
                        deviceOwnerName,
                        deviceOwnerNip,
                        deviceInstalledUnixTime,
                        deviceInstallerName,
                        deviceInstallerNip,
                        subscriptionTransitMethod,
                        subscriptionPhonenumber,
                        subscriptionExpiresAt,
                        lastEventText,
                        lastEventIcon,
                        lastEventColor,
                        lastEventAt,
                        batteryLevel,
                        batteryLevelAt,
                        waterDrainedInMetersSq
                    }) => ({
                        isAwake,
                        isActive: getDeviceStatusColor(isAwake, Boolean(isActive)),
                        deviceUUID,
                        deviceId,
                        devicePSC,
                        deviceName,
                        deviceTypeName,
                        deviceInstalledUnixTime: dateFromTimestamp(deviceInstalledUnixTime),
                        subscriptionExpiresAt: dateFromTimestamp(subscriptionExpiresAt),
                        deviceOwnerName,
                        deviceOwnerNip,
                        deviceInstallerName,
                        deviceInstallerNip,
                        subscriptionTransitMethod,
                        subscriptionPhonenumber,
                        lastEventText,
                        lastEventIcon,
                        lastEventColor,
                        lastEventAt,
                        batteryLevel,
                        batteryLevelAt,
                        waterDrainedInMetersSq
                    })
                ),
            ...options
        }
    );
