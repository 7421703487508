import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { REACT_APP_API_BASE_URL } from 'lib';
import { Cookies } from 'react-cookie';
import { Endpoint } from './api.endpoints';

const defaultOptions = {
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
};

export enum Cookie {
    ACCESS_TOKEN = 'domex-smart-access-token',
    REFRESH_TOKEN = 'domex-smart-refresh-token'
}

const cookies = new Cookies();

export const api = axios.create(defaultOptions);
api.interceptors.request.use(
    config => {
        const token = cookies.get(Cookie.ACCESS_TOKEN);

        if (config.headers === undefined) {
            config.headers = {};
        }

        if (typeof token === 'string') {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
        const { response } = error;

        const isAuth = /^\/auth\/?$/.test(window.location.pathname);

        if (response && response.status === 401 && window && !isAuth) {
            cookies.remove(Cookie.ACCESS_TOKEN, { path: '/' });
            cookies.remove(Cookie.REFRESH_TOKEN, { path: '/' });

            return (window.location.pathname = '/');
        }

        if (response && response.status === 403) {
            const result = await api
                .patch(Endpoint.AUTH_REFRESH_TOKEN, {
                    refreshToken: cookies.get(Cookie.REFRESH_TOKEN)
                })
                .catch(error => error);

            if (result instanceof Error) {
                return Promise.reject(result);
            }

            if (result.status !== 200) {
                cookies.remove(Cookie.ACCESS_TOKEN, { path: '/' });
                cookies.remove(Cookie.REFRESH_TOKEN, { path: '/' });

                return Promise.reject(error);
            }

            cookies.set(Cookie.ACCESS_TOKEN, result.data.accessToken, { path: '/' });
            cookies.set(Cookie.REFRESH_TOKEN, result.data.refreshToken, { path: '/' });

            const config = error.config as AxiosRequestConfig<any>;

            return api.request({
                method: config.method,
                url: config.url,
                baseURL: config.baseURL,
                data: config.data,
                params: config.params
            });
        }

        return Promise.reject(error);
    }
);
