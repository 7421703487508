import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import { SwitchProps } from '@mui/material';
import * as S from './switch.styled';

interface IProps extends SwitchProps {
    name: string;
    label: ReactNode;
    isLoading?: boolean;
}

export const Switch = ({ name, label, isLoading, ...rest }: IProps) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <S.Wrapper>
                    {isLoading ? (
                        <CircularProgress size={22} />
                    ) : (
                        <S.Switch {...field} {...rest} checked={field.value} />
                    )}
                    <S.Label isError={Boolean(error)}>{label}</S.Label>
                </S.Wrapper>
            )}
        />
    );
};
