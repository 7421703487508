import { ChipProps } from '@mui/material';
import * as S from './chip.styled';

interface IProps extends ChipProps {
    onClick?: () => void;
}

export const Chip = ({ onClick, ...rest }: IProps) => (
    <S.MUIChip
        onClick={onClick}
        pointer={Boolean(onClick)}
        variant="filled"
        color="primary"
        {...rest}
    />
);
