import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostSystemCondtionData } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostSystemCondition = (
    options?: IMutationOptions<IPostSystemCondtionData>
) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostSystemCondtionData>(
        async data => api.post(Endpoint.SYSTEM_CONDITION, data),
        options
    );
