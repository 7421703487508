import { ILocation } from 'types';

export enum LocationFormInput {
    SEARCH_QUERY = 'searchQuery',
    LATITUDE = 'lat',
    LONGITUDE = 'lng'
}

export interface ILocationForm extends ILocation {
    [LocationFormInput.SEARCH_QUERY]: string;
}

export const defaultValues = {
    [LocationFormInput.SEARCH_QUERY]: '',
    [LocationFormInput.LATITUDE]: 0,
    [LocationFormInput.LONGITUDE]: 0
};
