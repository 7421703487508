import * as yup from 'yup';
import { nipSchema, stringSchema, formatDate, dateSchema, transformDate } from 'utils';
import { ISelectOptions } from 'types';

export type IDeviceDetailsEditForm = Record<string, string>;

export enum DeviceDetailsEditType {
    DEVICE_TYPE_NAME = 'deviceTypeName',
    DEVICE_PHONE_NUMBER = 'subscriptionPhonenumber',
    DEVICE_OWNER_NIP = 'deviceOwnerNip',
    DEVICE_SUBSCRIPTION_EXPIRES_AT = 'subscriptionExpiresAt'
}

export const dynamicDefaultValues = (data: IDeviceDetailsEditForm, title: string) => {
    switch (title) {
        case DeviceDetailsEditType.DEVICE_PHONE_NUMBER:
            return {
                subscriptionPhonenumber: data[title].slice(3)
            };

        case DeviceDetailsEditType.DEVICE_SUBSCRIPTION_EXPIRES_AT:
            return {
                subscriptionExpiresAt: formatDate(
                    transformDate(data.subscriptionExpiresAt),
                    'YYYY-MM-DD'
                )
            };
        default:
            return {
                [title]: data[title]
            };
    }
};

export const dynamicFormSchema = (title: string) => {
    switch (title) {
        case DeviceDetailsEditType.DEVICE_PHONE_NUMBER:
            return yup.object({
                subscriptionPhonenumber: stringSchema.notRequired()
            });
        case DeviceDetailsEditType.DEVICE_SUBSCRIPTION_EXPIRES_AT:
            return yup.object({
                subscriptionExpiresAt: dateSchema
            });
        case DeviceDetailsEditType.DEVICE_OWNER_NIP:
            return yup.object({
                deviceOwnerNip: nipSchema
            });
        default:
            return yup.object({});
    }
};

export const deviceTypesSelectOptions = (deviceTypeSelect: Array<ISelectOptions>) =>
    deviceTypeSelect.map(deviceType => ({
        label: deviceType.label,
        value: deviceType.label
    }));
