import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Filters, Button, ButtonIcon, Modal, type ITableHandlers } from 'components';
import { useUserData } from 'stores';
import { paths } from 'utils';
import { useQueryGetDeviceTypeList } from 'api';
import { SectionWrapper, FiltersWrapper } from 'modules/dashboard';
import { IDeviceType, AddDeviceType, RoleType } from 'types';
import { DashboardDeviceTypesForm } from './device-type-form';
import { searchFields } from './device-types.config';

export const DashboardDevicesTypesPage = () => {
    const { t } = useTranslation('deviceTypes');
    const [openDeviceTypeNameModal, setOpenDeviceTypeNameModal] = useState<AddDeviceType | null>(
        null
    );
    const [device, setDevice] = useState<IDeviceType>();
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});
    const navigate = useNavigate();
    const {
        data: deviceTypes,
        isLoading,
        isError: isGetDeviceTypeListError
    } = useQueryGetDeviceTypeList();
    const modalIsOpen = Boolean(openDeviceTypeNameModal);
    const { userData } = useUserData();
    const role = userData && userData.role;
    const isAdmin = role === RoleType.Admin;

    const handleShowRules = (deviceType: IDeviceType) => {
        navigate(`${paths.dashboard.devicesTypes}/${deviceType.uuid}/rules`);
    };

    const handleEditDeviceName = (deviceType: IDeviceType) => {
        setDevice(deviceType);
        setOpenDeviceTypeNameModal(AddDeviceType.EDIT);
    };

    const handleCloseAddDeviceTypeModal = () => {
        setOpenDeviceTypeNameModal(null);
        setDevice(undefined);
    };

    const tableHandlers: Array<ITableHandlers<IDeviceType>> = [
        {
            name: t('tableHandlers.editDevice'),
            handler: handleEditDeviceName,
            icon: ButtonIcon.EDIT
        },
        {
            name: t('tableHandlers.showRules'),
            handler: handleShowRules,
            icon: ButtonIcon.RULE
        }
    ];

    return (
        <SectionWrapper
            title={`${t('title')}`}
            isLoading={isLoading}
            isError={isGetDeviceTypeListError}
        >
            <FiltersWrapper>
                <Filters
                    fields={searchFields(t)}
                    setSearchParams={setSearchParams}
                    disabled={deviceTypes?.length === 0}
                />
                <Button
                    icon={ButtonIcon.ADD}
                    sx={{ height: 'fit-content' }}
                    onClick={() => setOpenDeviceTypeNameModal(AddDeviceType.ADD)}
                >
                    {t('addDeviceType.buttonLabel')}
                </Button>
            </FiltersWrapper>

            <Table<IDeviceType>
                data={deviceTypes ?? []}
                tableHandlers={tableHandlers}
                hide={isAdmin ? ['uuid'] : ['uuid', 'parameterBatteryLifetime']}
                searchParams={searchParams}
            />
            {modalIsOpen && (
                <Modal
                    title={
                        device
                            ? `${t('addDeviceType.renameDeviceType')}`
                            : `${t('addDeviceType.newDeviceType')}`
                    }
                    open={modalIsOpen}
                    onClose={handleCloseAddDeviceTypeModal}
                >
                    <DashboardDeviceTypesForm
                        device={device}
                        variant="outlined"
                        onClose={handleCloseAddDeviceTypeModal}
                        isEdit={openDeviceTypeNameModal === AddDeviceType.EDIT}
                        isAdmin={isAdmin}
                    />
                </Modal>
            )}
        </SectionWrapper>
    );
};
