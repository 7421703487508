import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutationActivateUser } from 'api';
import { paths } from 'utils';
import { useStatus } from 'hooks';
import { AuthInfo } from 'modules/auth';

export const AuthActivatePage = () => {
    const { t } = useTranslation('register');
    const { status, setStatus, StatusType } = useStatus();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    const { mutate: postActivate, isLoading } = useMutationActivateUser({
        onSuccess: () => {
            setStatus(StatusType.SUCCESS);
        },
        onError: () => {
            setStatus(StatusType.ERROR);
        }
    });

    useEffect(() => {
        if (token) {
            postActivate({ token });
        }
    }, [token]);

    return (
        <AuthInfo
            isLoading={isLoading}
            title={t(`activate.${status}.title`)}
            description={t(`activate.${status}.description`)}
            buttonText={t(`activate.${status}.button`)}
            redirectPath={paths.auth.self}
        />
    );
};
