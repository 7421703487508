import { TFunction } from 'i18next';
import { IDeviceTypeRule } from 'types';

export interface IEditDeviceRule {
    name: string;
    content: IDeviceTypeRule;
}

export const searchFields = (t: TFunction) => [
    {
        name: 'hex',
        label: t('deviceTypeRules:filters.hex')
    },
    {
        name: 'text',
        label: t('deviceTypeRules:filters.content')
    }
];

export const dynamicSelectOptions = (
    type: 'icon' | 'eventType',
    data: Record<string, string>,
    t: TFunction
) =>
    Object.values(data).map(value => ({
        label: `${t(`common:${type}.${value}`)}`,
        value
    }));

export const translateTableData = (data: Array<IDeviceTypeRule>, t: TFunction) =>
    data.map(item => ({
        ...item,
        hex: item.hex.toUpperCase(),
        type: `${t(`common:eventType.${item.type}`)}`,
        icon: item.icon ? `${t(`common:icon.${item.icon}`)}` : ''
    }));
