import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { IGetStatsParams } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

type IStatsEventTypes = Array<any>;

export const getStatsEventTypes = async (params: IGetStatsParams) => {
    const response = await api.get<IStatsEventTypes>(Endpoint.STATS_EVENT_TYPES, {
        params
    });

    return response.data;
};

export const useQueryGetStatsEventTypes = (
    params: IGetStatsParams,
    options?: IQueryOptions<IStatsEventTypes, IStatsEventTypes>
) =>
    useQuery<IStatsEventTypes, AxiosError, IStatsEventTypes>(
        [QueryKey.STATS_EVENT_TYPES],
        () => getStatsEventTypes(params),
        {
            ...options
        }
    );
