import { Markdown } from 'components';
import { paths } from 'utils';

import TermsAndConditions from './terms-and-conditions.md';

export const TermsAndConditionsPage = () => {
    const registerType = sessionStorage.getItem('accountType');
    const href = `${paths.auth.register}/${registerType}`;

    return <Markdown content={TermsAndConditions} href={href} />;
};
