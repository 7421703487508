/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    Link,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Container,
    Stack
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { useMarkdown } from './hooks';
import { formatHeadingToId } from './markdown.utils';

interface IProps {
    content: string;
    href?: string;
}

export const Markdown = ({ content, href }: IProps) => {
    const { t } = useTranslation(['common']);
    const markdown = useMarkdown(content);

    return (
        <Container
            maxWidth="md"
            sx={{
                padding: '24px 0'
            }}
        >
            <Stack direction="row" columnGap="32px">
                <Stack>
                    <ReactMarkdown
                        children={markdown}
                        components={{
                            h1: ({ node, ...props }) => (
                                <Typography
                                    component="h1"
                                    {...props}
                                    sx={{
                                        marginBlock: '24px',
                                        fontSize: '48px'
                                    }}
                                />
                            ),
                            h2: ({ node, ...props }) => {
                                const value =
                                    node.children[0].type === 'text' ? node.children[0].value : '';

                                return (
                                    <Typography
                                        component="h2"
                                        sx={{
                                            marginBlock: '20px',
                                            fontSize: '32px'
                                        }}
                                        {...props}
                                        id={`${formatHeadingToId(value)}`}
                                    />
                                );
                            },
                            h3: ({ node, ...props }) => (
                                <Typography
                                    component="h3"
                                    {...props}
                                    sx={{
                                        marginBlock: '16px',
                                        fontSize: '24px'
                                    }}
                                />
                            ),
                            h4: ({ node, ...props }) => (
                                <Typography
                                    variant="h5"
                                    component="h4"
                                    {...props}
                                    sx={{
                                        marginBlock: '16px',
                                        fontSize: '20px'
                                    }}
                                />
                            ),
                            a: ({ node, ...props }) => <Link {...props} />,
                            p: ({ node, ...props }) => (
                                <Typography
                                    {...props}
                                    sx={{
                                        marginBlock: '16px'
                                    }}
                                />
                            ),
                            ul: ({ node, ...props }) => <List {...props} />,
                            li: ({ node, ...props }) => (
                                <ListItem {...props} disablePadding>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '24px',
                                            svg: {
                                                width: '10px',
                                                height: '10px',
                                                color: 'text.primary'
                                            }
                                        }}
                                    >
                                        <CircleIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            '& .MuiTypography-body1': {
                                                marginBlock: '4px'
                                            }
                                        }}
                                    >
                                        {props.children}
                                    </ListItemText>
                                </ListItem>
                            )
                        }}
                    />
                </Stack>
                {href && (
                    <Link
                        color="primary"
                        href={href}
                        sx={{
                            height: 'fit-content',
                            marginTop: '36px',
                            position: 'sticky',
                            top: '36px'
                        }}
                    >
                        {t('action.back')}
                    </Link>
                )}
            </Stack>
        </Container>
    );
};
