import { styled } from '@mui/material/styles';
import { Input as StyledInput } from 'components';

export const AuthInput = styled(StyledInput)(() => ({
    width: '100%'
}));

AuthInput.defaultProps = {
    variant: 'standard'
};
