import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMutationDeleteDeviceBatteryCounter, QueryKey } from 'api';
import { List, Button, Dialog } from 'components';
import { IDevice, RoleType } from 'types';
import { useUserData } from 'stores';
import { allowedEditKeys, hideColumnsByRole } from './device-details-card.config';
import { DashboardDeviceDetailsEditGeneralForm } from '../device-details-edit-general-from';
import * as S from './device-details-card.styled';

interface IProps {
    data: IDevice;
}

export const DeviceDetailsCard = ({ data }: IProps) => {
    const { t } = useTranslation('deviceDetails');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { userData } = useUserData();
    const [editGeneralData, setEditGeneralData] = useState<Record<string, string>>();
    const [confirmBatteryReset, setConfirmBatteryReset] = useState(false);
    const role = userData?.role as RoleType;
    const isAdmin = userData?.role === RoleType.Admin;

    const { mutate: deleteDeviceBatteryCounter } = useMutationDeleteDeviceBatteryCounter({
        onSuccess: () => {
            Promise.all([
                queryClient.invalidateQueries([QueryKey.DEVICE]),
                queryClient.invalidateQueries([QueryKey.DEVICE_HISTORY_MAINTENANCE])
            ]);
            setConfirmBatteryReset(false);
            enqueueSnackbar(t('batteryReset.alertSuccess'), { variant: 'success' });
        },
        onError: () => {
            setConfirmBatteryReset(false);
            enqueueSnackbar(t('batteryReset.alertError'), { variant: 'error' });
        }
    });

    const handleEdit = (key: keyof IDevice) => {
        switch (key) {
            case 'deviceName':
                return setEditGeneralData({ deviceName: data.deviceName ?? '' });
            case 'deviceTypeName':
                return setEditGeneralData({ deviceTypeName: data.deviceTypeName ?? '' });
            case 'subscriptionPhonenumber':
                return setEditGeneralData({
                    subscriptionPhonenumber: data.subscriptionPhonenumber ?? ''
                });
            case 'subscriptionExpiresAt':
                return setEditGeneralData({
                    subscriptionExpiresAt: String(data.subscriptionExpiresAt) ?? ''
                });
            case 'deviceOwnerNip':
                return setEditGeneralData({ deviceOwnerNip: data.deviceOwnerNip ?? '' });
            default:
                return;
        }
    };

    const handleResetBatteryCounter = () => {
        deleteDeviceBatteryCounter({
            deviceUUID: data.deviceUUID
        });
    };

    const editGeneralConfig = {
        keys: allowedEditKeys(role),
        handler: handleEdit
    };

    return (
        <S.Container>
            <List<IDevice>
                data={data}
                title={`${t('card.generalTitle')}`}
                edit={editGeneralConfig}
                columnGap={1}
                hide={hideColumnsByRole(role)}
            />
            {isAdmin && (
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => setConfirmBatteryReset(true)}
                >
                    {t('batteryReset.button')}
                </Button>
            )}

            {editGeneralData && (
                <DashboardDeviceDetailsEditGeneralForm
                    data={editGeneralData}
                    onClose={() => setEditGeneralData(undefined)}
                />
            )}

            {confirmBatteryReset && (
                <Dialog
                    open={confirmBatteryReset}
                    title={`${t('batteryReset.modalTitle')}`}
                    desc={`${t('batteryReset.modalDesc')}`}
                    onClose={() => setConfirmBatteryReset(false)}
                    onConfirm={handleResetBatteryCounter}
                />
            )}
        </S.Container>
    );
};
