import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import type { IPostVerifyAccount } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationVerifyAccount = (options?: IMutationOptions<IPostVerifyAccount>) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostVerifyAccount>(
        async accountUUID => api.post(Endpoint.ACCOUNT_VERIFY, accountUUID),
        options
    );
