import { useNavigate } from 'react-router-dom';
import type { ButtonProps } from '@mui/material';
import { ButtonIcon, handleIcon } from './button.icons';
import * as S from './button.styled';
import { Spinner } from '..';

type IButtonProps = ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

type IEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface IProps extends IButtonProps {
    children: React.ReactNode;
    to?: string;
    icon?: ButtonIcon | React.ReactNode;
    isLoading?: boolean;
}

export const Button = ({ children, to, icon, isLoading, ...rest }: IProps) => {
    const navigate = useNavigate();

    const handleNavigate = (event: IEvent, to: string) => {
        event.preventDefault();
        navigate(to);
    };

    return (
        <S.Button
            onClick={to ? (event: IEvent) => handleNavigate(event, to) : rest.onClick}
            startIcon={icon ? handleIcon(icon) : rest.startIcon}
            {...rest}
        >
            {children}
            {isLoading && (
                <S.Box>
                    <Spinner size={16} />
                </S.Box>
            )}
        </S.Button>
    );
};
