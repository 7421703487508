import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { debounce } from '@mui/material';
import { ISelectOptions } from 'types';
import { Input } from '..';
import { IFilters, dynamicDefaultValues } from './filters.config';
import * as S from './filters.styled';

interface IFields {
    name: string;
    label: string;
}

interface ISelects {
    name: string;
    label: string;
    options: Array<ISelectOptions>;
    selectAll?: boolean;
}

interface IProps {
    fields: Array<IFields>;
    setSearchParams: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    selects?: Array<ISelects>;
    disabled?: boolean;
    debounceTime?: number;
}

export const Filters = ({
    fields,
    setSearchParams,
    selects,
    disabled,
    debounceTime = 1000
}: IProps) => {
    const fieldsNames = fields.map(field => field.name);
    const selectsNames = selects?.map(select => select.name);

    const methods = useForm<IFilters>({
        defaultValues: dynamicDefaultValues(
            selectsNames ? [...fieldsNames, ...selectsNames] : fieldsNames
        )
    });

    const formData = methods.watch();

    const onChangeFunc = useCallback(
        debounce(() => {
            methods.handleSubmit((data: IFilters) => {
                setSearchParams(prev => ({ ...prev, ...data }));
            })();
        }, debounceTime),

        [formData]
    );

    return (
        <FormProvider {...methods}>
            <S.Wrapper component="form">
                {fields.map(({ name, label }) => (
                    <Input
                        key={`filter-input-${name}`}
                        name={name}
                        label={label}
                        size="small"
                        customOnChange={(e, onChange) => {
                            onChange(e);
                            onChangeFunc();
                        }}
                        disabled={disabled}
                    />
                ))}
                {selects &&
                    selects.map(({ name, label, options, selectAll }) => (
                        <S.Select
                            key={`filter-select-${name}`}
                            name={name}
                            label={label}
                            options={options}
                            size="small"
                            variant="filled"
                            selectAll={selectAll}
                            customOnChange={(e, onChange) => {
                                onChange(e);
                                onChangeFunc();
                            }}
                            disabled={disabled}
                        />
                    ))}
            </S.Wrapper>
        </FormProvider>
    );
};
