import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, Map } from 'components';
import { useUserData } from 'stores';
import { RoleType, type IDevice, type ILocation } from 'types';
import * as S from './device-details-card.styled';
import { DashboardDeviceFormLocation } from '../device-form-location';
import { useEditDeviceDetails } from '../hooks';

interface IProps {
    data: IDevice;
}

export const DeviceDetailsCardLocation = ({ data }: IProps) => {
    const { t } = useTranslation('deviceDetails');
    const { id } = useParams();
    const [editLocalizationContent, setEditLocalizationContent] = useState<ILocation>();
    const { userData } = useUserData();
    const role = userData?.role as RoleType;

    const handleClose = () => {
        setEditLocalizationContent(undefined);
    };

    const { postDeviceLocation, postDeviceLocationError } = useEditDeviceDetails(handleClose);

    const locationDeviceData: ILocation = {
        lat: data.lat,
        lng: data.lng
    };

    const editLocationConfig = {
        keys: ['lng'] as Array<keyof ILocation>,
        handler: () => setEditLocalizationContent(locationDeviceData)
    };

    const submitHandler = (location: ILocation) => {
        const { lat, lng } = location;
        postDeviceLocation({
            deviceUUID: id as string,
            location: {
                lat,
                long: lng
            }
        });
    };

    return (
        <S.Container>
            <List<ILocation>
                data={locationDeviceData}
                title={`${t('card.locationTitle')}`}
                edit={role === RoleType.Client ? undefined : editLocationConfig}
                columnGap={1}
            />
            <Map
                location={{
                    lat: data.lat,
                    lng: data.lng
                }}
            />

            {editLocalizationContent && (
                <DashboardDeviceFormLocation
                    data={editLocalizationContent}
                    title={`${t('tableHandlers.localizationEdit')}`}
                    submitHandler={submitHandler}
                    onClose={handleClose}
                    error={postDeviceLocationError}
                />
            )}
        </S.Container>
    );
};
