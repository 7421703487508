import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SectionWrapper } from 'modules/dashboard';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { Switch } from 'components';
import { ErrorMessage } from 'modules/common';
import { useQueryGetSystemConditions, useMutationPostSystemCondition, QueryKey } from 'api';
import { SettingsInput, dynamicDefaultValues, ISettingsForm } from './settings.config';
import * as S from './settings.styled';

export const DashboardSettingsPage = () => {
    const { t } = useTranslation('settings');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const {
        data: systemConditions,
        isLoading: isLoadingSystemConditions,
        error: errorSystemConditions,
        isError: isErrorSystemConditions
    } = useQueryGetSystemConditions();

    const { mutate: postSystemCondition, isLoading: isLoadingPostSystemConditions } =
        useMutationPostSystemCondition({
            onSuccess: () => {
                enqueueSnackbar(t('alert.success'), { variant: 'success' });
                queryClient.invalidateQueries([QueryKey.SYSTEM_CONDITIONS_PUBLIC]);
            },
            onError: () => {
                enqueueSnackbar(t('alert.error'), { variant: 'error' });
            }
        });

    const verifyIsRequired = Boolean(systemConditions?.verifyIsRequired);

    const methods = useForm<ISettingsForm>({
        defaultValues: dynamicDefaultValues(verifyIsRequired)
    });

    const fieldVerifyIsRequired = useWatch({
        control: methods.control,
        name: SettingsInput.VERIFY_IS_REQUIRED
    });

    const onSubmit = (formData: ISettingsForm) => {
        postSystemCondition(formData);
    };

    useEffect(() => {
        methods.reset(dynamicDefaultValues(verifyIsRequired));
    }, [verifyIsRequired]);

    return (
        <SectionWrapper title={`${t('title')}`} isError={isErrorSystemConditions}>
            <S.Container>
                <ErrorMessage error={errorSystemConditions} />
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Switch
                            name={SettingsInput.VERIFY_IS_REQUIRED}
                            label={t('form.verifyIsRequired')}
                            isLoading={isLoadingSystemConditions}
                        />
                        <S.Button
                            type="submit"
                            disabled={
                                isLoadingSystemConditions ||
                                isLoadingPostSystemConditions ||
                                fieldVerifyIsRequired === verifyIsRequired
                            }
                            isLoading={isLoadingPostSystemConditions}
                        >
                            {t(`form.submit`)}
                        </S.Button>
                    </Box>
                </FormProvider>
            </S.Container>
        </SectionWrapper>
    );
};
