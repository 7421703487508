import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, SelectProps, Box } from '@mui/material';
import { Select, Input, Button, Spinner } from 'components';
import { useMutationPostDeviceTypeRule, QueryKey, useQueryDeviceTypeRule } from 'api';
import { FormWrapper, FromButtonsWrapper, formFullWidtStyle } from 'modules/dashboard';
import { ErrorMessage } from 'modules/common';
import { DeviceRuleType, DeviceTypeRuleIcon } from 'types';
import {
    defaultValues,
    IDeviceRuleForm,
    formSchema,
    DeviceTypeRulesInput,
    transformDeviceTypeRule
} from './device-type-rules-form.config';
import { dynamicSelectOptions } from './device-type-rules.config';

interface IProps {
    selectedRuleId?: string;
    onClose: () => void;
}

export const DashboardDeviceTypesRulesForm = ({ onClose, selectedRuleId }: IProps) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation(['deviceTypeRules', 'common']);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const isEdit = Boolean(selectedRuleId);

    const { data: deviceTypeRule, isLoading: deviceTypeRuleIsLoading } = useQueryDeviceTypeRule(
        selectedRuleId ?? '',
        {
            enabled: isEdit
        }
    );

    const {
        mutate: postDeviceTypeRule,
        error,
        isLoading
    } = useMutationPostDeviceTypeRule(isEdit, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.DEVICE_TYPE_RULES]);
            enqueueSnackbar(t(`alert.${isEdit ? 'editRuleSuccess' : 'addRuleSuccess'}`), {
                variant: 'success'
            });
            onClose();
        },
        onError: () => {
            enqueueSnackbar(t(`alert.${isEdit ? 'editRuleError' : 'addRuleError'}`), {
                variant: 'error'
            });
        }
    });

    const methods = useForm<IDeviceRuleForm>({
        defaultValues: defaultValues(isEdit ? deviceTypeRule : undefined),
        resolver: yupResolver(formSchema)
    });

    const onSubmit = (data: IDeviceRuleForm) => {
        const ruleData = {
            [isEdit ? 'deviceTypeRuleUUID' : 'deviceTypeUUID']: isEdit
                ? `${selectedRuleId}`
                : `${id}`,
            type: data.type,
            icon: data.icon,
            hex: data.hex,
            color: data.color.slice(1),
            text: data.text,
            formulaValue: data?.formulaValue ? +data.formulaValue : 0
        };

        postDeviceTypeRule(ruleData);
    };

    useEffect(() => {
        if (isEdit && deviceTypeRule) {
            methods.reset(defaultValues(transformDeviceTypeRule(deviceTypeRule)));
        }
    }, [isEdit, deviceTypeRule]);

    const selectStyleOptions: SelectProps = {
        sx: formFullWidtStyle,
        variant: 'filled'
    };

    return (
        <FormProvider {...methods}>
            <Paper
                sx={{
                    minHeight: '375px'
                }}
            >
                {isEdit && deviceTypeRuleIsLoading ? (
                    <Spinner />
                ) : (
                    <FormWrapper component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Box sx={formFullWidtStyle}>
                            <ErrorMessage error={error} />
                        </Box>
                        <Select
                            {...selectStyleOptions}
                            name={DeviceTypeRulesInput.TYPE}
                            label={t('form.label.eventType')}
                            options={dynamicSelectOptions('eventType', DeviceRuleType, t)}
                        />
                        <Select
                            {...selectStyleOptions}
                            name={DeviceTypeRulesInput.ICON}
                            label={t('form.label.icon')}
                            options={dynamicSelectOptions('icon', DeviceTypeRuleIcon, t)}
                        />
                        <Input name={DeviceTypeRulesInput.HEX} label={t('form.label.hex')} />
                        <Input
                            name={DeviceTypeRulesInput.COLOR}
                            label={t('form.label.color')}
                            type="color"
                        />
                        <Input
                            name={DeviceTypeRulesInput.FORMULA_VALUE}
                            type="number"
                            label={t('form.label.formulaValue')}
                            sx={formFullWidtStyle}
                        />
                        <Input
                            name={DeviceTypeRulesInput.TEXT}
                            label={t('form.label.text')}
                            sx={formFullWidtStyle}
                        />
                        <FromButtonsWrapper>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLoading}
                                isLoading={isLoading}
                            >
                                {t('form.submitButton')}
                            </Button>
                        </FromButtonsWrapper>
                    </FormWrapper>
                )}
            </Paper>
        </FormProvider>
    );
};
