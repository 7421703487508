import { CSVLink } from 'react-csv';
import { styled } from '@mui/material/styles';
import { Button as MUIButton } from 'components';

export const Button = styled(MUIButton)(() => ({
    height: 'fit-content'
}));

export const CSVLinkStyled = styled(CSVLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main
}));
