import { Markdown } from 'components';
import { paths } from 'utils';

import PrivacyPolicy from './privacy-policy.md';

export const PrivacyPolicyPage = () => {
    const registerType = sessionStorage.getItem('accountType');
    const href = `${paths.auth.register}/${registerType}`;

    return <Markdown content={PrivacyPolicy} href={href} />;
};
