import * as yup from 'yup';
import { requiredStringSchema, regexSchema, convertToHexColor } from 'utils';
import { DeviceRuleType, IDeviceTypeRule, DeviceTypeRuleIcon } from 'types';

export enum DeviceTypeRulesInput {
    TYPE = 'type',
    ICON = 'icon',
    HEX = 'hex',
    COLOR = 'color',
    TEXT = 'text',
    FORMULA_VALUE = 'formulaValue'
}
export interface IDeviceRuleForm {
    [DeviceTypeRulesInput.TYPE]: DeviceRuleType;
    [DeviceTypeRulesInput.ICON]: string;
    [DeviceTypeRulesInput.HEX]: string;
    [DeviceTypeRulesInput.TEXT]: string;
    [DeviceTypeRulesInput.COLOR]: string;
    [DeviceTypeRulesInput.FORMULA_VALUE]: number;
}

export const defaultValues = (rule?: IDeviceTypeRule): IDeviceRuleForm => ({
    [DeviceTypeRulesInput.TYPE]: (rule?.type as DeviceRuleType) ?? DeviceRuleType.Event,
    [DeviceTypeRulesInput.ICON]: (rule?.icon as DeviceTypeRuleIcon) ?? '',
    [DeviceTypeRulesInput.HEX]: rule?.hex ?? '',
    [DeviceTypeRulesInput.COLOR]: (rule?.color && convertToHexColor(rule?.color)) ?? '#000000',
    [DeviceTypeRulesInput.TEXT]: rule?.text ?? '',
    [DeviceTypeRulesInput.FORMULA_VALUE]: rule?.formulaValue ?? 0
});

export const formSchema = yup.object({
    [DeviceTypeRulesInput.TYPE]: requiredStringSchema,
    [DeviceTypeRulesInput.ICON]: requiredStringSchema,
    [DeviceTypeRulesInput.HEX]: regexSchema('hex'),
    [DeviceTypeRulesInput.COLOR]: requiredStringSchema,
    [DeviceTypeRulesInput.TEXT]: requiredStringSchema,
    [DeviceTypeRulesInput.FORMULA_VALUE]: requiredStringSchema
});

export const transformDeviceTypeRule = (data: IDeviceTypeRule): IDeviceTypeRule => {
    const deviceIconLegacy = ['open_icon', 'closed_icon', 'battery_icon', 'hit_icon'];

    if (deviceIconLegacy.includes(String(data.icon))) {
        switch (data.icon) {
            case 'open_icon':
                return {
                    ...data,
                    icon: DeviceTypeRuleIcon.Opened
                };
            case 'closed_icon':
                return {
                    ...data,
                    icon: DeviceTypeRuleIcon.Closed
                };
            case 'battery_icon':
                return {
                    ...data,
                    icon: DeviceTypeRuleIcon.BatteryLevel
                };
            case 'hit_icon':
                return {
                    ...data,
                    icon: DeviceTypeRuleIcon.ImpactLevel
                };
            default:
                return data;
        }
    }

    return data;
};
