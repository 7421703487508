import { PaperProps } from '@mui/material';
import * as S from './form-wrapper.styled';

interface IProps extends PaperProps {
    title: string;
    children: React.ReactNode;
}

export const FormWrapper = ({ title, children, ...rest }: IProps) => (
    <S.Wrapper {...rest}>
        <S.Title>{title}</S.Title>
        {children}
    </S.Wrapper>
);
