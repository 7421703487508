import * as yup from 'yup';
import { nipSchema, requiredStringSchema, phoneSchema, dateSchema } from 'utils';
import { RoleType } from 'types';

export enum DeviceFormInput {
    DEVICE_TYPE_UUID = 'deviceTypeUUID',
    DEVICE_ID = 'deviceId',
    PSC = 'psc',
    DEVICE_NAME = 'deviceName',
    PHONE_NUMBER = 'subscriptionPhonenumber',
    COMPANY_NIP = 'ownershipCompanyNip',
    SUBSCRIPTION_EXPIRY = 'subscriptionExipryUnixTime',
    INSTALLER_NIP = 'installerNip',
    LOCATION_LAT = 'locationLat',
    LOCATION_LNG = 'locationLng'
}

export interface IDevicesForm {
    [DeviceFormInput.DEVICE_ID]: string;
    [DeviceFormInput.DEVICE_NAME]: string;
    [DeviceFormInput.PSC]: string;
    [DeviceFormInput.DEVICE_TYPE_UUID]: string;
    [DeviceFormInput.PHONE_NUMBER]: string;
    [DeviceFormInput.COMPANY_NIP]: string;
    [DeviceFormInput.INSTALLER_NIP]?: string;
    [DeviceFormInput.SUBSCRIPTION_EXPIRY]: Date;
    [DeviceFormInput.LOCATION_LAT]: string;
    [DeviceFormInput.LOCATION_LNG]: string;
}

export const defaultValues: IDevicesForm = {
    [DeviceFormInput.DEVICE_ID]: '',
    [DeviceFormInput.DEVICE_NAME]: '',
    [DeviceFormInput.PSC]: '',
    [DeviceFormInput.DEVICE_TYPE_UUID]: '',
    [DeviceFormInput.PHONE_NUMBER]: '',
    [DeviceFormInput.COMPANY_NIP]: '',
    [DeviceFormInput.SUBSCRIPTION_EXPIRY]: new Date(),
    [DeviceFormInput.LOCATION_LAT]: '',
    [DeviceFormInput.LOCATION_LNG]: ''
};

export const dynamicDefaultValues = (role: RoleType): IDevicesForm => {
    switch (role) {
        case RoleType.Admin:
            return {
                ...defaultValues,
                [DeviceFormInput.INSTALLER_NIP]: ''
            };

        default:
            return defaultValues;
    }
};

export const formSchema = yup.object({
    [DeviceFormInput.DEVICE_ID]: requiredStringSchema,
    [DeviceFormInput.DEVICE_NAME]: requiredStringSchema,
    [DeviceFormInput.PSC]: requiredStringSchema,
    [DeviceFormInput.DEVICE_TYPE_UUID]: requiredStringSchema,
    [DeviceFormInput.PHONE_NUMBER]: phoneSchema,
    [DeviceFormInput.COMPANY_NIP]: nipSchema,
    [DeviceFormInput.SUBSCRIPTION_EXPIRY]: dateSchema,
    [DeviceFormInput.LOCATION_LAT]: requiredStringSchema,
    [DeviceFormInput.LOCATION_LNG]: requiredStringSchema
});

export const dynamicFormSchema = (role: RoleType) => {
    switch (role) {
        case RoleType.Admin:
            return formSchema.shape({
                [DeviceFormInput.INSTALLER_NIP]: nipSchema
            });

        default:
            return formSchema;
    }
};
