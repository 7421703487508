import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IPostDeviceTypeRuleData } from 'types';
import { api, Endpoint, IMutationOptions } from '../..';

export const useMutationPostDeviceTypeRule = (
    isEdit?: boolean,
    options?: IMutationOptions<IPostDeviceTypeRuleData>
) =>
    useMutation<AxiosResponse<null>, AxiosError, IPostDeviceTypeRuleData>(
        async data => api[isEdit ? 'patch' : 'post'](Endpoint.DEVICE_TYPE_RULE, data),
        options
    );
