import { RoleType } from 'types';

export interface IAccountTypeButtons {
    label: string;
    type: RoleType;
}

export const accountTypeButtons: Array<IAccountTypeButtons> = [
    {
        label: 'customer',
        type: RoleType.Client
    },
    {
        label: 'installer',
        type: RoleType.Installer
    }
];
