import create from 'zustand';
import { Cookies } from 'react-cookie';
import { Cookie, api, Endpoint, queryClient } from 'api';
import { IUserData } from 'types';
const cookies = new Cookies();
interface IUserStore {
    userData: IUserData | null;
    isLoggedIn: boolean;
    isPending: boolean;
    setIsPending: (isPending: boolean) => void;
    setUserData: (user: IUserData) => void;
    logOut: () => void;
}

export const useUserData = create<IUserStore>(set => ({
    userData: null,
    isLoggedIn: false,
    isPending: true,
    setIsPending: isPending => set({ isPending }),
    setUserData: (userData: IUserData) => set({ userData, isLoggedIn: true, isPending: false }),
    logOut: async () => {
        const token = cookies.get(Cookie.ACCESS_TOKEN);

        if (token) {
            await api.delete(Endpoint.AUTH_DESTROY_TOKEN, {
                data: { token }
            });
            cookies.remove(Cookie.ACCESS_TOKEN, { path: '/' });
        }

        set({ isLoggedIn: false, userData: null, isPending: false });
        queryClient.clear();
    }
}));
