import { useTranslation } from 'react-i18next';
import type { FieldError } from 'types';

export const useHandleInputError = () => {
    const { t } = useTranslation('validation');

    const handleInputError = (error: FieldError): string | null => {
        if (error?.message) {
            return typeof error.message === 'string'
                ? t(error.message)
                : t(error.message.key, error.message.values);
        }

        return null;
    };

    return { handleInputError };
};
