import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IDeviceHistoryEventResponse, IDeviceHistoryEvent } from 'types';
import { api, Endpoint, QueryKey, type IQueryOptions } from '../..';

export const getDeviceHistoryEvents = async (deviceUUID: string) => {
    const response = await api.get<Array<IDeviceHistoryEventResponse>>(
        Endpoint.DEVICE_HISTORY_EVENTS,
        {
            params: {
                deviceUUID
            }
        }
    );

    return response.data;
};

export const useQueryGetDeviceHistoryEvents = (
    deviceUUID: string,
    options?: IQueryOptions<Array<IDeviceHistoryEventResponse>, Array<IDeviceHistoryEvent>>
) =>
    useQuery<Array<IDeviceHistoryEventResponse>, AxiosError, Array<IDeviceHistoryEvent>>(
        [QueryKey.DEVICE_HISTORY_EVENTS],
        () => getDeviceHistoryEvents(deviceUUID),
        {
            ...options
        }
    );
