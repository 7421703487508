type EnvironmentVariables = {
    NODE_ENV?: string
    REACT_APP_API_BASE_URL?: string
    REACT_APP_GOOGLE_MAPS_API_KEY?: string
}

declare global {
    interface Window { env: EnvironmentVariables }
}

const env = window?.env || process.env || {}
const getProcessEnvironmentVariable = (key: keyof EnvironmentVariables, defaultValue?: string) => env[key] ?? defaultValue ?? ''

export const NODE_ENV = getProcessEnvironmentVariable('NODE_ENV')
export const REACT_APP_API_BASE_URL = getProcessEnvironmentVariable('REACT_APP_API_BASE_URL')
export const REACT_APP_GOOGLE_MAPS_API_KEY = getProcessEnvironmentVariable('REACT_APP_GOOGLE_MAPS_API_KEY')
